import React, { useRef, useState, useContext, useReducer, useEffect, } from "react";
import { CheckUUID } from "../../Utility/CheckUUID";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import Logo from '../../assets/PreviewPageV2/img/logo.jpg'
import OverLeaf from '../../assets/PreviewPageV2/img/overleaf.jpg'
import Ombudsman from '../../assets/PreviewPageV2/img/budsman.jpg'
import { useHistory } from "react-router-dom";
import { usePreviewData } from "../../Hooks/usePreviewData";
import "../../assets/OD_UFL_4/scss/main.scss"
import { employmentVAQ3, answerArrayVery, questinnaire76Vanquis, questinnaire36Vanquis, wereUalreadyPaying,furtherBorrows } from "../../Constants/Questions";
import SignatureCanvas from "react-signature-canvas";
import { useSignature } from "../../Hooks/useSignature"
import QueryStringContext from "../../Contexts/QueryString";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { Api } from "../../api/Api";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import LOA from '../../assets/pdf/LOA_Vanquis.pdf';
import TOB from '../../assets/pdf/Business_Terms_TMS.pdf';
import VQ from '../../assets/pdf/Questionnaire_Vanquis.pdf';
import DECL from '../../assets/pdf/Vaq_Declaration.pdf';
import DSAR from '../../assets/pdf/MoneyBarn_DSAR.pdf';
import { useClaimReg } from "../../Hooks/useClaimReg";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const PreviewOD_UFL_4 = () => {
  const { isCheck } = CheckUUID();
  const isCheckResponse = isCheck();
  const { savePreviewData, getMeritPagePreviewData } = usePreviewData();
  const [userName, setUserName] = useState();
  const [fName, setFname] = useState();
  const [lName, setLname] = useState();
  const [address, setAddress] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [addressLine3, setAddressLine3] = useState();
  const [city, setCity] = useState();
  const [postcode, setPostcode] = useState();
  const [phone, setPhone] = useState();
  const [county, setCounty] = useState();
  const [country, setCountry] = useState();
  const [email, setEmail] = useState();
  const [salutation, setSalutation] = useState();
  const [joineeName, setJoineeName] = useState();
  const [userDob, setUserDob] = useState();
  const [joineeDob, setJoineeDob] = useState();
  const [joineeAddress, setJoineeAdress] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [userSign, setUserSign] = useState();
  const [joineeSign, setJoineeSign] = useState();
  const [partnerDate, setPartnerDate] = useState();
  const [partnerSignShow, setPartnerSignShow] = useState("hide");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [currentUuid, setCurrentUuid] = useState();
  const [bankName, setBankName] = useState();
  const [userSortCode, setUserSortCode] = useState([]);
  const [userBankAccount, setUserBankAccount] = useState([]);
  const [previousAddress, setPreviousAddress] = useState();
  const history = useHistory();
  const [postcodeArr, setPostcodeArr] = useState([]);
  const [phoneArr, setPhoneArr] = useState([]);
  const [dobDateArr, setDobDateArr] = useState([]);
  const [dobMonthArr, setDobMonthArr] = useState([]);
  const [dobYearArr, setDobYearArr] = useState([]);
  const [btndisabled, setBtndisabled] = useState(false);
  const [otherChoose, setOtherChoose] = useState([]);
  const [recentCredit, setRecentCredit] = useState();
  const [odopeningCredit, setOdopeningCredit] = useState();
  const [irresponsibleAct, setIrresponsibleAct] = useState();
  const [highestCreditLimit, setHighestCreditLimit] = useState();
  const [exceedCreditLimit, setExceedCreditLimit] = useState();
  const [timesExceedLimit, setTimesExceedLimit] = useState();
  const [overlimitCharge, setOverlimitCharge] = useState();
  const [finHardship, setFinHardship] = useState();
  // const [thinkBack, setThinkBack] = useState();
  const [furtherBorrow, setFurtherBorrow] = useState();
  const [essentialRepayment, setEssentialRepayment] = useState();
  const [maintainBalance, setMaintainBalance] = useState();
  const [stuckOverdraft, setStuckOverdraft] = useState();
  const [stuckOverdraftAffect,setStuckOverdraftAffect] = useState();
  const [dependent, setDependent] = useState();
  const [t2aStatus, setT2AStatus] = useState();
  const [RegisEmail, setRegisEmail] = useState();
  const [DateTd, setDateTd] = useState();
  const [previousName, setPreviousName] = useState();
  const [showSign, setShowSign] = useState(true)
  const { saveSignature, isLoading } = useSignature();
  const [signatureDataURL, setSignatureDataURL] = useState('');
  const { queryString } = useContext(QueryStringContext);
  const [canvasShow, setCanvasShow] = useState(true);
  const cfirstName = localStorage.getItem("UcFname") ? localStorage.getItem("UcFname") : '';
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);
  const divRefOne = useRef(null);
  const divRefTwo = useRef(null);
  const divRefThree = useRef(null);
  const divRefFour = useRef(null);
  const divRefFive = useRef(null);
  const divRefSix = useRef(null);
  const [refID, setRefID] = useState(2);
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [showNextBtn, setNextBtn] = useState(true);
  const [question76, setQuestion76] = useState([]);
  const { clickPreviewVisit, clickMeritPageVisit } = useClaimReg();
  let split_name = localStorage.getItem("split_name");
  const [selectedLender, setSelectedLender] = useState('');
  const [didReceiveIncome, setDidReceiveIncome] = useState();
  const [cardMonthlyIncome, setCardMonthlyIncome] = useState();
  const [question79, setQuestion79] = useState([]);
  const { updateMilestone } = useUpdateMilestone();

  const [userMortages, setUserMortage] = useState('');
  const [userRentss, setUserRent] = useState('');
  const [userTotalUtilityBillss, setUserTotalUtilityBills] = useState('');
  const [userCreditCardRepaymentss, setUserCreditCardRepayments] = useState('');
  const [userMonthlyLoanRepaymentss, setUserMonthlyLoanRepayments] = useState('');
  const [userMonthlyCarFinancess, setUserMonthlyCarFinance] = useState('');
  const [userMonthlyStoreCardPaymentss, setUserMonthlyStoreCardPayments] = useState('');
  const [userOtherMonthlyPaymentss, setUserOtherMonthlyPayments] = useState('');
  const [slide80, setSlide80] = useState([]);
  
  const scrollToDivWithDelay = () => {
    setTimeout(() => {
      divRefOne.current.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  };

  const scrollToElement = () => {
    setRefID(prev => prev + 1)
    if (refID == "2") {
      divRefTwo.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (refID == "3") {
      divRefThree.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (refID == "4") {
      divRefFour.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (refID == "5") {
      divRefFive.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (refID == "6") {
      setNextBtn(false);
      setShowSubmitBtn(true);
      divRefSix.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  };

  const signPadRef = useRef();
  const initialState = {
    isCanvasEmpty: false,
    isValid: false,
    isAgreed: false
  };
  const signatureReducer = (state, action) => {
    switch (action.type) {
      case "signPadChange": {
        return {
          ...state,
          isCanvasEmpty: action.payload.value,
        };
      }
      case "validSignature": {
        return {
          ...state,
          isValid: action.payload.value,
        };
      }
      case "privacyAgreed": {
        return {
          ...state,
          isAgreed: action.payload.value,
        };
      }
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(signatureReducer, initialState);

  const handleClick = async (e) => {
    try {
      e.preventDefault();
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const canvasDataURL = signPadRef.current
          .getCanvas()
          .toDataURL("image/png");
        setSignatureDataURL(canvasDataURL);
        const message_type = "signature_store";
        const pdf_generation = 0;
        const formParameters = JSON.parse(localStorage.getItem("formData"));
        const response = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type,
          pdf_generation
        );

        if (response.data.status === "Success") {

          const responsepdf = await Api.get("/api/v1/vaq-pdf-generate?uuid=" + visitorParameters.visitor_parameters.uuid, {});

          if (responsepdf.data.status === "Success") {

            const formdata = JSON.parse(localStorage.getItem("formData"));
            if (formdata) {
              formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
              localStorage.setItem("formData", JSON.stringify(formdata));
            }
            setUserSign(canvasDataURL);
            setShowSign(false);
            scrollToDivWithDelay();
            GtmDataLayer({
              question: "final submit",
            });
          }
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }

    } catch (e) {
      console.warn(e);
    }
  }
  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight('204');
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight('204');

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [canvasShow]);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
    setCanvasShow(false)
  };

  const modalChange = () => {
    GtmDataLayer({
      question: "final submit",
    });
    setShowSign(false);
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get("uuid");
    const responsepdf = Api.get("/api/v1/vaq-pdf-generate?uuid=" + uuid, {});
    scrollToDivWithDelay();

  }

  useEffect(() => {
    (async () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData) {
        const uuid = visitorData.uuid;
        setCurrentUuid(uuid);
      }
    })();
  }, [visitorParameters]);
  let customUuid = "";
  let customSource = "";
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const query_source = query.get("source");
  const local_uuid = localStorage.getItem("uuid");
  customUuid = query_uuid ? query_uuid : local_uuid;
  customSource = query_source ? query_source : "live";
  let local_flp_source = localStorage.getItem("flp_source");
  let source = local_flp_source ? local_flp_source : "live";

  const pushPageChange = async () => {
    setBtndisabled(true);
    try {
      const previewresponse = await clickPreviewVisit(
        query_uuid, split_name, "preview_submit", source
      );
      if (previewresponse.data.status == "Success") {
        GtmDataLayer({
          'question': 'letter of authority'
        });
        updateMilestone(visitorParameters.visitor_parameters.uuid, "preview_submit", source);
        const _query = new URLSearchParams(window.location.search);
        const _query_uuid = _query.get("uuid");

        const allFollowupData = JSON.parse(localStorage.getItem('allFollowupData'));

        if (allFollowupData) {
          window.scrollTo(0, 0);
          history.push("/ThankyouODUFL4?uuid=" + query_uuid);
        } else {
          if (t2aStatus == '1') {
            window.scrollTo(0, 0);
            history.push("/ThankyouODUFL4?uuid=" + query_uuid);
          } else {
            window.scrollTo(0, 0);
            history.push(`/VAQ_UB_4_ID?uuid=${query_uuid}&split_name=${split_name}`);
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const MonthlyIncomeList = () => {
    let letter = ["a", "b", "c", "d", "e", "f", "g", "h"];
    if (question79.length > 0) {
      return question79.map((ps, index) => {
        return (
          <p key={index}>
            {letter[index]}) {ps}
          </p>
        );
      });
    }
  };

  const FurtherBorrowList = () => {
    let letter = ["a", "b", "c", "d", "e", "f", "g", "h"];
    if (furtherBorrow.length > 0) {
      return furtherBorrow.map((ps, index) => {
        return (
          <p key={index}>
            ({letter[index]}) {ps}
          </p>
        );
      });
    }
  };

  useEffect(() => {
    (async () => {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = dd + '' + mm + '' + yyyy;
      const date_arr = formattedToday.split('');
      var colums = date_arr.map((ps, index) => {
        return (
          <td className="tdp" key={index}>
            {ps}
          </td>
        );
      });
      setDateTd(colums);

    })();
  }, []);

  useEffect(
    () => {
      const formdata = JSON.parse(localStorage.getItem("formData"));
      const customPreviousData = JSON.parse(
        localStorage.getItem("previous_postcode")
      );
      const userBank = localStorage.getItem("bankName");
      let question3 = formdata ? formdata.question_3 : "";
      let userOtherChoose = [];
      let userSituation76 = [];
      let quest79List = [];
      let quest102List = [];
      if ((formdata === undefined || formdata === null || formdata == "") || (source != 'live')) {
        const getResult = async () => {
          let resp = await savePreviewData(customUuid);
          var result = resp.data.result;
          return result;
        };
        getResult().then((res) => {
          const postCodeArr =
            res.postcode.length > 0 ? res.postcode.split("") : [];
          const phoneArr =
            res.telephone.length > 0 ? res.telephone.split("") : [];

          let today = new Date(res.dob);
          let dob_date = String(today.getDate()).padStart(2, "0");
          let dob_month = String(today.getMonth() + 1).padStart(2, "0");
          let dob_year = String(today.getFullYear()).padStart(4, "0");

          const dobDateArr = dob_date.length > 0 ? dob_date.split("") : [];
          const dobMonthArr = dob_month.length > 0 ? dob_month.split("") : [];
          const dobYearArr = dob_year.length > 0 ? dob_year.split("") : [];

          let userAddress = "";
          if (res.address_line3 !== "") {
            userAddress =
              res.address_line1 +
              ", " +
              res.address_line3 +
              ", " +
              res.town +
              ", " +
              res.county +
              ", " +
              res.country +
              ", " +
              res.postcode.toUpperCase();
          } else {
            userAddress =
              res.address_line1 +
              ", " +
              res.town +
              ", " +
              res.county +
              ", " +
              res.country +
              ", " +
              res.postcode.toUpperCase();
          }

          let dependent_status = res.questionData.dependent
            ? res.questionData.dependent
            : "";

          const regis_email = res.questionData.reg_email
            ? res.questionData.reg_email
            : "";
          
          let bankSelect = res.questionData.bank_select
            ? res.questionData.bank_select
            : "";

          let odopeningCdt = res.questionData.odopeningCredit
            ? res.questionData.odopeningCredit
            : "";

          let irresponsible = res.questionData.irresponsibleAct
          ? res.questionData.irresponsibleAct
          : "";

          const recentCdt = res.questionData.recentCredit
            ? res.questionData.recentCredit
            : "";
          const highestCdtLimit = res.questionData.highestCreditLimit
            ? res.questionData.highestCreditLimit
            : "";

          const isSele79 = res.questionData.quest_79
            ? res.questionData.quest_79
            : "";

          if (isSele79) {
            let chooseObj79 = JSON.parse(isSele79);

            if (chooseObj79?.length > 0) {
              chooseObj79.forEach((ele) => {
                const foundopts = wereUalreadyPaying?.answers.find(
                  (obj) => obj.value == ele.id
                );
                if (foundopts.value) {
                  quest79List.push(foundopts?.label);
                }
              });
            }
          }

          let slide80find = res.questionData ? res.questionData.newiande : "";

          let userMortage = '';
          let userRent = '';
          let userTotalUtilityBills = '';
          let userCreditCardRepayments = '';
          let userMonthlyLoanRepayments = '';
          let userMonthlyCarFinance = '';
          let userMonthlyStoreCardPayments = '';
          let userOtherMonthlyPayments = '';
          let parsedSlideQuest80 = '';

          if (slide80find) {

            slide80find = JSON.parse(slide80find);

            let userMortage_find = slide80find.find((arr) => arr[0] === '228');
            if (userMortage_find) {
              userMortage = userMortage_find[1];
            }

            let userRent_find = slide80find.find((arr) => arr[0] === '229');
            if (userRent_find) {
              userRent = userRent_find[1];
            }

            let userTotalUtilityBills_find = slide80find.find((arr) => arr[0] === '230');
            if (userTotalUtilityBills_find) {
              userTotalUtilityBills = userTotalUtilityBills_find[1];
            }

            let userMonthlyLoanRepayments_find = slide80find.find((arr) => arr[0] === '231');
            if (userMonthlyLoanRepayments_find) {
              userMonthlyLoanRepayments = userMonthlyLoanRepayments_find[1];
            }

            let userCreditCardRepayments_find = slide80find.find((arr) => arr[0] === '232');
            if (userCreditCardRepayments_find) {
              userCreditCardRepayments = userCreditCardRepayments_find[1];
            }

            let userMonthlyCarFinance_find = slide80find.find((arr) => arr[0] === '233');
            if (userMonthlyCarFinance_find) {
              userMonthlyCarFinance = userMonthlyCarFinance_find[1];
            }

            let userMonthlyStoreCardPayments_find = slide80find.find((arr) => arr[0] === '234');
            if (userMonthlyStoreCardPayments_find) {
              userMonthlyStoreCardPayments = userMonthlyStoreCardPayments_find[1];
            }

            let userOtherMonthlyPayments_find = slide80find.find((arr) => arr[0] === '235');
            if (userOtherMonthlyPayments_find) {
              userOtherMonthlyPayments = userOtherMonthlyPayments_find[1];
            }
          }
          const overlimit = res.questionData.overlimitCharge
          ? res.questionData.overlimitCharge
          : "";

          const timesLimitExceed = res.questionData.timesExceedLimit
          ? res.questionData.timesExceedLimit
          : "";

          const exceedCrLimit = res.questionData.exceedCreditLimit
          ? res.questionData.exceedCreditLimit
          : "";
          
          const hardship = res.questionData.finHardship
          ? res.questionData.finHardship
          : "";

          // const thinkBackAns = res.questionData.thinkBack
          // ? res.questionData.thinkBack
          // : "";

          const incomeYorN = res.questionData.didReceiveIncome
          ? res.questionData.didReceiveIncome
          : "";

          const cardIncome = res.questionData.cardMonthlyIncome
          ? res.questionData.cardMonthlyIncome
          : "";

          const isSele102 = res.questionData.furtherBorrow
            ? res.questionData.furtherBorrow
            : "";

          if (isSele102) {
            let chooseObj102 = JSON.parse(isSele102);

            if (chooseObj102?.length > 0) {
              chooseObj102.forEach((ele) => {
                const foundopts = furtherBorrows?.answers.find(
                  (obj) => obj.value == ele.id
                );
                if (foundopts.value) {
                  quest102List.push(foundopts?.label);
                }
              });
            }
          }

          const essentialRepay = res.questionData.essentialRepayment
          ? res.questionData.essentialRepayment
          : "";

          const maintainbal = res.questionData.maintainBalance
          ? res.questionData.maintainBalance
          : "";

          const stuckOd = res.questionData.stuckOverdraft
          ? res.questionData.stuckOverdraft
          : "";

          const stuckOdAffect = res.questionData.stuckOverdraftAffect
            ? res.questionData.stuckOverdraftAffect
            : "";

          setTimesExceedLimit(timesLimitExceed);
          setExceedCreditLimit(exceedCrLimit);
          setCardMonthlyIncome(cardIncome);
          setFurtherBorrow(quest102List);
          setEssentialRepayment(essentialRepay);
          setMaintainBalance(maintainbal);
          setStuckOverdraft(stuckOd);
          setStuckOverdraftAffect(stuckOdAffect);
          setRecentCredit(recentCdt);
          setIrresponsibleAct(irresponsible);
          setOdopeningCredit(odopeningCdt);
          setQuestion76(userSituation76);
          // setThinkBack(thinkBackAns);
          setFinHardship(hardship);
          setOverlimitCharge(overlimit);
          setHighestCreditLimit(highestCdtLimit);
          setUserName(res.client_name);
          setAddress(userAddress);
          setUserDob(res.dob);
          setSalutation(res.title);
          setAddressLine1(res.address_line1);
          setAddressLine2(res.address_line3);
          setAddressLine3(res.town);
          setCity(res.town);
          setPostcode(res.postcode);
          setPhone(res.telephone);
          setCounty(res.county);
          setCountry(res.country);
          setEmail(res.email);
          setFname(res.first_name);
          setLname(res.surname);
          setPreviousAddress(res.prevAddress);
          setT2AStatus(res.t2a_status);
          setUserSign(res.signature);
          let timeNow = new Date().toLocaleString();
          timeNow = timeNow.split(" ")[0].replace(/,/g, "");
          setCurrentDate(timeNow);
          setPostcodeArr(postCodeArr);
          setPhoneArr(phoneArr);
          setDobDateArr(dobDateArr);
          setDobMonthArr(dobMonthArr);
          setDobYearArr(dobYearArr);
          setDependent(dependent_status);
          setQuestion79(quest79List);
          setDidReceiveIncome(incomeYorN);
          setUserMortage(userMortage);
          setUserRent(userRent);
          setUserTotalUtilityBills(userTotalUtilityBills);
          setUserCreditCardRepayments(userCreditCardRepayments);
          setUserMonthlyLoanRepayments(userMonthlyLoanRepayments);
          setUserMonthlyCarFinance(userMonthlyCarFinance);
          setUserMonthlyStoreCardPayments(userMonthlyStoreCardPayments);
          setUserOtherMonthlyPayments(userOtherMonthlyPayments);
          setSlide80(slide80find);
          setSelectedLender(bankSelect);
          const email_provided = res.questionData.email_provided
            ? res.questionData.email_provided
            : "";
          if (email_provided && email_provided == '162') {
            setRegisEmail(res.email);
          } else if (email_provided && email_provided == '163') {
            setRegisEmail(regis_email);
          }

        });
      } else {
        const userName = formdata
          ? Capitalize(formdata.txtFName) + " " + Capitalize(formdata.txtLName)
          : "";
        const userdob = formdata
          ? formdata.DobDay + "/" + formdata.DobMonth + "/" + formdata.DobYear
          : "";
        const joineedob = formdata.JointDobDay
          ? formdata.JointDobDay +
          "/" +
          formdata.JointDobMonth +
          "/" +
          formdata.JointDobYear
          : "";
        const userSign = formdata ? formdata.user_sign : "";
        const lstSalutation = formdata ? formdata.lstSalutation : "";
        const addr1 = formdata ? formdata.txtHouseNumber : "";
        const addr2 = formdata ? formdata.txtAddress2 : "";
        const addr3 = formdata ? formdata.txtAddress3 : "";
        const userCity = formdata ? formdata.txtTown : "";
        const userCounty = formdata ? formdata.txtCounty : "";
        const userCountry = formdata ? formdata.txtCountry : "";
        const userDistrict = formdata ? formdata.txtDistrict : "";
        const ph = formdata ? formdata.txtPhone : "";
        const post = formdata ? formdata.txtPostCode.toUpperCase() : "";
        const mail = formdata ? formdata.txtEmail : "";
        const firstName = formdata ? formdata.txtFName : "";
        const lastName = formdata ? formdata.txtLName : "";
        const financestatus = formdata ? formdata.stillactive : "";
        const postCodeArr = post.length > 0 ? post.split("") : [];
        const phoneArr = ph.length > 0 ? ph.split("") : [];
        const previous_name = formdata ? formdata.Previousname : "";
        let today = new Date(userdob);
        let dob_date = String(today.getDate()).padStart(2, "0");
        let dob_month = String(today.getMonth() + 1).padStart(2, "0");
        let dob_year = String(today.getFullYear()).padStart(4, "0");

        const dobDateArr = dob_date.length > 0 ? dob_date.split("") : [];
        const dobMonthArr = dob_month.length > 0 ? dob_month.split("") : [];
        const dobYearArr = dob_year.length > 0 ? dob_year.split("") : [];

        let userAddress = "";

        let user_lender = formdata ? formdata.lender : "";
        let otherlenders = formdata ? formdata.otherlenders : "";

        if (
          user_lender[0]?.value == "others" &&
          (otherlenders !== undefined ||
            otherlenders !== "" ||
            otherlenders !== null)
        ) {
          setSelectedLender(otherlenders);
        } else {
          setSelectedLender(user_lender[0]?.label);
        }
        if (formdata) {
          if (formdata.txtAddress3 !== "") {
            userAddress =
              formdata.txtHouseNumber +
              ", " +
              formdata.txtAddress2 +
              ", " +
              formdata.txtAddress3 +
              ", " +
              formdata.txtTown +
              ", " +
              formdata.txtCounty +
              ", " +
              formdata.txtCountry +
              ", " +
              formdata.txtPostCode.toUpperCase();
          } else {
            userAddress =
              formdata.txtHouseNumber +
              ", " +
              formdata.txtAddress2 +
              ", " +
              formdata.txtTown +
              ", " +
              formdata.txtCounty +
              ", " +
              formdata.txtCountry +
              ", " +
              formdata.txtPostCode.toUpperCase();
          }
          if (formdata.previous_postcode != undefined) {
            let preAddress = "";
            if (
              formdata.previous_address_city !== "" ||
              formdata.previous_address_city !== null
            ) {
              if (formdata.previous_address_line1 !== null) {
                preAddress += formdata.previous_address_line1;
              }
              if (formdata.previous_address_line2 !== "") {
                preAddress += ", " + formdata.previous_address_line2;
              }
              if (formdata.previous_address_town !== "") {
                preAddress += ", " + formdata.previous_address_town;
              }
              if (formdata.previous_address_county !== "") {
                preAddress += ", " + formdata.previous_address_county;
              }
              if (formdata.previous_address_country !== "") {
                preAddress += ", " + formdata.previous_address_country;
              }
              if (formdata.previous_postcode !== "") {
                preAddress += ", " + formdata.previous_postcode.toUpperCase();
              }
              setPreviousAddress(preAddress);
            }
          }
        }



        const questionData = JSON.parse(localStorage.getItem("questionData"));

        const t2a_Validation = localStorage.getItem("t2aValidation");

        setT2AStatus(t2a_Validation);

        if (questionData) {

          let is_stillactive = questionData ? questionData.find((obj) => obj.question_id == '26') : "";

          let financestatus = questionData ? is_stillactive?.option_id : "";

          let timesExceedLimitval = questionData ? questionData.find((obj) => obj.question_id == '96') : "";
          let timesExceedLimitId = questionData ? timesExceedLimitval?.option_id : "";

          let  timesExceedLimit ="";

          if (timesExceedLimitId == 257) {
            timesExceedLimit = "Always";
          } else if (timesExceedLimitId == 258) {
            timesExceedLimit = "Sometimes";
          }  else if (timesExceedLimitId == 259) {
            timesExceedLimit = "Rarely";
          } else if (timesExceedLimitId == 260) {
            timesExceedLimit = "Never";
          } else {
            timesExceedLimit = "";
          }

          let highCredit = questionData ? questionData.find((obj) => obj.question_id == '94') : "";
          const highestCreditLimit = questionData ? highCredit?.input_answer : "";

          let chargelimitval = questionData ? questionData.find((obj) => obj.question_id == '97') : "";
          const limitChargeId = questionData ? chargelimitval?.option_id : "";

          let  limitCharge ="";

          if (limitChargeId == 261) {
            limitCharge = "Always";
          } else if (limitChargeId == 262) {
            limitCharge = "Sometimes";
          }  else if (limitChargeId == 263) {
            limitCharge = "Rarely";
          } else if (limitChargeId == 264) {
            limitCharge = "Never";
          } else {
            limitCharge = "";
          }

          let didReceiveIncome_opt = questionData ? questionData.find((obj) => obj.question_id == '100') : "";
          let didReceiveIncome_value = questionData ? didReceiveIncome_opt?.option_id : "";

          let hardshipVal = questionData ? questionData.find((obj) => obj.question_id == '98') : "";
          let hardshipRes = questionData ? hardshipVal?.option_id : "";
          
          // let thinkBackVal = questionData ? questionData.find((obj) => obj.question_id == '99') : "";
          // let thinkBackValue = questionData ? thinkBackVal?.input_answer : "";

          let repaymentStatus = questionData ? questionData.find((obj) => obj.question_id == '103') : "";
          let repaymentsts = questionData ? repaymentStatus?.option_id : "";

          let balanceVal = questionData ? questionData.find((obj) => obj.question_id == '104') : "";
          let balanceValue = questionData ? balanceVal?.option_id : "";

          let overdraftstuck = questionData ? questionData.find((obj) => obj.question_id == '105') : "";
          let overdraftvalueId = questionData ? overdraftstuck?.option_id : "";

          let  overdraftvalue ="";

          if (overdraftvalueId == 290) {
            overdraftvalue = "Always";
          } else if (overdraftvalueId == 291) {
            overdraftvalue = "Sometimes";
          }  else if (overdraftvalueId == 292) {
            overdraftvalue = "Rarely";
          } else if (overdraftvalueId == 293) {
            overdraftvalue = "Never";
          } else {
            overdraftvalue = "";
          }

          let odstuck = questionData ? questionData.find((obj) => obj.question_id == '106') : "";
          let odstuckaffect = questionData ? odstuck?.input_answer : "";

          let slide80find = questionData ? questionData.find((obj) => obj.slide_80) : null;

          let userMortage = '';
          let userRent = '';
          let userTotalUtilityBills = '';
          let userCreditCardRepayments = '';
          let userMonthlyLoanRepayments = '';
          let userMonthlyCarFinance = '';
          let userMonthlyStoreCardPayments = '';
          let userOtherMonthlyPayments = '';
          let parsedSlideQuest80 = '';

          if (slide80find && Array.isArray(slide80find.slide_80)) {

            let parsedSlide80 = JSON.parse(slide80find.slide_80[1]);
            let parsedSlideQuest80_find = JSON.parse(slide80find.slide_80[0]);

            if (parsedSlideQuest80_find) {
              parsedSlideQuest80 = parsedSlideQuest80_find || '';
            }

            let userMortage_find = parsedSlide80.find((arr) => arr[0] === '228');
            if (userMortage_find) {
              userMortage = userMortage_find[1];
            }

            let userRent_find = parsedSlide80.find((arr) => arr[0] === '229');
            if (userRent_find) {
              userRent = userRent_find[1];
            }

            let userTotalUtilityBills_find = parsedSlide80.find((arr) => arr[0] === '230');
            if (userTotalUtilityBills_find) {
              userTotalUtilityBills = userTotalUtilityBills_find[1];
            }

            let userMonthlyLoanRepayments_find = parsedSlide80.find((arr) => arr[0] === '231');
            if (userMonthlyLoanRepayments_find) {
              userMonthlyLoanRepayments = userMonthlyLoanRepayments_find[1];
            }

            let userCreditCardRepayments_find = parsedSlide80.find((arr) => arr[0] === '232');
            if (userCreditCardRepayments_find) {
              userCreditCardRepayments = userCreditCardRepayments_find[1];
            }

            let userMonthlyCarFinance_find = parsedSlide80.find((arr) => arr[0] === '233');
            if (userMonthlyCarFinance_find) {
              userMonthlyCarFinance = userMonthlyCarFinance_find[1];
            }

            let userMonthlyStoreCardPayments_find = parsedSlide80.find((arr) => arr[0] === '234');
            if (userMonthlyStoreCardPayments_find) {
              userMonthlyStoreCardPayments = userMonthlyStoreCardPayments_find[1];
            }

            let userOtherMonthlyPayments_find = parsedSlide80.find((arr) => arr[0] === '235');
            if (userOtherMonthlyPayments_find) {
              userOtherMonthlyPayments = userOtherMonthlyPayments_find[1];
            }
          }

          let monthlyIncome_find = questionData ? questionData.find((obj) => obj.question_id == '79') : "";
          

          const ques79list = monthlyIncome_find ? monthlyIncome_find?.input_answer : [];

          if (ques79list?.length > 0) {
            ques79list.forEach((ele) => {
              const foundopts = wereUalreadyPaying?.answers.find(
                (obj) => obj.value == ele.id
              );
              if (foundopts.value) {
                quest79List.push(foundopts?.label);
              }
            });
          }

          let furtherBorrow_find = questionData ? questionData.find((obj) => obj.question_id == '102') : "";
          const ques102list = furtherBorrow_find ? furtherBorrow_find?.input_answer : [];

          if (ques102list?.length > 0) {
            ques102list.forEach((ele) => {
              const listfound = furtherBorrows?.answers.find(
                (obj) => obj.value == ele.id
              );
              if (listfound.value) {
                quest102List.push(listfound?.label);
              }
            });
          }

          let reg_email_f = questionData ? questionData.find((obj) => obj.question_id == '48') : "";

          let providedEmil = questionData ? questionData.find((obj) => obj.question_id == '61') : "";
          let providedEmilOption = questionData ? providedEmil?.option_id : "";
          let reg_email = '';
          if (providedEmilOption && providedEmilOption == '162') {
            reg_email = mail;
          } else if (providedEmilOption && providedEmilOption == '163') {
            reg_email = questionData ? reg_email_f?.input_answer : "";
          }

          let credit_limitYN = questionData ? questionData.find((obj) => obj.question_id == '92') : "";
          let recent_credit = questionData ? credit_limitYN?.option_id : "";

          let dependent_status_opt = questionData ? questionData.find((obj) => obj.question_id == '3') : "";
          let dependent_status = questionData ? dependent_status_opt?.option_id : "";
          if(dependent_status == "7") {
            dependent_status = "0"
          } else if(dependent_status == "8") {
            dependent_status = "1"
          } else if(dependent_status == "9") {
            dependent_status = "2"
          } else if(dependent_status == "279") {
            dependent_status = "3"
          } else if(dependent_status == "280") {
            dependent_status = "4"
          } else if(dependent_status == "281") {
            dependent_status = "4+"
          }    

          let card_monthly_income_opt = questionData ? questionData.find((obj) => obj.question_id == '101') : "";
          let card_monthly_incomeId = questionData ? card_monthly_income_opt?.option_id : "";

          let card_monthly_income="";
          if(card_monthly_incomeId == 269){
            card_monthly_income = "Less than £ 500";
          }else if(card_monthly_incomeId == 270){
            card_monthly_income = "£ 500 - £ 1000";
          }else if(card_monthly_incomeId == 271){
            card_monthly_income = "£ 1000 - £ 1500";
          }else if(card_monthly_incomeId == 272){
            card_monthly_income = "£ 1500 - £ 2000";
          }else if(card_monthly_incomeId == 273){
            card_monthly_income = "£ 2500 - £ 3000";
          }else if(card_monthly_incomeId == 274){
            card_monthly_income = "£ 3000 - £ 4000";
          }else if(card_monthly_incomeId == 275){
            card_monthly_income = "More than £ 4000";
          }else{
            card_monthly_income = "";
          }

          let odopeningCreditlim = questionData ? questionData.find((obj) => obj.question_id == '91') : "";
          let odopeningCredit = questionData ? odopeningCreditlim?.input_answer : "";

          let irresponsibleAct = questionData ? questionData.find((obj) => obj.question_id == '93') : "";

          let bankAct = questionData ? irresponsibleAct?.option_id : "";

          const lender_option = questionData ? questionData.find((obj) => obj.question_id == '64') : "";
          let selectLender = questionData ? lender_option?.input_answer : "";

          const exceedlimitval = questionData ? questionData.find((obj) => obj.question_id == '95') : "";
          let exceedlimit = questionData ? exceedlimitval?.option_id : "";

          setHighestCreditLimit(highestCreditLimit);
          setFinHardship(hardshipRes);
          setOverlimitCharge(limitCharge);
          // setThinkBack(thinkBackValue);
          setEssentialRepayment(repaymentsts);
          setStuckOverdraftAffect(odstuckaffect);
          setDidReceiveIncome(didReceiveIncome_value);
          setCardMonthlyIncome(card_monthly_income);
          setMaintainBalance(balanceValue);
          setStuckOverdraft(overdraftvalue);
          setUserMortage(userMortage);
          setUserRent(userRent);
          setUserTotalUtilityBills(userTotalUtilityBills);
          setUserCreditCardRepayments(userCreditCardRepayments);
          setUserMonthlyLoanRepayments(userMonthlyLoanRepayments);
          setUserMonthlyCarFinance(userMonthlyCarFinance);
          setUserMonthlyStoreCardPayments(userMonthlyStoreCardPayments);
          setUserOtherMonthlyPayments(userOtherMonthlyPayments);
          setSlide80(parsedSlideQuest80);
          setOtherChoose(userOtherChoose);
          setRegisEmail(reg_email);
          setTimesExceedLimit(timesExceedLimit);
          setRecentCredit(recent_credit);          
          setDependent(dependent_status);
          setOdopeningCredit(odopeningCredit);
          setExceedCreditLimit(exceedlimit)
          setIrresponsibleAct(bankAct);
          setQuestion76(userSituation76);
          setQuestion79(quest79List);
          setFurtherBorrow(quest102List);
        }

        const partnerSign = formdata ? formdata.partner_sign : "";
        setBankName(userBank);
        setUserName(userName);
        setAddress(userAddress);
        setJoineeName(joineeName);
        setUserDob(userdob);
        setJoineeDob(joineedob);
        setSalutation(lstSalutation);
        setAddressLine1(addr1);
        setAddressLine2(addr2);
        setAddressLine3(addr3);
        setCity(userCity);
        setPostcode(post);
        setPhone(ph);
        setCounty(userCounty);
        setCountry(userCountry);
        setEmail(mail);
        setFname(firstName);
        setLname(lastName);
        setPostcodeArr(postCodeArr);
        setPhoneArr(phoneArr);
        setDobDateArr(dobDateArr);
        setDobMonthArr(dobMonthArr);
        setDobYearArr(dobYearArr);
        setPreviousName(previous_name);

        if (question3 == 5) {
          setJoineeAdress(userAddress);
          setJoineeSign(partnerSign);
        } else {
          setJoineeAdress("");
          setJoineeSign("");
        }
        setUserSign(userSign);
        let timeNow = new Date().toLocaleString();
        timeNow = timeNow.split(" ")[0].replace(/,/g, "");
        if (question3 == 5) {
          setPartnerDate(timeNow);
          setPartnerSignShow("show");
        } else {
          setPartnerDate("");
        }
        setCurrentDate(timeNow);

      }

      const localSortcode = localStorage.getItem("sort_code");
      if (
        localSortcode != undefined &&
        localSortcode != "null" &&
        localSortcode != "" &&
        localSortcode != "Not In The List"
      ) {
        const l_SortCode = Array.from(String(localSortcode), String);
        setUserSortCode(l_SortCode);
      }
      const localAccountNumber = localStorage.getItem("account_number");
      if (
        localAccountNumber != undefined &&
        localAccountNumber != "null" &&
        localAccountNumber != ""
      ) {
        const l_AccountNumber = Array.from(String(localAccountNumber), String);
        setUserBankAccount(l_AccountNumber);
      }
    },
    []
  );

  function Capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  

  return (
    <>
      <div className="OD_UFL_4">
        <GetVisitorsParams />

        <div className="signinpopup">
          <div
            className={`modal blackover fade ${showSign ? "show" : "hide"}`}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content   signinpopup">
                <div className="modal-body">
                  <div className="row align-items-center text-center">
                    <h2>IMPORTANT</h2>
                    <p>
                      <b>
                        {cfirstName}, we believe your complaint against Overdraft
                        has merit and are ready to assist you with pursuing this
                        matter further. We require every one of our clients to
                        confirm their signature and make sure they have read all
                        our following documents.
                      </b>
                    </p>
                    <div className="row justify-content-center">
                      <ul>
                        <li>
                          <a href={LOA} target="_blank">
                            <i className="bi bi-arrow-up-right-square" /> Letter
                            of Authority
                          </a>
                        </li>
                        <li>
                          <a href={TOB} target="_blank">
                            <i className="bi bi-arrow-up-right-square" /> Terms of Business
                          </a>
                        </li>
                        <li>
                          <a href={VQ} target="_blank">
                            <i className="bi bi-arrow-up-right-square" />{" "}
                            Questionnaire
                          </a>
                        </li>
                        <li>
                          <a href={DECL} target="_blank">
                            <i className="bi bi-arrow-up-right-square" />{" "}
                            Complaint Form & Declaration
                          </a>
                        </li>
                        <li>
                          <a href={DSAR} target="_blank">
                            <i className="bi bi-arrow-up-right-square" />{" "}
                            DSAR Letter of Authority
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="popup-sign-sec">
                      <div className="signbox">
                        <div
                          id="canvas"
                          className={`${canvasShow ? "hide" : "show"}`}
                        >
                          <div ref={signatureWrapRef}>
                            <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: signatureWrapWidth,
                                height: signatureWrapHeight,
                              }}
                              ref={signPadRef}
                              defaultValue={userSign}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                }
                              }}
                            />
                          </div>
                          {state.isCanvasEmpty && (
                            <div
                              className="sign_msg col-12 text-center sign_msg mb-3"
                              id="signatures_required"
                            >
                              Signature is required!
                            </div>
                          )}
                          {state.isValid && (
                            <div
                              className="sign_msg col-12 text-center sign_msg"
                              id="signatures_valid"
                            >
                              Draw valid signature!
                            </div>
                          )}
                          <div className="input-group mb-3 justify-content-lg-center justify-content-between">
                            <div className="mb-3 mt-3 col-5 p-0">
                              <input
                                type="button"
                                name="clearbutton"
                                id="clearbutton"
                                className="btn btn-secondary"
                                defaultValue="Clear"
                                onClick={clearSign}
                              />
                            </div>
                            <div className="mb-3 mt-3 col-7 p-0">
                              <input
                                type="button"
                                name="signbutton"
                                id="signbutton"
                                className="signbutton"
                                defaultValue="Review & Sign >"
                                onClick={handleClick}
                              />
                            </div>
                          </div>
                        </div>
                        <div></div>
                        <div
                          className={`${canvasShow ? "show" : "hide"
                            } text-center`}
                        >
                          <div id="preview" className="web-canvas">
                            <img width="300" height="200" src={userSign} />
                          </div>

                          <div className="input-group mb-3 justify-content-lg-center justify-content-between">
                            <div className="mb-3 mt-3 col-5 p-0">
                              <input
                                type="button"
                                name="clearbutton"
                                id="clearbutton"
                                className="btn btn-secondary"
                                defaultValue="Retake"
                                onClick={clearSign}
                              />
                            </div>
                            <div className="mb-3 mt-3 col-7 p-0">
                              <input
                                type="button"
                                name="signbutton"
                                id="signbutton"
                                className="signbutton"
                                defaultValue="Review & Sign >"
                                onClick={modalChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='OD_UFL_4' >
          {/* page-1 LOA */}
          <section className="container-1 my-5 important-margin">
            <table className="sec_loa">
              <tbody>
                <tr>
                  <td className="first_td">
                    <div className="d-flex">
                      <div className="w-50">
                        <img src={Logo} className="logo_imgloa" />
                      </div>
                      <div className="w-50 ">
                        <p className="loa_header_address">
                          Arena, 9 Nimrod Way, Ferndown BH21 7UH <br />
                          Telephone: 01202 022350
                        </p>
                      </div>
                    </div>
                    <h1 className="loa_h1"> Letter of Authority </h1>
                    <p style={{ fontSize: 9 }}>
                      As provided for under Rule DISP2.4.16R of the FCA Handbook, which states that ‘ A complaint may be brought on behalf of an eligible complainant, or a
                      deceased person who would have been an eligible complainant, by a person authorised by the eligible complainant or authorised by law
                    </p>
                    <table className="loa_table1">
                      <tbody>
                        <tr>
                          <td colSpan={2} style={{ background: '#e6e7e9' }}>
                            To whom it may concern
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '16%', padding: 16 }}> <b> Bank/Lender</b></td>
                          {/* <td>Vanquis</td> */}
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: '100%', marginTop: 6, marginBotom: 12, fontSize: 11 }}>
                      <tbody>
                        <tr>
                          <td> <b> PRODUCTS </b></td>
                          <td style={{ textAlign: 'end' }}> <div style={{ border: '1px solid black', height: 12, width: 12 }} /></td>
                          <td> Loans</td>
                          <td style={{ textAlign: 'end' }}> <div style={{ border: '1px solid black', height: 12, width: 12 }} /></td>
                          <td> Credit Cards</td>
                          <td style={{ textAlign: 'end', fontSize: '16px' }}>
                            &#9745;
                          </td>
                          <td> Overdrafts</td>
                          <td style={{ textAlign: 'end' }}> <div style={{ border: '1px solid black', height: 12, width: 12 }} /></td>
                          <td> Car Finance</td>
                        </tr>
                      </tbody>
                    </table>
                    <p style={{ marginTop: 6, marginBottom: 4 }}> <b> Authorisation to TMS Legal Ltd:</b></p>
                    <ol>
                      <li style={{ paddingBottom: 0 }}>
                        I/We have instructed TMS Legal Ltd and hereby authorise them to act on my/our behalf as solicitors and agents in pursuing my/our
                        complaint in respect of irresponsible lending/unaffordability and/or sales made by the above named company ('the company') and any
                        other products I/we hold or have held with you.
                      </li>
                    </ol>
                    <p style={{ marginTop: 6, marginBottom: 4 }}>  <b> Instructions to the Company</b></p>
                    <ol start={2}>
                      <li>
                        Please take this letter of authority as my/our instructions to you, to deal directly with TMS Legal Ltd, as my/our solicitors and agents,
                        in respect of the complaint and to provide them with any information they request either verbally or in any other format in order that
                        they may pursue my/our claim. This authority extends to providing TMS Legal Ltd with the Final Response Letter/Outcome ('FRL')
                        prepared by your company.
                      </li>
                      <li>
                        With effect from the date I/we signed this letter of authority I/we instruct the company to adhere to my/our signed GDPR declaration
                        on page 2 of this letter of authority in accordance with my GDPR rights.
                      </li>
                      <li>
                        I/We authorise and instruct TMS Legal Ltd as solicitors and agents to receive on my/our behalf any offer of redress awarded or agreed.
                        Any cheque or electronic payments of redress should be sent to and made payable to TMS Legal Ltd who will then deduct their fees
                        pursuant to my/our agreement with them and send me/us the balance by cheque or electronic payment.
                      </li>
                      <li>
                        I/We confirm that TMS Legal have complete authority at each distinct stage of my/our complaint. This includes giving TMS Legal
                        authority to decide on accepting or rejecting settlement offers/lenders decisions on your behalf. TMS authority also includes TMS
                        Legal having complete authority to support the Financial Ombudsman Service ('FOS') outcomes and redress decisions.
                      </li>
                      <li>
                        I/We authorise TMS Legal to refer my/our complaint to the FOS; not satisfied with the outcome.
                      </li>
                      <li>
                        If a copy of this Letter of Authority is provided, it will have the same validity as the original.
                      </li>
                      <li>
                        I/We request that you do not log the complaint until you have fully approved TMS Legal Ltd's authority as we do not want to proceed
                        without them representing us.
                      </li>
                    </ol>
                    <table className="table_1a" ref={divRefOne}>
                      <tbody>
                        <tr>
                          <td colSpan={3} style={{ background: '#e6e7e9', padding: 4, fontSize: 13 }}>
                            <b> Customer 1</b>
                          </td>
                          <td colSpan={3} style={{ background: '#e6e7e9', padding: 4, fontSize: 12 }}>
                            <b>Customer 2</b>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30, fontSize: 10, width: '12%' }}>
                            Full Name
                          </td>
                          <td colSpan={2} style={{ width: '38%' }}>
                            {userName}
                          </td>
                          <td style={{ width: '12%' }}>
                            Full Name
                          </td>
                          <td colSpan={2} style={{ width: '38%' }}> </td>
                        </tr>
                        <tr>
                          <td style={{ height: 60 }}>
                            Address
                          </td>
                          <td colSpan={2}>  <br />{address}</td>
                          <td>
                            Address
                          </td>
                          <td colSpan={2}> <br /> </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30 }}>
                            Date of Birth
                          </td>
                          <td colSpan={2}>{userDob}</td>
                          <td>
                            Date of Birth
                          </td>
                          <td colSpan={2}> </td>
                        </tr>
                        <tr>
                          <td className="anim_ylw_preview" colSpan={2} style={{ verticalAlign: 'bottom', fontSize: 9, width: '34%', height: 70 }}>
                            <img style={{ height: "40px", maxwidth: "80%" }} src={userSign} />
                            <div> SIGNATURE</div>
                          </td>
                          <td style={{ fontSize: 9, verticalAlign: 'bottom', width: '16%' }}>
                            <div>{currentDate}</div>
                            <span>DATE</span> </td>
                          <td style={{ fontSize: 9, verticalAlign: 'bottom', width: '34%' }} colSpan={2}>
                            SIGNATURE
                            {/*  <img style="height:50px; max-width:80%" src="customer_2_signature_image" /> */}
                          </td>
                          <td style={{ fontSize: 9, verticalAlign: 'bottom', width: '16%' }}> DATE</td>
                        </tr>
                      </tbody>
                    </table>
                    <p style={{ margin: 0, marginBottom: 3 }}>
                      <b> By signing this Letter of Authority:</b>
                    </p>
                    <p style={{ color: '#ee232e', fontSize: 11, margin: 0, marginBottom: 0 }}>
                      <b>
                        • I/We agree to the terms and conditions contained in this Letter of Authority and in the Terms of Business Agreement provided
                      </b>
                    </p>
                    <p style={{ color: 'black', fontSize: 11, margin: 0, marginBottom: 0 }}>
                      <b>
                        • I/We agree to pay fees to TMS Legal Ltd as laid out in the Terms of Business Agreement ('TOBA').
                      </b>
                    </p>
                    <div className="row mt-3">
                      <div className="col-lg-7">
                        <p style={{ lineHeight: '12px', margin: 0, marginBottom: 4 }}>
                          <span className='important-font-size'>
                            TMS Legal Ltd is authorised and regulated by the Solicitors Regulation Authority, Number: 645041.
                            <br />
                            TMS Legal Ltd is a limited company registered in England and Wales, 10315962. <br />
                            Registered Office address: Arena, 9 Nimrod Way, Ferndown, Dorset, BH21 7UH

                          </span>
                        </p>
                      </div>
                      <div className="col-lg-5">
                        <img style={{ width: 280 }} src={OverLeaf} />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-2 LOA  */}
          <section className="container-1 my-5">
            <table className="sec_loa">
              <tbody>
                <table>
                  <tbody>
                    <tr>
                      <td className="c-address" style={{ width: "40%" }}>
                        <div style={{ paddingTop: "10px" }}>Current Address</div>
                      </td>
                      <td style={{ width: "10%" }} />
                      <td style={{ width: "40%" }}>
                        <p style={{ paddingTop: "10px" }}></p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="c-address"
                        style={{
                          width: "40%",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                        }}
                      >
                        {address}
                      </td>
                      <td style={{ width: "10%" }} />
                      <td
                        style={{
                          width: "40%",
                          paddingTop: "10px",
                        }}
                      />
                    </tr>
                    <tr>
                      <td className="c-address" style={{ width: "40%" }}>
                        <div style={{ paddingTop: "10px" }}>Previous Address</div>
                      </td>
                      <td style={{ width: "10%" }} />
                      <td style={{ width: "40%" }}>
                        <p style={{ paddingTop: "10px" }}></p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="c-address"
                        style={{
                          width: "40%",
                          borderBottom: "1px solid #000000",
                          paddingTop: "10px",
                        }}
                      >
                        {previousAddress}
                      </td>
                      <td style={{ width: "10%" }} />
                      <td
                        style={{
                          width: "40%",
                          paddingTop: "10px",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                <tr>
                  <td className="first_td" style={{ paddingTop: 20 }}>
                    <div style={{ width: '100%', borderTop: '1px solid #231f20', height: 1, marginBottom: 6 }}> </div>
                    <h1 className="loa_h1"> UK GDPR Notification</h1>
                    <p> <b> In accordance with my rights under the UK GDPR and specifically Chapter II Article 6 and Chapter III Articles 12, 13 and 16 therein:</b></p>
                    <ol>
                      <li>
                        I authorise my data controller at TMS Legal Ltd to exchange and receive all relevant data pertaining to my complaint in written,
                        electronic and verbal format with the data controller of the Lender specified on this Letter of Authority.
                      </li>
                      <li>
                        Any requests for information relating to my complaint must be made electronically to my TMS Legal Ltd data controller.
                      </li>
                      <li>
                        The final response letter and any other data pertaining to my complaint must be passed to TMS Legal Ltd electronically or verbally
                      </li>
                      <li>
                        TMS Legal Ltd have verified my identity. As such you must update your records in accordance with their more recent data should
                        you discover any discrepancies.
                      </li>
                    </ol>
                    <table className="w-100" ref={divRefTwo}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%' }}>
                            <div style={{ height: 60, paddingRight: 18 }}>
                              <p> Name</p>
                              <div style={{ borderBottom: '1px solid black', height: 26 }}>{userName} </div>
                            </div>
                          </td>
                          <td style={{ width: '50%' }}>
                            <div style={{ height: 60, paddingLeft: 18 }}>
                              <p> Name</p>
                              <div style={{ borderBottom: '1px solid black', height: 26 }}></div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '50%' }}>
                            <div style={{ height: 60, paddingRight: 18 }}>
                              <p> Date</p>
                              <div style={{ borderBottom: '1px solid black', height: 26 }}>{currentDate}</div>
                            </div>
                          </td>
                          <td style={{ width: '50%' }}>
                            <div style={{ height: 60, paddingLeft: 18 }}>
                              <p> Date</p>
                              <div style={{ borderBottom: '1px solid black', height: 26 }}></div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '50%' }}>
                            <div className="anim_ylw_preview" style={{ height: 60, paddingRight: 18 }}>
                              <p> Signature</p>
                              <div style={{ borderBottom: '1px solid black', height: 34 }}>
                                <img src={userSign} style={{ height: 34, width: 'auto' }} />
                              </div>
                            </div>
                          </td>
                          <td style={{ width: '50%' }}>
                            <div style={{ height: 60, paddingLeft: 18 }}>
                              <p> Signature</p>
                              <div style={{ borderBottom: '1px solid black', height: 34 }}></div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="mt-3"><b> * Please note that the data controller for TMS Legal Ltd is Nick Stocks</b></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-1 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <h5 className="text-center"> TMS Legal Ltd -Terms of Business Agreement("TOBA") </h5>
                    <p>
                      The terms set out in the Letter of Engagement, Letter of Authority, Power of Attorney, UK GDPR notification
                      and these Terms of Business are the terms and conditions on which TMS Legal Ltd (‘the Company’ ‘we’ or
                      ‘our’ or ‘us’) will provide its Services to you.
                    </p>
                    <p>
                      We are a law firm authorised and regulated by the Solicitors Regulation Authority. Our registration number is
                      645041. We comply with the SRA Standards and Regulations which are available on the SRA website at <br />
                      <span style={{ textDecoration: 'underline' }}><a href="https://www.sra.org.uk" target="_blank">www.sra.org.uk.</a></span>
                    </p>
                    <p>
                      By agreeing to instruct us to pursue your complaint or claim on a Conditional Fee Agreement ("CFA")
                      (otherwise known as being on a No Win: No Fee basis) you understand that you have the option to bring a
                      complaint or claim for redress yourself without the involvement of TMS Legal Ltd.
                    </p>
                    <h5 style={{ textAlign: 'left', marginBottom: 0, marginLeft: 28 }}> 1. &nbsp; &nbsp; Definitions </h5>
                    <ul style={{ paddingTop: 0, margin: 0 }}>
                      <li style={{ paddingTop: 0 }}>
                        <p>
                          <b> ‘ADR’ </b>: refers to the <b> Alternative Dispute Resolution for Consumer
                            Disputes (Amendment) Regulations</b> [SI 2015 No.1392; dated July 2015], enabling you
                          and the Company to submit a monetary financial services disputes to be resolved by the
                          FOS; without having to incur the significant cost, delay and disproportionate up-front
                          expense of having to go to Court in the UK, in circumstances where there is nonCompliance with the Online Dispute Resolution Regulation time line of eight weeks.
                        </p>
                        <h5 style={{ textAlign: 'left' }}> ‘Services’: </h5>
                        <ul>
                          <li>
                            Submitting a complaint or claim to the Lender seeking redress for a mis-sold Packaged Bank
                            Account (‘PBA’).
                          </li>
                          <li>
                            Submitting a complaint or claim to the Lender seeking redress for unaffordable lending in relation
                            to overdrafts, credit cards, loans and other types of credit agreements.
                          </li>
                          <li>
                            Submitting a complaint or claim to the Lender seeking redress for breach of contract or
                            misrepresentation by a retailer or trader when goods or services were purchased using a
                            credit card.
                          </li>
                          <li>
                            Submitting a complaint or claim to the Lender seeking redress for unaffordable lending in relation
                            to a motor finance agreement (‘MFA’).
                          </li>
                          <li>
                            Submitting a complaint or claim to the Financial Ombudsman Service (‘FOS’) where:
                            <ul style={{ listStyleType: 'disc', paddingLeft: 12, paddingTop: 12, paddingBottom: 12 }}>
                              <li style={{ marginBottom: 6 }}>there has been no Final Response Letter from the Lender within agree time lines, or</li>
                              <li style={{ marginBottom: 6 }}>the complaint or claim submitted to the Lender has been rejected, or </li>
                              <li style={{ marginBottom: 6 }}>we disagree with the findings of the Lender </li>
                            </ul>
                            but only when we consider it reasonable, meritorious, appropriate and proportionate to do so, and
                            have sufficient information on which to base it.  <br /> <br />
                            By signing these Terms of Business you give us express written authority and permission to append to
                            your signed FOS Complaint Form factual information obtained in connection with the submission of the
                            original Complaint to the Lender
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-2 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <ul style={{ paddingTop: 24 }}>
                      <ul>
                        <li>
                          Where one or more of the above claims has been successful we may seek to recover from
                          HMRC any interest on basic rate tax deducted from the redress you receive
                        </li>
                        <li>
                          Unless otherwise instructed by you, TMS will always refer your Complaint to the FOS 8-10 weeks
                          after your Complaint or claim is lodged with a UK Bank (or other lender); that is, if TMS has not
                          received a meaningful Final Response Letter ("FRL") from the Bank or Lender, since Banks and
                          Lenders are required to respond to any Complaint promptly within 8 weeks [FCA Rules], and under
                          English law and sometimes fail to do so; resulting in Complaints taking 8-12 months to resolve
                          instead of 8-12 weeks.
                        </li>
                        <li>
                          Unless otherwise instructed by you, or in "exceptional circumstances", TMS will normally
                          accept and recommend that any adjudication by the FOS should be accepted by
                          you without your further instructions.
                        </li>
                        <li>
                          <i> “Exceptional Circumstances” </i>would include where the FOS alters or changes its policy
                          position for any reason.
                        </li>
                      </ul>
                      <li>
                        <b> ‘Client’:</b> is ‘you’ or ‘your’
                      </li>
                      <li>
                        <b> ‘Complaint or Claim’:</b> is the submission of a complaint or claim to a Lender
                      </li>
                      <li>
                        <b> ‘Complaint’:</b> is "any oral or written expression of dissatisfaction, whether justified or not, from, or on
                        behalf of, a person about the provision of, or failure to provide, a financial service, claims management
                        service of a redress determination, which alleges that the complainant has suffered (or may suffer)
                        financial loss, material distress or material inconvenience." <b>[Per FCA Handbook DISP 1.1]</b>
                      </li>
                      <li> <b>'FCA'</b>: is the Financial Conduct Authority</li>
                      <li>
                        <b>  ‘Financial Ombudsman Service (‘FOS’)’: </b>is a body which operates a dispute resolution scheme that can resolve
                        disputes with financial companies .
                      </li>
                      <li>
                        <b>‘Financial Product’:</b> means one or more of the following - a loan, an overdraft, a credit card, a
                        debt, a motor finance agreement
                      </li>
                      <li>
                        <b> ‘Lender’:</b> means a bank, financial institution, building society or other credit provider
                      </li>
                      <li>
                        <b> ‘Letter of Authority’/ Power of Attorney</b> is the form you sign and return to us:
                        <ul>
                          <li>
                            Providing written confirmation that you agree to the terms and conditions upon which we are
                            prepared to act for you.
                          </li>
                          <li>
                            giving authority to us to act on your behalf in relation to your complaint or claim as solicitors and
                            agents.
                          </li>
                          <li>
                            <b>  MFA’:</b> is a motor finance agreement, such as a Personal Contract Purchase, Hire Purchase
                            Agreement, Conditional Sale Agreement, Hire or Lease Agreement.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-3 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <ul style={{ paddingTop: 24 }}>
                      <ul>
                        <li>
                          <b> 'Redress':</b>
                          <ul style={{ marginTop: 16 }}>
                            <li> is the total compensation offered by your Lender in response to a claim, and /or </li>
                            <li>awarded by the Financial Ombudsman Service, and/or </li>
                            <li>any tax recovery we obtain from HMRC on your behalf</li>
                          </ul>
                          <p style={{ marginTop: 60 }}>Redress includes some or all of the following:</p>
                          <ul>
                            <li style={{ marginBottom: 0 }}>A refund of premium</li>
                            <li style={{ marginBottom: 0 }}> Interest</li>
                            <li style={{ marginBottom: 0 }}>Fees and charges</li>
                            <li style={{ marginBottom: 0 }}> Overdraft interest </li>
                            <li style={{ marginBottom: 0 }}> A reduction, or waiver, to an outstanding debt or overdraft</li>
                            <li style={{ marginBottom: 0 }}> A payment as a gesture of goodwill, or for distress and inconvenience</li>
                            <li style={{ marginBottom: 0 }}> A refund of tax</li>
                            <li style={{ marginBottom: 0 }}> Payments from a credit card company</li>
                            <li style={{ marginBottom: 0 }}> Repair costs</li>
                            <li style={{ marginBottom: 0 }}> Reimbursements</li>
                            <li style={{ marginBottom: 0 }}> Refunded interest on borrowing</li>
                            <li style={{ marginBottom: 0 }}> Reworking of a debt</li>
                            <li style={{ marginBottom: 0 }}> Removal of interest, fees and charges from an account</li>
                            <li style={{ marginBottom: 0 }}> Reduction of outstanding debt</li>
                            <li style={{ marginBottom: 0 }}> Write-off of an outstanding balance</li>
                            <li style={{ marginBottom: 0 }}> Compensation or other benefits received</li>
                            <li style={{ marginBottom: 0 }}> A reduction in the interest rate</li>
                            <li style={{ marginBottom: 0 }}> A refund of hidden or secret commission</li>
                            <li style={{ marginBottom: 0 }}> A refund of the amount you have been overcharged </li>
                            <li style={{ marginBottom: 0 }}> A waiver of any fees or charges</li>
                          </ul>
                        </li>
                      </ul>
                    </ul></td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-4 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <ul>
                      <li><b>‘Fees’: We operate on a NO WIN NO FEE basis – what does this mean?</b></li>
                      <ul>
                        <li>
                          We will not charge a fee where there has been no redress.
                        </li>
                      </ul>
                      <p style={{ marginLeft: 6 }}>
                        The fees payable to us are calculated as a percentage of the redress, and are inclusive of VAT, this fee
                        depends on which band your compensation payout falls into, with a maximum fee per band, as per below:
                      </p>
                    </ul>
                    <table className="table_maxfee">
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'left' }}>Band</td>
                          <td style={{ textAlign: 'left' }}>Redress </td>
                          <td style={{ textAlign: 'left' }}>Percentage rate the Success
                            Fee is calculated on
                            (including VAT)
                          </td>
                          <td style={{ textAlign: 'left' }}>Maximum Success Fee
                            in band (including VAT) </td>
                        </tr>
                        <tr>
                          <td> 1 </td>
                          <td>£1 to £1,499</td>
                          <td>36% </td>
                          <td>£504
                          </td>
                        </tr>
                        <tr>
                          <td> 2</td>
                          <td> £1,500 to £9,999</td>
                          <td>33.60% </td>
                          <td> £3,000 </td>
                        </tr>
                        <tr>
                          <td> 3 </td>
                          <td>£10,000 to £24,999</td>
                          <td>30%</td>
                          <td> £6,000</td>
                        </tr>
                        <tr>
                          <td> 4</td>
                          <td> £25,000 to £49,999</td>
                          <td> 24%</td>
                          <td> £9,000 </td>
                        </tr>
                        <tr>
                          <td> 5</td>
                          <td> £50,000 or more</td>
                          <td>18% </td>
                          <td> £12,000</td>
                        </tr>
                      </tbody>
                    </table>
                    <h5 className="text-center mt-4"> FEE EXAMPLES </h5>
                    <table className="table_feeeg w-100 mt-3">
                      <tbody>
                        <tr>
                          <td colSpan={3} className="border-0 text-center"> LOWER EXAMPLE</td>
                          <td colSpan={2} className="border-0 text-center"> HIGHER EXAMPLE</td>
                        </tr>
                        <tr>
                          <td> Band</td>
                          <td>Redress</td>
                          <td>Fee</td>
                          <td> Redress</td>
                          <td> Fee</td>
                        </tr>
                        <tr>
                          <td>1 </td>
                          <td> £100 </td>
                          <td> £36</td>
                          <td> £1,499 </td>
                          <td> £504</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td> £12,000</td>
                          <td> £3,600 </td>
                          <td> £24,999 </td>
                          <td> £6,000 </td>
                        </tr>
                        <tr>
                          <td> 4 </td>
                          <td> £30,350 </td>
                          <td> £7,284</td>
                          <td> £49,999 </td>
                          <td> £9,000</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td> £55,000</td>
                          <td> £9,900</td>
                          <td> £100,000 </td>
                          <td> £12,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-5 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <h5 className="text-center"> FEE EXAMPLE (in the form of a reduction of a debt/loan or overdraft)</h5>
                    <div style={{ border: '1px solid black', padding: 8 }}>
                      <p> Let us suppose that you are offered a £2,000 reduction in the amount of your debt, loan or
                        overdraft, in response to your claim:
                      </p>
                      <p>The fee is calculated based on band <b> 2</b> (see table above): 33.60% (inclusive of VAT)
                      </p>
                      <p>
                        The fee payable to us will be £672
                      </p>
                      <p><b> Your debt, loan or overdraft will be reduced by £2000</b></p>
                    </div>
                    <h5 className="text-center mt-4"> FEE EXAMPLE (in the form of a cash payment and a reduction of a debt, loan or overdraft)</h5>
                    <div style={{ border: '1px solid black', padding: 8 }}>
                      <p> Let us suppose that you are offered a £2,000 total redress – in the form of a £600 reduction in
                        your debt, loan or overdraft – and a £1,400 cash payment – in response to your claim:
                      </p>
                      <p>The fee is calculated based on band 2 (see table above): 33.60% (inclusive of VAT)
                      </p>
                      <p>
                        The fee payable to us will be £672
                      </p>
                      <p><b> Your debt, loan or overdraft will be reduced by £600 – and you will receive £728</b></p>
                    </div>
                    <p className="mt-5"> <b> 2. Responsibilities</b> </p>
                    <p style={{ marginLeft: 16, marginTop: 30 }}> <b> We will:</b></p>
                    <ul>
                      <li>Treat you fairly and with respect </li>
                      <li>Rely on the information and documents you provide us as being accurate and complete</li>
                      <li>Pursue a complaint or claim when we have reasonable grounds to do so and sufficient information
                        on which to base it </li>
                      <li>Communicate with you in plain language </li>
                      <li>Review your matter regularly </li>
                      <li>Notify you promptly on receipt of responses from your Lender </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-6 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <p style={{ marginLeft: 16, marginTop: 40 }}> <b> You will:</b></p>
                    <ul>
                      <li>
                        Provide us with clear and accurate information to be used in pursuing a claim including bank statements,
                        and access to credit reports and open banking
                      </li>
                      <li> Respond to any request for further information without delay </li>
                      <li> Inform us immediately of any change of address or contact details including email and telephone
                        number</li>
                      <li> Inform us promptly should you receive a Final Response Letter from the Lender or any other
                        correspondence ,and send us a copy if we request it</li>
                      <li> Inform us promptly when redress is paid to you directly by the Lender</li>
                      <li>Co-operate and not mislead us</li>
                      <li>
                        Not negotiate any settlement independently of us. All communications should take place between
                        us and the Lender
                      </li>
                      <li> Pay our fees where there has been redress</li>
                    </ul>
                    <p style={{ marginTop: 50 }}> <b> 3. Service levels and frequency of communication</b> </p>
                    <ul>
                      <li>
                        We will update you by telephone or in writing on your matter regularly. When we use the term ‘in
                        writing’ we include any form of written electronic communication normally used for business
                        purposes, such as emails and texts.
                      </li>
                      <li>We will explain to you the work required as your matter progresses. </li>
                      <li> We will update you on the likely timescales for each stage of the matter and update you on any
                        material change</li>
                    </ul>
                    <ul style={{ marginLeft: 16 }}>
                      <li>
                        Our correspondence with you will name an individual whom you can contact to discuss your claim . <br />
                        Alternatively, you can contact the office manager on: 01202 022350, or by email at <br />
                        <span style={{ textDecoration: 'underline' }}><a href="mailto:info@moneysolicitor.com" target="_blank">info@moneysolicitor.com</a></span> who has supervisory responsibility for the claims team.
                      </li>
                    </ul>
                    <p style={{ marginTop: 40, marginBottom: 12 }}>  <b> 4. Cancellation or Change of Terms by Lenders</b></p>
                    <ul>
                      <li> <b> Cancellation of PBA Policies</b>  <br /> <br />
                        You acknowledge that in the event of a successful claim and your Lender making an offer of redress<br />
                        the PBA benefits associated with your account may be downgraded or cancelled and it is your<br />
                        responsibility to arrange replacement cover if required.
                      </li>
                      <li> <b> Cancellation of Financial Products</b>  <br /> <br />
                        You acknowledge that the Lender may cancel, withdraw or change the terms of any of your financial <br />
                        product(s) on receipt of a claim , and it is your responsibility to arrange replacement
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-6 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <p className="ps-5">
                      financial facilities if needed. <br />  <br />
                      In the event of a motor finance agreement the Lender may demand the return of a vehicle, and you may <br />
                      need to acquire a replacement. <br />  <br />
                      You acknowledge that the lender may refuse to lend to you in future.
                    </p>
                    <p style={{ marginTop: 36 }}> <b> 5. Limit of Liability and Professional Indemnity Insurance</b></p>
                    <ul>
                      <li>We maintain professional indemnity insurance in accordance with the requirements of the Solicitors <br />
                        Regulation Authority and a copy of our policy schedule is available for inspection by clients at our <br />
                        office. Our liability to you in respect of professional negligence claims is limited to the extent of the <br />
                        coverage we maintain. </li>
                      <li>
                        Any advice given to you or work undertaken for you by a director, employee or consultant of the <br />
                        Company will be given or undertaken by that person on behalf of the Company and not in his or her <br />
                        individual capacity. Our directors, employees and consultants do not owe a personal duty of care <br />
                        nor assume any personal responsibility for their advice or other work.
                      </li>
                    </ul>
                    <p style={{ marginTop: 36 }}> <b> 6. Banking and Interest and Cash </b></p>
                    <ul>
                      <li>
                        We hold all client money in our client account with a UK clearing bank which is authorised by the <br />
                        Prudential Regulation Authority and regulated by the Financial Conduct Authority and the <br />
                        Prudential Regulation Authority. We will not be responsible for any loss due to any mistake by or <br />
                        failure of the clearing bank.
                      </li>
                      <li>
                        When we hold money for you in our client account we will pay interest to you of a fair and <br />
                        reasonable amount when it is fair and reasonable to do so. However, we do not pay interest if the <br />
                        amount calculated is £50 or less. We will calculate interest at the end of the matter and account to <br />
                        you promptly if any is due. The rate of interest is the base rate of Barclays Bank PLC from time to <br />
                        time.
                      </li>
                    </ul>
                    <ul style={{ marginLeft: 16 }}>
                      <li>
                        Our policy is not to accept cash. If clients circumvent this policy by depositing cash directly with our
                        bank we reserve the right to charge for any additional checks we consider necessary regarding the
                        source of the funds.
                      </li>
                    </ul>
                    <p style={{ marginTop: 50, marginBottom: 12 }}>  <b> 7. Fees</b></p>
                    <ul>
                      <li> We will notify you of any fees that are payable. </li>
                      <li>
                        If redress has been paid to you directly by the Lender, or by HMRC in the event of a tax recovery,
                        payment of our fees is due immediately. If the payment is outstanding for more than 21 days an
                        additional £50 charge may be added to cover administration and collection costs.
                      </li>
                      <li>
                        If redress is paid to us by the Lender, or by HMRC in the event of a tax recovery, you agree that we
                        can deduct our fee before forwarding the balance to you.
                      </li>
                      <li>
                        We may agree a payment plan with you for up to 3 months to clear an outstanding balance
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-7 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <p className="ps-4">  will charge simple interest at 12% per annum</p>
                    <li>
                      Should you owe us any fees you agree that we can deduct this outstanding debt from any redress
                      offered or awarded by a Lender, in respect of another successful complaint or claim, or by HMRC in
                      relation to a tax recovery.
                    </li>
                    <li>
                      Payment of our fees is due if redress is offered or awarded following any direct communications
                      you may have had with the Lender, subsequent to the submission of a complaint or claim by us to
                      them.
                    </li>
                    <li>
                      Payment of our fees is also due if you enter into a contract with another entity and redress is
                      offered or awarded following submission of a complaint or claim by us to the Lender.
                    </li>
                    <li>
                      Should the Lender increase an offer at a later date, perhaps because of a review of the complaint or
                      claim or as a result of an audit, we will charge fees on the additional amount on the same basis and
                      at the same percentage of the redress as previously agreed.
                    </li>
                    <li>
                      Should the Lender uphold other complaints or claims as a result of our approach to them we will deal
                      with these cases on your behalf and charge fees on the same basis and at the same percentage of
                      the redress as previously agreed.
                    </li>
                    <li>
                      Should you have any outstanding debts at the time we notify you of fees due to us you agree that
                      we will have first charge.
                    </li>
                    <li>
                      You have the right to complain about our invoice directly to us (see Complaints Clause 18) and also
                      by applying to the court to assess the invoice under Part 111 of the Solicitors Act 1974. The usual
                      time limit for making such an application is one month from the date of delivery of the invoice . If
                      the application is made after one month but before 12 months from the date of delivery of the
                      invoice, the court’s permission is required for the invoice to be assessed. <br />
                      Unless there are special circumstances, the court will not order an invoice to be assessed after:
                      <ul style={{ marginTop: 12 }}>
                        <li style={{ margin: 0 }}> 12 months from delivery of the invoice</li>
                        <li style={{ margin: 0 }}>A judgment has been obtained for the recovery of the costs covered by the invoice</li>
                        <li> The invoice has been paid, even if this is within 12 months</li>
                      </ul>
                    </li>
                    <p> <b> 8. Fee sharing </b></p>
                    <ul>
                      <li>
                        If you have been introduced to us by a third party we may share our fees with them. They will
                        receive up to 50% of our fees. This has no bearing on the way we handle our complaint or claim for
                        you and our duty to act in your best interests will not be compromised. The amount of redress you
                        receive will not be reduced by any fee sharing agreement
                      </li>
                    </ul>
                    <p> <b>9. Your Personal Data and Electronic communication</b> </p>
                    <ul>
                      <li>
                        We take the privacy of your personal data seriously. It is controlled and processed in accordance <br />
                        with the UK General Data Protection Regulation (‘UK GDPR’). You give us full authority to disclose <br />
                        and receive information as outlined herein and in our Privacy Policy which can be viewed at <br />
                        <span style={{ textDecoration: 'underline' }}><a href="https://www.pba-claims.co.uk" target="_blank">www.pba-claims.co.uk</a></span>. We ask that you read our Privacy Policy carefully. If required, a hard copy is <br />
                        available upon request. Nick Stocks is the data controller for TMS Legal Ltd.  <br />  <br />
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-8 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <p className="ps-2">
                      We use the personal data primarily for the provision of legal services to you in relation to your <br />
                      claim and for related purposes including:
                    </p>
                    <ul style={{ marginTop: 24 }}>
                      <li> Contacting you in relation to your complaint or claim and responding to your enquiries</li>
                      <li>Processing your complaint or claim</li>
                      <li>Contacting and responding to third parties on your behalf in relation to the complaint or
                        claim, for example, Lenders, the Financial Ombudsman Service and HMRC.</li>
                      <li> Updating and enhancing client records</li>
                      <li>Administration and billing </li>
                      <li>Management of our business </li>
                      <li>Statutory returns</li>
                      <li>Legal and regulatory compliance including auditing and investigation(s) </li>
                      <li>Debt collecting </li>
                      <li>Prevention of fraud, money laundering and terrorism</li>
                      <li>Verifying your identity and contact details </li>
                    </ul>
                    <li>Please note that telephone calls are recorded for quality, training and compliance purposes.</li>
                    <li>
                      You agree to accept the risks of using electronic communication, including but not limited to the
                      risks of viruses, interception, unauthorised access, delay, misrouting and break down of service
                      providers
                    </li>
                    <p><b>10. Storage </b></p>
                    <ul>
                      <li>
                        We hold personal data for as long as is necessary to fulfil the purpose for which we collected such
                        information (or for any compatible purpose), including for the purposes of satisfying any legal, accounting or
                        regulatory requirement, and limitation periods for taking legal action.
                      </li>
                      <li>
                        Any original documents we obtain from you will be scanned and returned to you within 14 days of
                        receipt
                      </li>
                    </ul>
                    <p> <b> 11. Prevention of Money Laundering</b></p>
                    <ul>
                      <li>
                        We are professionally and legally obliged to keep your affairs confidential. However, solicitors may
                        be obliged by statute to make a disclosure to the National Crime Agency and other relevant
                        authorities where they know or suspect that a transaction may involve money laundering or
                        terrorist financing. If we make a disclosure in relation to your matter, we may not be able to tell
                        you that a disclosure has been made or the reason for it. We may have to stop working on your
                        matter and may not be able to tell you why.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-9 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <ul>
                      <li>
                        We also have to obtain satisfactory verification of the identity of our clients and undertake client
                        due diligence. This may be necessary even if we have acted for you before or you are personally
                        known to us. In the absence of such verification at the start of our relationship we are unable to act
                        for you. We will conduct an electronic verification of your identity and proof of address by
                        searching various data sets. We may additionally ask you to provide documents as proof of your
                        identity and address.
                      </li>
                    </ul>
                    <p> <b> 12. Confidentiality</b></p>
                    <ul>
                      <li>
                        Any information not in the public domain or known to us, that we receive from you whilst acting for
                        you in connection with any matter, is confidential. However, in some circumstances we may be
                        required to disclose confidential information and documents to the court or to regulatory or fiscal
                        authorities. Should we be so required we will, to the extent we are permitted, endeavour to give you
                        advance notice of the disclosures we are obliged to make.
                      </li>
                      <li>
                        Please note that by accepting our terms and conditions you are consenting to the inspection of your
                        file should it be selected for audit or regulatory compliance checks.
                      </li>
                    </ul>
                    <p> <b> 13. Equality and Diversity </b></p>
                    <ul>
                      <li>
                        We are committed to the principles of equality and diversity and to observing all legislative
                        requirement regarding these principles.
                      </li>
                      <li>
                        The company is committed to a policy of non-discrimination, non-victimisation and nonharassment in all its professional dealings with employees, directors, clients and third parties and
                        will not discriminate, victimise or harass any person in these groups on the grounds of : race or
                        racial group ( including colour, nationality and ethnic or national origins), sex (including marital
                        status, civil partnership, gender reassignment, pregnancy, maternity and paternity), religion or
                        beliefs, age or disability.
                      </li>
                    </ul>
                    <p><b>14. Vulnerability </b></p>
                    <ul>
                      <li>
                        We are committed to the early identification and protection of vulnerable clients. A copy of our
                        vulnerability policy is available on request.
                      </li>
                    </ul>
                    <p> <b>15. Conflict of Interest</b></p>
                    <ul>
                      <li>
                        We will check for conflicts of interest before taking on work and whilst we are retained by you.
                        Where a conflict arises between your interests and the interests of another client of the firm during
                        the course of a matter, we will discuss the position with you and determine the appropriate course
                        of action. In order to protect your interests, we may not be permitted to continue to act for you
                      </li>
                    </ul>
                    <p><b>16. Authorisation to commence work</b></p>
                    <ul>
                      <li>
                        You expressly request and authorise us to begin working on your complaint or claim before the
                        end of the statutory cancellation period referred to in clause 17 below. You understand that by
                        giving this authorisation you cease having the right to cancel the contract i f our service has
                        been fully performed.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-10 */}
          <section className="container-1 my-5 ">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <p><b>17. Cancellation and Termination</b></p>
                    <ul>
                      <li>
                        You have the statutory right to cancel this contract within 14 days of agreeing to it. You can request<br />
                        cancellation by email at <span style={{ textDecoration: 'underline' }}><a href="mailto:info@moneysolicitor.com" target="_blank">info@moneysolicitor.com</a></span> or by writing to us at TMS Legal Ltd, Arena, 9 Nimrod Way, Ferndown, BH21 7UH or by using the enclosed cancellation form. Use of the cancellation
                        form is not obligatory.
                      </li>
                      <li>
                        You may also cancel this contract after the statutory cancellation period but our fees are still
                        payable where there has been redress - whether prior to, or subsequent to, the notice of
                        cancellation.
                      </li>
                      <li>
                        We may decide to stop acting for you, but only with good reason: for example, if you do not pay an
                        invoice, if you provide us with misleading information, if you act in an offensive or abusive manner,
                        or if we conclude that we have no substantive grounds on which to pursue or continue a complaint
                        or claim or that such complaint or claim is unlikely to be successful.
                      </li>
                    </ul>
                    <p> <b>18. Complaint</b></p>
                    <ul>
                      <li>
                        We endeavour to provide a good quality service with efficiency and courtesy. It is our aim that our
                        clients should be satisfied with all aspects of the work undertaken by us.
                      </li>
                      <li>
                        However, if you are unhappy with any aspect of our service, or the amount of our invoice, please <br />
                        contact the operations manager by writing to TMS Legal Ltd, Arena, 9 Nimrod Way, Ferndown, BH21 <br />
                        7UH or by telephone 01202 022350 or by email to <span style={{ textDecoration: 'underline' }}><a href="mailto:info@moneysolicitor.com" target="_blank">info@moneysolicitor.com</a></span>. A copy of our <br />
                        complaints handling procedure is available on request
                      </li>
                      <li>
                        We undertake to investigate any complaint carefully and promptly and will provide our final written response
                        to you within 8 weeks. If we have not resolved it within this time you may complain to the Legal
                        Ombudsman.
                        <br /> <br />
                        The Legal Ombudsman’s contact details are:
                      </li>
                    </ul>
                    <ul style={{ listStyleType: 'none' }}>
                      <li>
                        <ul>
                          <li style={{ margin: 0 }}> By post: PO Box 6167, Slough, SL1 0EH </li>
                          <li style={{ margin: 0 }}> By email: <span style={{ textDecoration: 'underline' }}><a href="mailto:enquiries@legalombudsman.org.uk"> enquiries@legalombudsman.org.uk</a></span></li>
                          <li style={{ margin: 0 }}>By phone: 0300 555 0333 </li>
                        </ul>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        You need to bring a complaint to the Legal Ombudsman within 6 months of receiving a final written response
                        from us about your complaint
                      </li>
                      <li>
                        The Legal Ombudsman expects complaints to be made within one year of the act or omission about which
                        you are concerned, or within one year of you realising that there was a concern.
                      </li>
                      <li>
                        Alternative complaints bodies exist which are competent to deal with complaints about legal services
                        should both you and the company wish to use such a scheme. A list of those that we recommend is
                        available on request.
                      </li>
                      <li>
                        The Legal Ombudsman is there to deal with concerns about the level of service received. If your
                        concern is related to behaviour, for example dishonesty, or that you have been treated unfairly
                        because of your age, disability, or other characteristic, you can raise your concerns with the
                        Solicitors Regulation Authority. Full details on how to do this can be found on their website at
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-11 */}
          <section className="container-1 my-5 ">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <a style={{ textDecoration: 'none', color: 'black' }} href="https://www.sra.org.uk" target="_blank"> www.sra.org.uk </a>
                    <p className="mt-3"><b>19. Applicable Law </b></p>
                    <ul>
                      <li>
                        The contract and any dispute between us shall be governed by and construed in accordance with
                        the laws of England and Wales and shall be subject to the exclusive jurisdiction of the English
                        courts.
                      </li>
                    </ul>
                    <p style={{ marginTop: 120 }}> <b>
                      I /We agree to and accept the terms and conditions set out in the Terms of Business above and in the Letter of
                      Engagement, Letter of Authority, Power of Attorney and UK GDPR notification
                    </b></p>
                    <div ref={divRefThree} style={{ marginTop: 50 }}>
                      <div className="d-flex flex-column flex-lg-row justify-content-evenly">
                        <div style={{ borderRadius: 35, border: '1px solid #065197', padding: '18px 15px 2px 15px', width: 312, marginBottom: 12 }}>
                          <h3 style={{ textAlign: 'center', fontSize: 18, fontWeight: 900 }}>Client 1</h3>
                          <p style={{ fontSize: 13, paddingTop: 3 }}>
                            Print Full Name: : {userName}
                          </p>
                          <div className="anim_ylw_preview" style={{ border: '1px solid black', padding: '15px 5px 10px 5px' }}>
                            <table style={{ fontSize: 14, height: 50 }}>
                              <tbody>
                                <tr>
                                  <td>
                                    Signature:
                                  </td>
                                  <td>
                                    <img style={{ width: '85%' }} src={userSign} alt="" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p style={{ textAlign: 'right', fontSize: 12, paddingTop: 5 }}>
                            (Signature required in above Box)
                          </p>
                          <p style={{ fontSize: 12 }}>
                            Dated: {currentDate}
                          </p>
                        </div>
                        <div style={{ borderRadius: 35, border: '1px solid #065197', padding: '18px 15px 2px 15px', width: 312, marginBottom: 12 }}>
                          <h3 style={{ textAlign: 'center', fontSize: 18, fontWeight: 900 }}>Client 2</h3>
                          <p style={{ fontSize: 13, paddingTop: 3 }}>
                            Print Full Name: : ____________________
                          </p>
                          <div style={{ border: '1px solid black', padding: '15px 5px 10px 5px' }}>
                            <table style={{ fontSize: 14, height: 50 }}>
                              <tbody>
                                <tr>
                                  <td>
                                    Signature:
                                  </td>
                                  <td>
                                    {/* <img src=" {user_signature_image}" alt=""> */}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p style={{ textAlign: 'right', fontSize: 12, paddingTop: 5 }}>
                            (Signature required in above Box)
                          </p>
                          <p style={{ fontSize: 12 }}>
                            Dated:____________________
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page_12 */}
          <section className="container-1 my-5 ">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <h5 className="text-center mt-5 pt-5">NOTICE OF RIGHT TO CANCEL</h5>
                    <p style={{ color: '#666666' }}>
                      You have the right to cancel this contract within 14 days of agreeing to it. You can request cancellation by email at
                      <span style={{ color: '#0461c1', textDecoration: 'underline' }}><a href="mailto:info@moneysolicitor.com" target="_blank"> info@moneysolicitor.com</a></span> or by writing to us at TMS Legal Ltd, Arena, 9 Nimrod Way, Ferndown BH21 7UH or by
                      using the cancellation form.
                    </p>
                    <p className="d-none d-lg-block" style={{ textAlign: 'center' }}>
                      ...................................................................................................................................................................................</p>
                    <p className="d-block d-lg-none" style={{ textAlign: 'center' }}>
                      ...........................................................................................</p>
                    <h5 className="text-center">CANCELLATION FORM</h5>
                    <p>To: TMS Legal Ltd</p>
                    <p>I / We* hereby give notice that I / We* cancel my / our* contract for the supply of services by TMS Legal Ltd.</p>
                    <p style={{ marginTop: 36 }}>Name:</p>
                    <p>Address:</p>
                    <p>Signature:</p>
                    <p>Date:</p>
                    <p style={{ marginTop: 28 }}>* Delete as appropriate.</p>
                    <p style={{ marginTop: 24, marginBottom: 0 }}>You can return this form</p>
                    <p style={{ marginTop: 0 }}>by:</p>
                    <ul>
                      <li style={{ margin: 0 }}>Post to: TMS Legal Ltd, Arena, 9 Nimrod Way, Ferndown, BH21 7UH</li>
                      <li style={{ margin: 0 }}>E mail: <a href="mailto:info@moneysolicitor.com" target="_blank">info@moneysolicitor.com</a></li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-12 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <h5 className="text-center">Complaints Procedure</h5>
                    <ul>
                      <li>We endeavour to provide a good quality service with efficiency and courtesy. It is
                        our aim that our clients should be satisfied with all aspects of the work undertaken
                        by us.</li>
                      <li>
                        We aim to resolve complaints as quickly and efficiently as possible while at the same
                        time gaining a complete understanding of the client’s complaint points and
                        addressing each one.
                      </li>
                      <li>
                        A complaint can be made verbally or in writing. For example it can be by letter,
                        telephone, e-mail or by any other contact medium we have available.
                      </li>
                      <li>
                        Our contact details are:
                        <ul style={{ marginTop: 16 }}>
                          <li style={{ margin: 0 }}>By Post: TMS Legal Ltd, Arena, 9 Nimrod Way, Ferndown, BH21 7UH</li>
                          <li style={{ margin: 0 }}>email: <a href="mailto:info@moneysolicitor.com" target="_blank">info@moneysolicitor.com</a></li>
                          <li style={{ margin: 0 }}>By phone: 0800 0073 777</li>
                        </ul>
                      </li>
                      <li>
                        Once a complaint has been received a letter or electronic acknowledgement of the
                        complaint will be sent within 5 business days by Simona Zaharia, the Operations
                        Manager, who is responsible for investigating your complaint
                      </li>
                      <li>
                        Within 4 weeks of the complaint being received we will send either a final response
                        letter addressing all your complaint points, or a holding response advising you as to
                        why the complaint has not been resolved.
                      </li>
                      <li>
                        If a holding response is sent an indication will be given as to when our final response
                        letter will be sent to you, but it will be within 8 weeks from the date of the receipt of
                        the original complaint.
                      </li>
                      <li>
                        Within 8 weeks from the date of receipt of your complaint we will send you a final
                        response letter addressing all your complaint points.
                      </li>
                      <li>
                        If we have not resolved the complaint to your satisfaction you have the right to
                        complain to the Legal Ombudsman, an independent complaints body established
                        under the Legal Services Act 2007.
                      </li>
                      <li>
                        The Legal Ombudsman expects complaints to be made within one year of the act or
                        omission about which you are concerned, or within one year of you realising that
                        there was a concern.
                      </li>
                      <li>
                        You must also refer your your concerns to the Legal Ombudsman within 6 months
                        of the date of our final response letter to you.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-12 */}
          <section className="container-1 my-5">
            <table>
              <tbody>
                <tr>
                  <td className="first_td">
                    <ul>
                      <li>The Legal Ombudsman can be contacted:
                        <ul>
                          <li>By post: P.O Box 6167, Slough, SL1 0EH</li>
                          <li> By email: <a href="mailto:enquiries@legalombudsman.org.uk"> enquiries@legalombudsman.org.uk</a> </li>
                          <li> By phone: 0300 555 0333</li>
                        </ul>
                      </li>
                      <li>
                        Alternative complaint bodies exist that are competent to deal with complaintsabout legal services should both you and TMS Legal wish to use such a
                        scheme. A list of those that the company recommends are available on request.
                      </li>
                      <li>
                        Please note that the Legal Ombudsman is there to deal with concerns about
                        the level of service received. If your concern is related to behaviour, for
                        example dishonesty, or that you have been treated unfairly because of your
                        age, disability, or other characteristic, you can raise your concerns withtheSolicitors Regulation Authority. Full details on how to do this can be found on their website:<a href="https://www.sra.org.uk" target="_blank">www.sra.org.uk</a>

                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-1 */}
          <section className="container-1 my-5 declare-sec">
            <table>
              <tbody><tr>
                <td>
                  <table className="logo-tab">
                    <tbody><tr>
                      <td style={{ textAlign: 'right' }}>
                        <img src={Ombudsman} />
                      </td>
                    </tr>
                    </tbody></table>
                  {/* Header */}
                  <div style={{ clear: 'both' }} />
                  <h2 className="text_one color_one" style={{ marginBottom: 8, fontSize: 20 }}> Professional representatives – customer declaration form</h2>
                  <div className="f-wb" style={{ marginBottom: 8, fontSize: 15 }}>To be completed by your customer:</div>
                  <table className="border" style={{ width: '100%' }} ref={divRefFour}>
                    <tbody>
                      <tr>
                        <td className="bg_clr011 f-wb color_one" style={{ width: '30%', verticalAlign: 'middle', paddingTop: 8, paddingBottom: 8 }}> Name</td>
                        <td style={{ width: '70%', verticalAlign: 'middle', paddingLeft: 5 }}>{salutation} {fName} {lName}</td>
                      </tr>
                      <tr>
                        <td className="bg_clr011 f-wb color_one" style={{ width: '30%', verticalAlign: 'middle', paddingTop: 8, paddingBottom: 8 }}>  Name (joint complainant)</td>
                        <td style={{ width: '70%', verticalAlign: 'middle', paddingLeft: 5 }}>
                        </td>
                      </tr>
                      <tr>
                        <td className="bg_clr011 f-wb color_one" style={{ width: '30%', verticalAlign: 'middle', paddingTop: 8, paddingBottom: 8 }}>  The business you're<br />
                          complaining about</td>
                        <td style={{ width: '70%', verticalAlign: 'middle', paddingLeft: 5 }}>
                          {/* Vanquis */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p style={{ fontSize: 15, paddingTop: 30 }}>
                    Before the Financial Ombudsman Service can look into your complaint, you need to agree to this declaration. By signing below, you are agreeing to it.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 20, marginBottom: 10 }}>
                    <i>
                      "I would like the Financial Ombudsman Service to look into my complaint. I confirm to the best of my knowledge everything I have told you is correct."
                    </i>
                  </p>
                  <table className="border" style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td className="bg_clr011 f-wb color_one" style={{ width: '40%', verticalAlign: 'middle', paddingTop: 8, paddingBottom: 8 }}> Signature</td>
                        <td className="anim_ylw_preview" style={{ width: '60%', verticalAlign: 'middle', paddingLeft: 5 }}>
                          <img style={{ height: "40px" }} src={userSign} />
                        </td>
                      </tr>
                      <tr>
                        <td className="bg_clr011 f-wb color_one" style={{ width: '40%', verticalAlign: 'middle', paddingTop: 8, paddingBottom: 8 }}>  Today's date</td>
                        <td style={{ width: '60%', verticalAlign: 'middle', paddingLeft: 5 }}>
                          {currentDate}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="border" style={{ width: '100%', marginTop: 30 }}>
                    <tbody>
                      <tr>
                        <td className="bg_clr011 f-wb color_one" style={{ width: '40%', verticalAlign: 'middle', paddingTop: 8, paddingBottom: 8 }}> Signature</td>
                        <td style={{ width: '60%', verticalAlign: 'middle', paddingLeft: 5, height: 40 }}>
                        </td>
                      </tr>
                      <tr>
                        <td className="bg_clr011 f-wb color_one" style={{ width: '40%', verticalAlign: 'middle', paddingTop: 8, paddingBottom: 8 }}>  Today's date</td>
                        <td style={{ width: '60%', verticalAlign: 'middle', paddingLeft: 5 }}>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p style={{ paddingTop: 30, paddingBottom: 15, fontSize: 15 }}>If you're complaining on behalf of a business, charity or trust, please provide your job title:</p>
                  <table className="border" style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td className="bg_clr011 f-wb color_one" style={{ width: '40%', verticalAlign: 'middle', paddingTop: 8, paddingBottom: 8 }}> Job title</td>
                        <td style={{ width: '60%', verticalAlign: 'middle', paddingLeft: 5 }}>
                        </td>
                      </tr>
                      <tr>
                        <td className="bg_clr011 f-wb color_one" style={{ width: '40%', verticalAlign: 'middle', paddingTop: 8, paddingBottom: 8 }}>  Job title (joint complainant)</td>
                        <td style={{ width: '60%', verticalAlign: 'middle', paddingLeft: 5 }}>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p style={{ fontSize: 15, paddingTop: 30 }}>
                    For complaints involving accounts or policies held jointly, we usually need each person to provide their names above – and we may share details about the complaint with both complainants. Please tell your representative to let us know if there's any reason this might be a problem for you.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 15 }}>
                    If more than two people are bringing the complaint, please complete additional declaration forms as needed.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 15 }}>
                    You can find a copy of our privacy notice at:
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 15 }}>
                    <a style={{ color: '#23acf7', textDecoration: 'none' }} target="_blank" href="https://www.financial-ombudsman.org.uk/privacy-policy/consumer-privacy-notice">https://www.financial-ombudsman.org.uk/privacy-policy/consumer-privacy-notice</a>
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 15 }}>
                    Additionally, for customers unable to access a copy of our privacy notice online, please find it attached below
                  </p>
                </td>
              </tr>
              </tbody></table>
          </section>
          {/* page-2 */}
          <section className="container-1 my-5 declare-sec">
            <table>
              <tbody><tr>
                <td>
                  <table className="logo-tab">
                    <tbody><tr>
                      <td style={{ textAlign: 'right' }}>
                        <img src={Ombudsman} />
                      </td>
                    </tr>
                    </tbody></table>
                  {/* Header */}
                  <div style={{ clear: 'both' }} />
                  <h4 className="color_one" style={{ marginBottom: 8, fontSize: 18 }}> Complainant privacy notice</h4>
                  <p style={{ fontSize: 15 }}>
                    This privacy notice summarises what to expect us to do with your personal information when you contact us or bring a complaint to our service. A more detailed version of this privacy notice is available on our website.
                  </p>
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>About us</h4>
                  <p style={{ fontSize: 15 }}>
                    The Financial Ombudsman Service is a free and easy-to-use service that settles complaints between complainants and businesses that provide financial services. You can contact us <a href="https://www.financial-ombudsman.org.uk/contact-us" target="_blank">here</a>.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    <b>
                      We use your personal information to investigate and resolve individual complaints and prevent unfairness. We also analyse complaints data to make our services and processes more effective for you and others. More detail is set out in the ‘Why we process your personal information’ section.
                    </b>
                  </p>
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>The personal information we use</h4>
                  <p style={{ fontSize: 15 }}>
                    Personal information means information that is about an individual or can identify them in some way. The amount and type of personal information we process varies depending on the individual circumstances of the complaint and why we are processing personal information. Examples of the type of information we process are your name, date of birth, financial details, phone recordings and special category data, such as health information.
                  </p>
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>How we collect personal data</h4>
                  <p style={{ fontSize: 15 }}>
                    We have a range of channels that individuals can use to get in contact with us. Generally, we receive personal information from the individual directly, their representative or from the financial business the complaint is against. But sometimes, where it is necessary for resolving a complaint or fulfilling our legal functions, we may also gather information from other individuals or organisations, such as a loss adjuster hired by an insurance company, a medical expert or a credit reference agency.
                  </p>
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>Why we process personal information</h4>
                  <p style={{ fontSize: 15 }}>
                    We primarily collect and process personal information to investigate and resolve individual complaints brought to our service and respond to enquiries and redirect individuals to the correct organisation if necessary. In addition to this we also process personal data in the following way:
                  </p>
                  <ul>
                    <li style={{ fontSize: 15, paddingTop: 10 }}>Prevent complaints and unfairness</li>
                    <li style={{ fontSize: 15, paddingTop: 5 }}>Improve the effectiveness of our service and processes</li>
                    <li style={{ fontSize: 15, paddingTop: 5 }}>Meeting your needs and making adjustments</li>
                    <li style={{ fontSize: 15, paddingTop: 5 }}>Work with the regulator and other bodies</li>
                    <li style={{ fontSize: 15, paddingTop: 5 }}>Dealing with contact you may make with us through social media</li>
                    <li style={{ fontSize: 15, paddingTop: 5 }}>Complying with a legal duty.</li>
                  </ul>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    We have a legal obligation to publish final decisions made by our ombudsmen. These are published on our website. We remove the name of the person making the complaint as well as any other personal information that would be likely to identify them.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    We conduct regular surveys to understand your views on the service we have provided to you. You can let your Investigator know at any time if you do not want us to contact you for our surveys.
                  </p>
                </td>
              </tr>
              </tbody></table>
          </section>
          {/* page-3 */}
          <section className="container-1 my-5 declare-sec">
            <table>
              <tbody><tr>
                <td>
                  <table className="logo-tab">
                    <tbody><tr>
                      <td style={{ textAlign: 'right' }}>
                        <img src={Ombudsman} />
                      </td>
                    </tr>
                    </tbody></table>
                  {/* Header */}
                  <div style={{ clear: 'both' }} />
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>Who we share personal information with?</h4>
                  <p style={{ fontSize: 15 }}>
                    We will not share your information with anyone for the purpose of direct marketing. We will not sell your data.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    When an enquiry is brought to us, we need to contact the financial business and make them aware that an enquiry has been received and ask them what has happened so far. The personal details of the complainant, any representative and details of the complaint are shared during this initial process.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    In order to investigate a complaint, we need to share information with both parties of the complaint to get both sides of the story. Sometimes, depending on the nature of the complaint, we may also need to share relevant information with other individuals or organisations, for example: another financial business, medical experts or credit reference agencies.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    If you have a complaint about the standard of service we’ve provided to you, and we’ve not been able to resolve this, you can ask the Independent Assessor to investigate this complaint. We will pass on relevant details to the Independent Assessor so that they can investigate and respond to your service complaint.
                  </p>
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>Lawful bases for processing personal information</h4>
                  <p style={{ fontSize: 15 }}>
                    The law on data protection sets out a number of different reasons for which an organisation may collect and process your personal information.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    Our lawful basis for processing personal information will usually be because it’s necessary for our statutory function, a reason of substantial public interest or compliance with our legal obligations. On those occasions where we are not relying on any of the above, we will ensure that a suitable alternative lawful basis is used, which is likely to be where the processing is in our legitimate interests.
                  </p>
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>Where your data is processed and stored</h4>
                  <p style={{ fontSize: 15 }}>
                    We store your personal information in the UK or the European Union (EU). Our technical support teams in India may process your information to provide technical advice and assistance.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    Where we allow access to your information from countries outside the UK, we ensure that we comply with all our data protection obligations.
                  </p>
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>How we store personal information and for how long</h4>
                  <p style={{ fontSize: 15 }}>
                    We know that data security is important to us all. When we receive personal information, we take steps to ensure that it is stored securely, both physically and electronically, in accordance with the internal policies that we have in place for the effective and secure processing of your personal information. We will keep your case file for 6 years after your case closes (or 3 years if we did not go on to fully investigate your case or if we transferred your case to another organisation). Paper documents sent by post are destroyed 6 months after the date they are scanned into our system. More information is in Annex A <a href="https://www.financial-ombudsman.org.uk/privacy-policy/consumer-privacy-notice" target="_blank">here</a>.
                  </p>
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>What are your rights over your personal information?</h4>
                  <p style={{ fontSize: 15 }}>
                    Under data protection law, you have rights we need to make you aware of. The rights available to you depend on our reason for processing your information. Details of each of these rights are set out in Annex B <a href="https://www.financial-ombudsman.org.uk/privacy-policy/consumer-privacy-notice" target="_blank">here</a>.
                  </p>
                </td>
              </tr>
              </tbody></table>
          </section>
          {/* page-4 */}
          <section className="container-1 my-5 declare-sec">
            <table>
              <tbody><tr>
                <td>
                  <table className="logo-tab">
                    <tbody><tr>
                      <td style={{ textAlign: 'right' }}>
                        <img src={Ombudsman} />
                      </td>
                    </tr>
                    </tbody></table>
                  {/* Header */}
                  <div style={{ clear: 'both' }} />
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>What to do if you’re unhappy with how we’ve handled your personal information</h4>
                  <p style={{ fontSize: 15 }}>
                    If you have any questions about this notice or are concerned about how we are processing your personal data, you can contact our Data Protection Officer at:
                  </p>
                  <p>
                    <a href="mailto:data.protection@financial-ombudsman.org.uk" target="_blank">data.protection@financial-ombudsman.org.uk</a>. Details of how to raise a complaint are
                    available here.
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    We hope we’ll be able to resolve your concerns, but if you still remain unhappy with our response, you can contact the Information Commissioner’s Office at <a href="mailto:casework@ico.org.uk" target="_blank">casework@ico.org.uk</a> or 01625 545 745.
                  </p>
                  <h4 style={{ fontSize: 17, margin: '10px 0' }}>Changes to this privacy notice</h4>
                  <p style={{ fontSize: 15 }}>
                    We may change this privacy policy. In that case, the ‘last updated’ date at the bottom of this page will also change. Any changes to this privacy policy will apply to you and your data immediately
                  </p>
                  <p style={{ fontSize: 15, paddingTop: 10 }}>
                    Last updated February 2022
                  </p>
                </td>
              </tr>
              </tbody></table>
          </section>
          {/* page-5 */}
          <section className="container-1 my-5 declare-sec">
            <table>
              <tbody><tr>
                <td>
                  <table className="logo-tab">
                    <tbody><tr>
                      <td style={{ textAlign: 'center' }}>
                        <img style={{ width: 200, opacity: '.8' }} src={Logo} />
                      </td>
                    </tr>
                    </tbody></table>
                  {/* Header */}
                  <div style={{ clear: 'both' }} />
                  <h4 style={{ fontSize: 24, margin: '10px 0', textAlign: 'center', color: '#2680d5', fontWeight: 400 }}>Overdraft Questionnaire</h4>

                  <p>
                    <b>
                      What was your overdraft opening credit limit?
                    </b>
                  </p>
                  <p>£ {odopeningCredit}</p>
                  <br />

                    <>
                      <p>
                        <b>
                          Was your credit limit ever increased?
                        </b>
                      </p>
                      <p>{recentCredit == "247" ? "YES" : "NO"}</p>
                      <br />
                    </>

                  {recentCredit == "247" && (
                    <>
                      <p>
                        <b>The bank acted irresponsibly by increasing my overdraft limit without conducting a proper affordability assessment - do you agree?
                        </b>
                      </p>
                      <p>{irresponsibleAct == "249" ? "Agree" : "Disagree"}</p>
                      <br />
                      <p>
                        <b>
                          What is the highest credit limit you had on your overdraft?
                        </b>
                      </p>
                      <p>£ {highestCreditLimit}</p>
                      <br />
                    </>
                  )}

                  <p>
                    <b>
                      Did you ever exceed your credit limit?
                    </b>
                  </p>
                  <p>{exceedCreditLimit == "251" ? "YES" : "NO"}</p>
                  <br />

                  {exceedCreditLimit == "251" && (
                    <>
                  <p>
                    <b>
                      How often did you exceed your credit limit?
                    </b>
                  </p>
                  <p>{timesExceedLimit}</p>
                  <br />
                  
                  <p>
                    <b>
                      Did you receive overlimit charges?
                    </b>
                  </p>
                  <p>{overlimitCharge}</p>
                  <br />
                  </>
                  )}

                  <p>
                    <b>
                      I have suffered financial hardship due to interest and charges being applied to my account - do you agree?
                    </b>
                  </p>
                  <p>{finHardship == "265" ? "Agree" : "Disagree"}</p>
                  <br />

                  {/* <p>
                    <b>
                      Think back to when you took the overdraft - please answer the questions in this section relating to that time:
                    </b>
                  </p>
                  <p>{thinkBack}</p>
                  <br /> */}

                  <>
                    <p>
                      <b>
                        In the year when you took the overdraft did you receive any income?
                      </b>
                    </p>
                    <p>{didReceiveIncome == "267" ? "YES" : "NO"}</p>
                    <br />

                    {(didReceiveIncome == "267" && cardMonthlyIncome != "") && (
                      <>
                        <p>
                          <b>
                            When you took out your overdraft, what was your monthly income?
                          </b>
                        </p>
                        <p>{cardMonthlyIncome}</p>
                        <br />
                      </>
                    )}

                    {(dependent && dependent != "") && (
                      <>
                        <p>
                          <b>How many dependents did you have at that time?</b>
                        </p>
                        <p>{dependent}</p>
                        <br />
                      </>
                    )}


                    {question79 && question79.length > 0 && (
                      <>
                        <p>
                          <b>When I took the overdraft I was already paying monthly for one or more of the following:</b>
                        </p>
                        <MonthlyIncomeList />
                      </>
                    )}
                    <br />

                    {slide80 && (
                      <>
                        <p>
                          <b> Please confirm the following MONTHLY / ANNUAL income and expenditure details</b>
                        </p>

                        {(userMortages || userRentss) && (
                          <p>
                            My monthly Mortgage / Rent was:
                            {userMortages || userRentss}
                          </p>
                        )}
                        {userTotalUtilityBillss && userTotalUtilityBillss != "" && (
                          <>
                            <p> My monthly TOTAL utility bills were: {userTotalUtilityBillss}</p>
                          </>
                        )}
                        {userMonthlyLoanRepaymentss && userMonthlyLoanRepaymentss != "" && (
                          <>
                            <p> My monthly loan repayments were:{userMonthlyLoanRepaymentss}</p>
                          </>
                        )}
                        {userCreditCardRepaymentss && userCreditCardRepaymentss != "" && (
                          <>
                            <p> My monthly credit card repayments were: {userCreditCardRepaymentss}</p>
                          </>
                        )}
                        {userMonthlyCarFinancess && userMonthlyCarFinancess != "" && (
                          <>
                            <p> My monthly car finance payments were: {userMonthlyCarFinancess}</p>
                          </>
                        )}
                        {userMonthlyStoreCardPaymentss && userMonthlyStoreCardPaymentss != "" && (
                          <>
                            <p> My monthly store card payments were: {userMonthlyStoreCardPaymentss}</p>
                          </>
                        )}
                        {userOtherMonthlyPaymentss && userOtherMonthlyPaymentss != "" && (
                          <>
                            <p> My other monthly payment commitments were: {userOtherMonthlyPaymentss}</p>
                          </>
                        )}
                      </>
                    )}
                    <br />

                  </>
                
                  {furtherBorrow && furtherBorrow.length > 0 && (
                    <>
                      <p>
                        <b>
                          Did you take out further borrowing so that you could manage your finances?
                        </b>
                      </p>
                      <FurtherBorrowList />
                      <br />
                    </>
                  )}

                  <p>
                    <b>
                      Have you struggled to meet your essential repayments as a result of being in your overdraft?
                    </b>
                  </p>
                  <p>{essentialRepayment == "286" ? "YES" : "NO"}</p>
                  <br />

                  <p>
                    <b>
                      I had not maintained a credit balance for a significant period of time which ought to have made the bank aware of my financial difficulties - do you agree?
                    </b>
                  </p>
                  <p>{maintainBalance == "288" ? "Agree" : "Disagree"}</p>
                  <br />

                  <p>
                    <b>
                      I was stuck in my overdraft and didn’t see a positive balance
                    </b>
                  </p>
                  <p>{stuckOverdraft}</p>
                  <br />
                  {stuckOverdraft != "Never" && (
                    <>
                      <p>
                        <b>
                          You’ve told us you were stuck in your overdraft, as a result you didn’t see a frequent positive balance – how did this affect you?
                        </b>
                      </p>
                      <p>{stuckOverdraftAffect}</p>
                      <br />
                    </>
                  )}
                  <br />
                </td>
              </tr>
              </tbody></table>
          </section>
          {/* page-6 */}
          <section className="container-1 my-5 table-sec" ref={divRefFive}>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <div className="d-flex flex-column flex-lg-row justify-content-evenly">
                      <div style={{ borderRadius: 35, border: '1px solid #065197', padding: '18px 15px 2px 15px', width: 312, marginBottom: 12 }}>
                        <h3 style={{ textAlign: 'center', fontSize: 18, fontWeight: 900 }}>Customer 1</h3>
                        <p style={{ fontSize: 13, paddingTop: 3 }}>
                          Full Name : {userName}
                        </p>
                        <div className="anim_ylw_preview" style={{ border: '1px solid black', padding: '15px 5px 10px 5px' }}>
                          <table style={{ fontSize: 14, height: 50 }}>
                            <tbody>
                              <tr>
                                <td>
                                  Signature:
                                </td>
                                <td>
                                  <img style={{ width: '85%' }} src={userSign} alt="" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <p style={{ fontSize: 12 }}>
                          Dated: {currentDate}
                        </p>
                      </div>
                      <div style={{ borderRadius: 35, border: '1px solid #065197', padding: '18px 15px 2px 15px', width: 312, marginBottom: 12 }}>
                        <h3 style={{ textAlign: 'center', fontSize: 18, fontWeight: 900 }}>Customer 2</h3>
                        <p style={{ fontSize: 13, paddingTop: 3 }}>
                          Full Name: ____________________
                        </p>
                        <div style={{ border: '1px solid black', padding: '15px 5px 10px 5px' }}>
                          <table style={{ fontSize: 14, height: 50 }}>
                            <tbody>
                              <tr>
                                <td>
                                  Signature:
                                </td>
                                <td>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <p style={{ fontSize: 12 }}>
                          Dated:
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* page-7 */}
          <section className="container-1 my-5 dsar-sec">
            <table>
              <tbody>
                <tr>
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ width: '62%' }}>
                            <img style={{ width: 200, opacity: '.8' }} src={Logo} />
                          </td>
                          <td style={{ width: '38%' }}>
                            <p style={{ color: '#535453' }}>
                              Arena, 9 Nimrod Way, Ferndown, BH21 7UH. <br />
                              Telephone: 01202 022350
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h1 style={{ fontSize: 30, marginTop: 15, fontWeight: 700 }}>
                      Letter of Authority for a DSAR <br />
                      (Data Subject Access Request)
                    </h1>
                    <p style={{ fontSize: 10, lineHeight: '22px' }}>
                      As provided for under Rule DISP2.4.16R of the FCA Handbook, which states that ‘ A complaint may
                      be brought on behalf of an eligible complainant, or a <br />
                      deceased person who would have been an eligible complainant, by a person authorised by the
                      eligible complainant or authorised by law.’
                    </p>
                    <table className="table_1" style={{ marginTop: 12, marginBottom: 10 }}>
                      <tbody>
                        <tr>
                          <th colSpan={2} style={{ background: '#e6e7e9', padding: 4 }}>
                            <b>To whom it may concern</b>
                          </th>
                        </tr>
                        <tr>
                          <td style={{ width: '20%', textAlign: 'left', paddingLeft: 5 }}><b>Lender</b></td>
                          <td style={{ width: '80%' }}><b /><p>{selectedLender!=='' ? selectedLender : ""}</p></td>
                        </tr>
                      </tbody>
                    </table>
                    <h4 style={{ fontSize: 17, margin: 0, textAlign: 'left' }}>
                      Please note this is not a request for all data held, but a requirement specifically for all my bank statements.
                    </h4>
                    <div style={{ paddingLeft: 20, marginTop: 20 }}>
                      <h5> <b> Authorisation to TMS Legal Ltd:</b> </h5>
                      <ol>
                        <li>
                          I/We have instructed TMS Legal Ltd and hereby authorise them to act on my/our behalf as solicitors in
                          obtaining my/our bank
                          statement from the above named company/financial institution.
                        </li>
                      </ol>
                      <h5> <b> Instructions to the Company</b> </h5>
                      <ol>
                        <li value={2}>
                          Please take this letter of authority as my/our instructions to you, to deal directly with TMS Legal Ltd
                          , as my/our solicitors, in respect
                          of obtaining my/our bank statements and to provide them with any information they request either
                          verbally or in any other format.
                          This authority extends to providing TMS Legal Ltd with all of my/our bank statements held by your
                          company.
                        </li>
                        <li>
                          If a copy of this Letter of Authority is provided, it will have the same validity as the original.
                        </li>
                      </ol>
                    </div>
                    <table className="table_1a" ref={divRefSix}>
                      <tbody>
                        <tr>
                          <td colSpan={3} style={{ background: '#e6e7e9', padding: 4, fontSize: 13 }}>
                            <p>
                              <b> Customer 1</b>
                            </p>
                          </td>
                          <td colSpan={3} style={{ background: '#e6e7e9', padding: 4, fontSize: 12 }}>
                            <p>
                              <b>Customer 2</b>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30, fontSize: 10, width: '12%' }}>
                            <p>Full Name</p>
                          </td>
                          <td colSpan={2} style={{ width: '38%' }}>
                            <p>{userName}</p>
                          </td>
                          <td style={{ width: '12%' }}>
                            <p>Full Name</p>
                          </td>
                          <td colSpan={2} style={{ width: '38%' }}> </td>
                        </tr>
                        <tr>
                          <td style={{ height: 60 }}>
                            Address
                          </td>
                          <td colSpan={2}>
                            {address}
                          </td>
                          <td>
                            <p>Address</p>
                          </td>
                          <td colSpan={2}> <br /> </td>
                        </tr>
                        <tr>
                          <td style={{ height: 30 }}>
                            <p>Date of Birth</p>
                          </td>
                          <td colSpan={2}>{userDob}</td>
                          <td>
                            <p>Date of Birth</p>
                          </td>
                          <td colSpan={2}> </td>
                        </tr>
                        <tr>
                          <td className="anim_ylw_preview" colSpan={2} style={{ verticalAlign: 'bottom', fontSize: 9, width: '34%', height: 70 }}>
                            <img style={{ height: "40px", maxwidth: "80%" }} src={userSign} />
                            <p style={{ paddingTop: 10 }}>SIGNATURE</p>
                          </td>
                          <td style={{ fontSize: 9, verticalAlign: 'bottom', width: '16%' }}>
                            <p>{currentDate}</p>
                            <p style={{ paddingTop: 10 }}>DATE</p>

                          </td>
                          <td style={{ fontSize: 9, verticalAlign: 'bottom', width: '34%' }} colSpan={2}>
                            <p style={{ paddingTop: 10 }}>SIGNATURE</p>
                          </td>
                          <td style={{ fontSize: 9, verticalAlign: 'bottom', width: '16%' }}>
                            <p style={{ paddingTop: 10 }}>DATE</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      <b> By signing this Letter of Authority:</b>
                    </p>
                    <p style={{ color: '#ee232e', fontSize: 12, marginTop: 10 }}>
                      <b>
                        • I/We agree to TMS Legal obtaining my/our bank statements and using them to pursue my/our Irresponsible lending complaint(s).
                      </b>
                    </p>

                    <div className="row mt-3 mb-5 mb-md-0">
                      <div className="col-lg-7">
                        <p style={{ lineHeight: '12px', margin: 0, marginBottom: 4 }}>
                          <span className='important-font-size'>
                            TMS Legal Ltd is authorised and regulated by the Solicitors Regulation Authority, Number: 645041.
                            <br />
                            TMS Legal Ltd is a limited company registered in England and Wales, 10315962. <br />
                            Registered Office address: Arena, 9 Nimrod Way, Ferndown, Dorset, BH21 7UH

                          </span>
                        </p>
                      </div>
                      <div className="col-lg-5">
                        <img style={{ width: 280 }} src={OverLeaf} />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          {/* footer-nxtpage */}
          <footer className="next-btn  bg-dark p-2">
            {showSubmitBtn && (
              <button
                className="anim_ylw_preview btn btn-success px-3"
                type="button"
                id="nextscroll"
                disabled={btndisabled}
                onClick={pushPageChange}
              >
                Submit</button>
            )}
            {showNextBtn && (
              <button
                onClick={scrollToElement}
                className="anim_ylw_preview btn btn-success px-3 "
              >
                Next
              </button>
            )}

          </footer>
        </div>
      </div>
    </>
  );
};
export default PreviewOD_UFL_4;
