import { useEffect, useState } from 'react';
import Select from 'react-select';
import { obLender } from '../../../../../Hooks/useOblender';
import Autocomplete from '../../../../UI/AutoComplete';


const BankName = ({
  clsName,
  saveInputAns,
  SelectBank,
  setSelectBank,
  reff,
  lender,
  validationMsg,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
}) => {

  const [BankValidationMsg, setBankValidationMsg] = useState(false);
const {getLenderlist}=obLender();
const [lenderList,setLenderList]=useState([]);
const [showOtherOpts,setShowOtherOpts] = useState(false);

useEffect(()=>{
  getLender();
},[])


async function  getLender(){
  try {
    const response = await getLenderlist();
    const dataLength = response?.data?.providers?.length;

    if (dataLength > 0) {
      setLenderList(response.data.providers);
    }
  } catch (error) {
    console.error("Error fetching lender list:", error);
  }
}
  const customStyles = {
    control: base => ({
      ...base,
      //height: 50,
      minHeight: 50
    })
  };

  const nextSlide = async (e) => {
    const values = getValues();
    console.log(values);

    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;
    let LabelSelectedBank  = SelectBank.label;
    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setBankValidationMsg(true);
    } else {
      if (ValueSelectedBank != "others") {
        setShowOtherOpts(false);
        localStorage.setItem("bud_lender", LabelSelectedBank);
        localStorage.setItem("lenderArray", JSON.stringify([SelectBank]));
        saveInputAns(e);
      } else {
        setShowOtherOpts(true);
        const input = values["otherlenders"];
        const otherlender = await trigger("otherlenders");


        if (input != "" && otherlender) {
          localStorage.setItem("bud_lender", input);
          saveInputAns(e);
        } else {
          setError("otherlenders", {
            type: "manual",
            message: "Please Enter Valid Bank Name",
          });
        }
      }
    }
  }


  function handleSelect(opt) {
    setSelectBank(opt);
    setBankValidationMsg(false);
    if (opt.value != "others") {
      setShowOtherOpts(false);
    } else {
      setShowOtherOpts(true);
    }
  }
  let BankList = []; 

  if (lenderList?.length > 0) {
    BankList = BankList.concat(lenderList?.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards");
    }).map(function (val) {
      return {
        value: val.provider,
        label: val.display_name
      };
    }));
  }
  return (
    <>
      <div className={`radio-box animated fadeInUp space-top space-top2 ${clsName}`} ref={reff} >
        <h3><b>Which bank were you with when you took your credit with {lender}?</b></h3>
        <div className="input-section mb-3 bank-select">
          <Select
            placeholder="Select Bank Name"
            options={BankList}
            aria-label="Username"
            name="bankName"
            id="bankName"
            value={SelectBank}
            onChange={handleSelect}
            isSearchable={true}
            styles={customStyles}
          // isMulti
          />
          {BankValidationMsg && (<span id="bank_err" className="error_msg">Please select your Bank</span>)}
        </div>
        <div className={`col-lg-12 mb-2 ${showOtherOpts ? "show" : "hide"}`}>
        <div className="input-group mb-3 z-2 autocomplete-wrapper">
        <Autocomplete
            validation={validation}
            clearErrors={clearErrors}
            />
          { validationMsg?.otherlenders && (
            <span id="bank_err" className="error_msg">
              { validationMsg?.otherlenders.message}
            </span>
          )}
        </div>
      </div>
        <div className="col-xl-12 col-lg-12 text-center p-0" >
          <button
            type="button"
            className="btn-next"
            onClick={nextSlide}
            data-quest-id={64}
            name="bankname"
          >{showOtherOpts ? "Save" : "NEXT"}</button>
        </div>

      </div>

    </>
  );
};

export default BankName;