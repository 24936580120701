import React, {
    useEffect,
    useState,
    useReducer,
    useRef,
    useContext,
  } from "react";
  import { useForm } from "react-hook-form";
  import "../../assets/Quest_NDY_UB_4_3/scss/modules/questionnaire.scss";
  import "../../assets/Quest_NDY_UB_4_3/scss/modules/custom.scss";
  import GetVisitorsParams from "../../Utility/GetVisitorsParams";
  import logowhite from "../../assets/Quest_NDY_UB_4/img/logo-white.png";
  import Loader from "../Includes/Layouts/QuestionnaireV26/Loader";
  import Footer2 from "../Includes/Layouts/Common/Footer2";
  import Filed from "../Includes/Layouts/QuestionnaireV26/Filed";
  import Bankruptcy from "../Includes/Layouts/QuestionnaireV26/Bankruptcy";
  import StillActive from "../Includes/Layouts/QuestionnaireV26/StillActive";
  import RecentCreditIncrease from "../Includes/Layouts/QuestionnaireV26/RecentCreditIncrease";
  import SoldOnDept from "../Includes/Layouts/QuestionnaireV26/SoldOnDept";
  import WhatYearHappen from "../Includes/Layouts/QuestionnaireV26/WhatYearHappen";
  import EmploymentStatus from "../Includes/Layouts/QuestionnaireV26/EmploymentStatus";
  import Dependents from "../Includes/Layouts/QuestionnaireV26/Dependents";
  import FurtherDetails from "../Includes/Layouts/QuestionnaireV26/FurtherDetails";
  import {
    questinnare1,
    questinnaire36Vanquis,
    questinnaire76Vanquis,
    answerArray,
    supportDocTypeArray,wereUalreadyPaying
  } from "../../Constants/Questions";
  import { VanquisProducts } from "../../Constants/Constants";
  import HighestBalance from "../Includes/Layouts/QuestionnaireV26/HighestBalance";
  import CreditLimitIncreased from "../Includes/Layouts/QuestionnaireV26/CreditLimitIncreased";
  import ExceedingCreditLimit from "../Includes/Layouts/QuestionnaireV26/ExceedingCreditLimit";
  import MinimumPayment from "../Includes/Layouts/QuestionnaireV26/MinimumPayment";
  import IncomeAndExp from "../Includes/Layouts/QuestionnaireV26/IncomeAndExp";
  import Emailconfirmation from "../Includes/Layouts/QuestionnaireV26/Emailconfirmation";
  import SupportingDoc from "../Includes/Layouts/QuestionnaireV26/SupportingDoc";
  import AgreementNoModal from "../Includes/Layouts/QuestionnaireV26/AgreementNoModal";
  import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
  import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
  import VisitorParamsContext from "../../Contexts/VisitorParams";
  import { FormData } from "../../Utility/FormData";
  import { userInfo } from "../../Hooks/userInfo";
  import SupportingDocUpload from "../Includes/Layouts/QuestionnaireV26/SupportingDocUpload";
  import { useHistory } from "react-router-dom";
  import { MonthNumberArray } from "../../Constants/Constants";
  import Footer from "../Includes/Layouts/VAQ_UB_4_2/Footer";
  import InitialYear from "../Includes/Layouts/QuestionnaireV26/InitialYear";
  import GtmDataLayer from "../../Utility/GtmDataLayer";
  import WhetherIandE from "../Includes/Layouts/QuestionnaireV26/WhetherIandE";
  import ConfirmSituation from "../Includes/Layouts/QuestionnaireV26/ConfirmSituation";
  import { obLender } from "../../Hooks/useOblender";
  import Hardship from "../Includes/Layouts/QuestionnaireV26/Hardship";
  import ClosedYear from "../Includes/Layouts/QuestionnaireV26/ClosedYear";
  import DidReceiveIncome from "../Includes/Layouts/QuestionnaireV26/DidReceiveIncome";
  import CardMonthlyIncome from "../Includes/Layouts/QuestionnaireV26/CardMonthlyIncome";
  import DependentsNew from "../Includes/Layouts/QuestionnaireV26/DependentsNew";
  import WereAlreadyPaying from "../Includes/Layouts/QuestionnaireV26/WereAlreadyPaying";
  import IncomeAndExpV2 from "../Includes/Layouts/QuestionnaireV26/IncomeAndExpV2";
  import CardOverSixYears from "../Includes/Layouts/QuestionnaireV26/CardOverSixYears";
  import LatePaymentLetters from "../Includes/Layouts/QuestionnaireV26/LatePaymentLetters";
  import Difficulties from "../Includes/Layouts/QuestionnaireV26/Difficulties";
  import FinanceProblem from "../Includes/Layouts/QuestionnaireV26/FinanceProblem";
  import DidYouAware from "../Includes/Layouts/QuestionnaireV26/DidYouAware";
  import Banks from "../Includes/Layouts/QuestionnaireV26/Banks";
  import SortCode from "../Includes/Layouts/QuestionnaireV26/SortCode";
  import BankAccNo from "../Includes/Layouts/QuestionnaireV26/BankAccNo";
  import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
  
  const initialState = {
    showSlide1: "show",
    showSlide1a: "show",
    showSlide2: "hide",
    showSlide2a: "hide",
    showSlide3: "hide",
    showSlide3a: "hide",
    showSlide4: "hide",
    showSlide4a: "hide",
    showSlide5: "hide",
    showSlide5a: "hide",
    showSlide6: "hide",
    showSlide6a: "hide",
    showSlide7: "hide",
    showSlide7a: "hide",
    showSlide8: "hide",
    showSlide8a: "hide",
    showSlide9: "hide",
    showSlide9a: "hide",
    showSlide10: "hide",
    showSlide10a: "hide",
    showSlide11: "hide",
    showSlide11a: "hide",
    showSlide12: "hide",
    showSlide12a: "hide",
    showSlide13: "hide",
    showSlide13a: "hide",
    showSlide14: "hide",
    showSlide14a: "hide",
    showSlide15: "hide",
    showSlide15a: "hide",
    showSlide16: "hide",
    showSlide16a: "hide",
    showSlide17: "hide",
    showSlide17a: "hide",
    showSlide18: "hide",
    showSlide18a: "hide",
    showSlide19: "hide",
    showSlide19a: "hide",
    showSlide21:'hide',
    showSlide21a:'hide',
    showSlide22:"hide",
    showSlide22a:"hide",
    showSlide23:"hide",
    showSlide23a:"hide",
    showSlide24:'hide',
    showSlide24a:'hide',
    showSlide25:'hide',
    showSlide25a:'hide',
    showSlide26:'hide',
    showSlide26a:'hide',
    showSlide27:'hide',
    showSlide27a:'hide',
    showSlide28:'hide',
    showSlide28a:'hide',
    showSlide29:'hide',
    showSlide29a:'hide',
    showSlide30:'hide',
    showSlide30a:'hide',
    showSlide31:'hide',
    showSlide31a:'hide',
  
    prev: "",
    closedPrev: "",
    IandEPrev: "",
  };
  
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide == "initialyears") {
          GtmDataLayer({
            question: "initially receive credit card",
          });
          return {
            ...state,
            showSlide1a: "hide",
            showSlide3: "show",
            showSlide3a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "struggle") {
          GtmDataLayer({
            question: "hardships or struggle",
          });
          return {
            ...state,
            showSlide2a: "hide",
            showSlide3: "show",
            showSlide3a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "filed") {
          GtmDataLayer({
            question: "filed a claim",
          });
          return {
            ...state,
            showSlide3a: "hide",
            showSlide4: "show",
            showSlide4a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "bankruptcy") {
          GtmDataLayer({
            question: "bankruptcy",
          });
          return {
            ...state,
            showSlide4a: "hide",
            showSlide29: "show",
            showSlide29a: "show",
            // showSlide5: "show",
            // showSlide5a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "btn_qest_36") {
          GtmDataLayer({
            question: "further details",
          });
          return {
            ...state,
            showSlide5a: "hide",
            showSlide6: "show",
            showSlide6a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "btn_qest_76") {
          GtmDataLayer({
            question: "situation statements",
          });
          return {
            ...state,
            showSlide6a: "hide",
            showSlide7: "show",
            showSlide7a: "show",
            // showSlide29a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "card_over_six_years" && action.payload.clickedSlide.slide_value == "236") {
          GtmDataLayer({
            question: "card over 6 years ago",
          });
          return {
            ...state,
            // showSlide24: "hide",
            showSlide24a: "hide",
            showSlide28: "show",
            showSlide28a: "show",          
          };
        } else if (action.payload.clickedSlide.slide == "did_you_aware") {
          GtmDataLayer({
            question: "aware of claim",
          });
          return {
            ...state,
            // showSlide24: "hide",
            showSlide28a: "hide",
            showSlide25: "show",
            showSlide25a: "show",          
          };
        } else if (action.payload.clickedSlide.slide == "card_over_six_years" && action.payload.clickedSlide.slide_value == "237") {
          GtmDataLayer({
            question: "card over 6 years ago",
          });
          return {
            ...state,
            // showSlide24: "hide",
            showSlide24a: "hide",
            showSlide29: "show",
            showSlide29a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "late_payment_letter" && action.payload.clickedSlide.slide_value == "238") {
          GtmDataLayer({
            question: "payment letters",
          });
          return {
            ...state,
            // showSlide25: "hide",
            showSlide25a: "hide",
            showSlide26: "show",
            showSlide26a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "late_payment_letter" && action.payload.clickedSlide.slide_value == "239") {
          GtmDataLayer({
            question: "payment letters",
          });
          return {
            ...state,
            // showSlide25: "hide",
            showSlide25a: "hide",
            showSlide29: "show",
            showSlide29a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "difficulty") {
          GtmDataLayer({
            question: "financial difficulties",
          });
          return {
            ...state,
            // showSlide29: "hide",
            showSlide26a: "hide",
            showSlide27: "show",
            showSlide27a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "problem") {
          GtmDataLayer({
            question: "problems in repaying",
          });
          return {
            ...state,
            // showSlide30: "hide",
            showSlide27a: "hide",
            showSlide29: "show",
            showSlide29a: "show",
          };
        }
        else if (action.payload.clickedSlide.slide == "banks") {
          GtmDataLayer({
            question: "which bank",
          });          
          return {
            ...state,
            showSlide31: "hide",
            showSlide29a: "hide",
            showSlide5: "show",
            showSlide5a: "show",
          };
        }
        // else if (action.payload.clickedSlide.slide == "stillactive") {
          // GtmDataLayer({
          //   question: "still active",
          // });
        //   return {
        //     ...state,
        //     showSlide7a: "hide",
        //     showSlide8: "show",
        //     showSlide8a: "show",
        //   };
        // }
        else if (
          action.payload.clickedSlide.slide == "stillactive" &&
          state.closedPrev == "96"
        ) {
          GtmDataLayer({
            question: "still active",
          });
          return {
            ...state,
            showSlide7a: "hide",
            showSlide18: "show",
            showSlide18a: "show",
          };
        } else if (
          action.payload.clickedSlide.slide == "stillactive" &&
          state.closedPrev == "95"
        ) {
          GtmDataLayer({
            question: "still active",
          });
          return {
            ...state,
            showSlide7a: "hide",
            showSlide8: "show",
            showSlide8a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "closedYear") {
          GtmDataLayer({
            question: "account close year",
          });
          return {
            ...state,
            showSlide18a: "hide",
            showSlide8: "show",
            showSlide8a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "recentcreditIncrease") {
          GtmDataLayer({
            question: "account year",
          });
          return {
            ...state,
            showSlide5a: "hide",
            showSlide6: "show",
            showSlide6a: "show",
          };
        } else if (
          action.payload.clickedSlide.slide == "SoldOnDebt" &&
          action.payload.clickedSlide.slide_value == "206"
        ) {
          GtmDataLayer({
            question: "sold your debt",
          });
          return {
            ...state,
            showSlide6a: "hide",
            showSlide7: "show",
            showSlide7a: "show",
          };
        } else if (
          action.payload.clickedSlide.slide == "SoldOnDebt" &&
          action.payload.clickedSlide.slide_value == "207"
        ) {
          GtmDataLayer({
            question: "sold your debt",
          });
          return {
            ...state,
            showSlide6a: "hide",
            showSlide7a: "hide",
            showSlide8: "show",
            showSlide8a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "WhatYearThisHappen") {
          GtmDataLayer({
            question: "year sold",
          });
          return {
            ...state,
            showSlide7a: "hide",
            showSlide8: "show",
            showSlide8a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "employmentsts") {
          GtmDataLayer({
            question: "employment status",
          });
          return {
            ...state,
            showSlide8a: "hide",
            showSlide10: "show",
            showSlide10a: "show",
          };
        } 
        else if (action.payload.clickedSlide.slide == "high_balance") {
          GtmDataLayer({
            question: "highest balance",
          });
          return {
            ...state,
            showSlide10a: "hide",
            showSlide11: "show",
            showSlide11a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "CredLimitInc") {
          GtmDataLayer({
            question: "credit limit increase",
          });
          return {
            ...state,
            showSlide11a: "hide",
            showSlide12: "show",
            showSlide12a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "excdCreditLimit") {
          GtmDataLayer({
            question: "exceed credit limit",
          });
          return {
            ...state,
            showSlide12a: "hide",
            showSlide13: "show",
            showSlide13a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "minmumPaymnt") {
          GtmDataLayer({
            question: "pay minimum payment",
          });
          return {
            ...state,
            showSlide13a: "hide",
            showSlide21: "show",
            showSlide21a: "show",
          };
        } else if (
          action.payload.clickedSlide.slide == "whetherIandE" &&
          action.payload.clickedSlide.slide_value == "213"
        ) {
          GtmDataLayer({
            question: "bolster complaint",
          });
          return {
            ...state,
            showSlide19a: "hide",
            showSlide21: "show",
            showSlide21a: "show",
          };
        } else if (
          action.payload.clickedSlide.slide == "whetherIandE" &&
          action.payload.clickedSlide.slide_value == "214"
        ) {
          GtmDataLayer({
            question: "bolster complaint",
          });
          return {
            ...state,
            showSlide19a: "hide",
            showSlide15: "show",
            showSlide15a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "incmAndMonth") {
          GtmDataLayer({
            question: "income and expenditure",
          });
          return {
            ...state,
            showSlide14a: "hide",
            showSlide15: "show",
            showSlide15a: "show",
          };
        } else if (
          action.payload.clickedSlide.slide == "btn_qest_48" ||
          action.payload.clickedSlide.slide == "question_61"
        ) {
          GtmDataLayer({
            question: "registered email address",
          });
          return {
            ...state,
            // showSlide15a: "hide",
            // showSlide16: "show",
            // showSlide16a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "question_42") {
          GtmDataLayer({
            question: "bolster complaint",
          });
          return {
            ...state,
            showSlide16a: "hide",
            showSlide17: "show",
            showSlide17a: "show",
          };
        } else if (action.payload.clickedSlide.slide == "support_doc") {
          GtmDataLayer({
            question: "",
          });
          return {
            ...state,
            showSlide17a: "hide",
          };
        }else if (action.payload.clickedSlide.slide == "didReceiveIncome" && action.payload.clickedSlide.slide_value == "226") {
          GtmDataLayer({
            question: "receive any income",
          });
          return {
            ...state,
            showSlide21a: "hide",
            showSlide22:"show",
            showSlide22a:"show"
          };
        }else if (action.payload.clickedSlide.slide == "didReceiveIncome" && action.payload.clickedSlide.slide_value == "227") {
          GtmDataLayer({
            question: "receive any income",
          });
          return {
            ...state,
            showSlide21a:"hide",
            showSlide9:"show",
            showSlide9a:"show",
  
          };
        }
        else if (action.payload.clickedSlide.slide == "cardMonthlyIncome") {
          GtmDataLayer({
            question: "monthly income",
          });
          return {
            ...state,
            showSlide22a:"hide",
            showSlide9:"show",
            showSlide9a:"show",
  
          };
        }else if (action.payload.clickedSlide.slide == "dependents") {
          GtmDataLayer({
            question: "dependents",
          });
          return {
            ...state,
            showSlide9a: "hide",
            showSlide15: "show",
            showSlide15a: "show",
          };
        }
        // else if (action.payload.clickedSlide.slide == "wereAlreadyPaying") {
        //   GtmDataLayer({
        //     question: "paying monthly",
        //   });
        //   return {
        //     ...state,
        //     showSlide23a: "hide",
        //     showSlide15: "show",
        //     showSlide15a: "show",
  
        //   };
        // }
         else if (action.payload.clickedSlide.slide == "sortcode") {
          GtmDataLayer({
            question: "sort code",
          });
          return {
            ...state,
            showSlide30a: "hide",
            showSlide31: "show",
            showSlide31a: "show",
  
  
          };
        } else if (action.payload.clickedSlide.slide == "bank_acc_no") {
          GtmDataLayer({
            question: "account number",
          });
          return {
            ...state,
            showSlide31a: "hide",
            showSlide7: "show",
            showSlide7a: "show",
  
          };
        }  
        else {
          return {
            ...state,
          };
        }
      }
  
      case "previousSlides": {
        if (action.payload.previousSlide.slide == "initialyears") {
          return {
            ...state,
            showSlide3a: "hide",
            showSlide3: "hide",
            showSlide1: "show",
            showSlide1a: "show",
          };
        } else if (action.payload.previousSlide.slide == "struggle") {
          return {
            ...state,
            showSlide3a: "hide",
            showSlide3: "hide",
            showSlide1: "show",
            showSlide1a: "show",
          };
        } else if (action.payload.previousSlide.slide == "filed") {
          return {
            ...state,
            showSlide4a: "hide",
            showSlide4: "hide",
            showSlide3: "show",
            showSlide3a: "show",
          };
        } else if (action.payload.previousSlide.slide == "bankruptcy") {
          return {
            ...state,
            showSlide29a: "hide",
            showSlide29: "hide",
            showSlide4: "show",
            showSlide4a: "show",
          };
        } else if (action.payload.previousSlide.slide == "btn_qest_36") {
          return {
            ...state,
            showSlide6a: "hide",
            showSlide6: "hide",
            showSlide5: "show",
            showSlide5a: "show",
          };
        } else if (action.payload.previousSlide.slide == "btn_qest_76") {
          return {
            ...state,
            // showSlide29a: "hide",
            showSlide30: "hide",
            showSlide6: "show",
            showSlide6a: "show",
          };
        } else if (action.payload.previousSlide.slide == "card_over_six_years" && state.oldCardval == "237") {
          return {
            ...state,
            showSlide29: "hide",
            showSlide29a: "hide",
            showSlide24: "show",
          };
        } else if (action.payload.previousSlide.slide == "late_payment_letter" && state.latePayval == "239") {
          return {
            ...state,
            showSlide29: "hide",
            showSlide29a: "hide",
            showSlide25: "show",
            showSlide25a: "show",
          };
        } else if (action.payload.previousSlide.slide == "card_over_six_years" && state.oldCardval == "236") {
          return {
            ...state,
            // showSlide21a:"hide",
            // showSlide21: "hide",
            showSlide28: "hide",
            showSlide28a: "hide",
            showSlide24: "show",
          };
        } else if (action.payload.previousSlide.slide == "difficulty") {
          return {
            ...state,
            showSlide27: "hide",
            showSlide27a: "hide",
            showSlide26: "show",
          };
        }  else if (action.payload.previousSlide.slide == "did_you_aware") {
          return {
            ...state,
            showSlide25: "hide",
            showSlide25a: "hide",
            showSlide28: "show",
          };
        }  else if (action.payload.previousSlide.slide == "problem" && state.latePayval == "238") {
          return {
            ...state,
            showSlide29: "hide",
            showSlide29a: "hide",
            showSlide27: "show",
            showSlide27a: "show",            
          };
        } else if (action.payload.previousSlide.slide == "late_payment_letter"&& state.latePayval == "238")  {
          return {
            ...state,
            showSlide26: "hide",
            showSlide25: "show",
            showSlide25a: "show",
          };
        } 
        else if (action.payload.previousSlide.slide == "banks") {
          return {
            ...state,
            showSlide5:"hide",
            showSlide5a: "hide",
            showSlide29: "show",
            showSlide29a: "show",
          };
        }
        else if (
          action.payload.previousSlide.slide == "stillactive" &&
          state.closedPrev == "95"
        ) {
          return {
            ...state,
            showSlide7: "show",
            showSlide7a: "show",
            showSlide8: "hide",
            showSlide8a: "hide",
          };
        } else if (
          action.payload.previousSlide.slide == "stillactive" &&
          state.closedPrev == "96"
        ) {
          return {
            ...state,
            // showSlide7:"show",
            // showSlide7a:"show",
            showSlide8: "hide",
            showSlide8a: "hide",
            showSlide18: "show",
          };
        } else if (action.payload.previousSlide.slide == "employmentsts") {
          return {
            ...state,
            showSlide10: "hide",
            showSlide10a: "hide",
            showSlide8: "show",
            showSlide8a: "show",
          };
        } else if (action.payload.previousSlide.slide == "recentcreditIncrease") {
          return {
            ...state,
            showSlide6a: "hide",
            showSlide6: "hide",
            showSlide5: "show",
            showSlide5a: "show",
          };
        } else if (action.payload.previousSlide.slide == "SoldOnDebt") {
          return {
            ...state,
            showSlide7a: "hide",
            showSlide7: "hide",
            showSlide6: "show",
            showSlide6a: "show",
          };
        } else if (action.payload.previousSlide.slide == "WhatYearThisHappen") {
          return {
            ...state,
            showSlide18a: "hide",
            showSlide18: "hide",
            showSlide7: "show",
            showSlide7a: "show",
          };
        } else if (
          action.payload.previousSlide.slide == "WhatYearThisHappen" &&
          state.prev == "206"
        ) {
          return {
            ...state,
            showSlide8a: "hide",
            showSlide8: "hide",
            showSlide7: "show",
            showSlide7a: "show",
          };
        } else if (
          action.payload.previousSlide.slide == "WhatYearThisHappen" &&
          state.prev == "207"
        ) {
          return {
            ...state,
            showSlide8a: "hide",
            showSlide8: "hide",
            showSlide6: "show",
            showSlide6a: "show",
          };
        }else if (action.payload.previousSlide.slide == "high_balance") {
          return {
            ...state,
            showSlide11a: "hide",
            showSlide11: "hide",
            showSlide10: "show",
            showSlide10a: "show",
          };
        } else if (action.payload.previousSlide.slide == "CredLimitInc") {
          return {
            ...state,
            showSlide12a: "hide",
            showSlide12: "hide",
            showSlide11: "show",
            showSlide11a: "show",
          };
        } else if (action.payload.previousSlide.slide == "excdCreditLimit") {
          return {
            ...state,
            showSlide13a: "hide",
            showSlide13: "hide",
            showSlide12: "show",
            showSlide12a: "show",
          };
        } else if (action.payload.previousSlide.slide == "minmumPaymnt") {
          return {
            ...state,
            showSlide21a: "hide",
            showSlide21: "hide",
            showSlide13: "show",
            showSlide13a: "show",
          };
        } else if (action.payload.previousSlide.slide == "whetherIandE") {
          return {
            ...state,
            // showSlide21a: "hide",
            showSlide21: "hide",
            showSlide21a: "hide",
            showSlide19: "show",
            showSlide19a: "show",
          };
        } else if (action.payload.previousSlide.slide == "wereAlreadyPaying") {
          return {
            ...state,
            showSlide23:"hide",
            showSlide23a:"hide",
            showSlide9: "show",
            showSlide9a: "show",
          };
        } else if (
          action.payload.previousSlide.slide == "incmAndMonth"
        ) {
          return {
            ...state,
            showSlide15a: "hide",
            showSlide15: "hide",
            showSlide9: "show",
            showSlide9a: "show",
          };
        } 
        // else if (
        //   action.payload.previousSlide.slide == "incmAndMonth" &&
        //   state.IandEPrev == "214"
        // ) {
        //   return {
        //     ...state,
        //     showSlide15a: "hide",
        //     showSlide15: "hide",
        //     showSlide19: "show",
        //     showSlide19a: "show",
        //   };
        // } 
        else if (action.payload.previousSlide.slide == "btn_qest_48") {
          return {
            ...state,
            showSlide16a: "hide",
            showSlide16: "hide",
            showSlide15: "show",
            showSlide15a: "show",
          };
        } else if (action.payload.previousSlide.slide == "bank_acc_no") {
          return {
            ...state,
            showSlide7a: "hide",
            showSlide7: "hide",
            showSlide6: "show",
            showSlide6a: "shoe",
            // showSlide31: "show",
            // showSlide31a: "show",
          };
        } else if (action.payload.previousSlide.slide == "sortcode") {
          return {
            ...state,
            showSlide31a: "hide",
            showSlide31: "hide",
            // showSlide7: "show",
            // showSlide7a: "show",
          };
        } else if (action.payload.previousSlide.slide == "question_42") {
          return {
            ...state,
            showSlide17a: "hide",
            showSlide17: "hide",
            showSlide16: "show",
            showSlide16a: "show",
          };
        }else if (action.payload.previousSlide.slide == "dependents" && state.q77prev == "226") {
          return {
            ...state,
            showSlide9a:"hide",
            showSlide9: "hide",
            showSlide22: "show",
            showSlide22a: "show",
          };
        }else if (action.payload.previousSlide.slide == "dependents" && state.q77prev == "227" ) {
          return {
            ...state,
            showSlide9a:"hide",
            showSlide9: "hide",
            showSlide21: "show",
            showSlide21a: "show",
          };
        }else if (action.payload.previousSlide.slide == "didReceiveIncome") {
          return {
            ...state,
            showSlide21a: "show",
            showSlide21: "show",
            showSlide22: "hide",
            showSlide22a: "hide",
          };  
      }
    }
    default:
        return state;
    }
  };
  const QuestionnaireV26 = () => {
    const splitForm = useRef(null);
    const scrollRef = useRef([]);  
    const history = useHistory();
    const [showLoad, setShowLoad] = useState("");
    const [clickedSlide, setClickedSlide] = useState([]);
    const [state, dispatch] = useReducer(FormReducer, initialState);
    const [optionSelected] = useState([]);
    const [SelectedOptions] = useState([]);
    const [completedQuestArr, setCompletedQuestArr] = useState([]);
    const [isRegisteredEmail, setIsRegisteredEmail] = useState("show");
    const [isNewEmailVisible, setIsNewEmailVisible] = useState("hide");
    const [agNoShow, setAgNoShow] = useState("hide");
    const { skippedLeads } = useSkippedLeads();
    const { isLoading, SaveQuestionnaire } = useQuestionnaire();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const query_string = localStorage.getItem("querystring");
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const { setFormData } = FormData();
    const [splitName, setSplitName] = useState();
    const [splitNameForSkip, setSplitNameForSkip] = useState();
    const [docType, setDocType] = useState("");
    const [showUserName, setUserName] = useState("");
    const { getUserInfo } = userInfo();
    const [supportDocType, setSupportDocType] = useState("");
    const [userEmail, setUserEmail] = useState();
    const [supportDoc, setSupportDoc] = useState("");
    const [questionCounter, setQuestionCounter] = useState(0);
    // let [totalQuestArr, setTotalQuestArr] = useState([61,56,55,54,53,3,2,72,71,70,26,31,36,,76,9,30,62,42,46]);
    let [totalQuestArr, setTotalQuestArr] = useState([
      61, 56, 55, 54, 53, 3, 2, 72, 71, 70, 26, 31, 36, 76, 9, 30, 62, 46, 64, 77, 79, 80, 81, 78 ,82,83, 84, 85, 86, 87 
    ]);
    const [previousSlide, setPreviousSlide] = useState([]);
    const [previous, setPrevious] = useState("");
    const [IandEPrev, setiandEPrev] = useState("");
    const [percentage, SetPercentage] = useState(0);
    const [lenderList, setLenderList] = useState([]);
    const { getLenderlist } = obLender();
    const [SelectBank, setSelectBank] = useState([]);
    const [BankValidationMsg, setBankValidationMsg] = useState(false);
    const divRefOne = useRef(null);
    const [yearSelected, setYearSelected] = useState("");
    const [veryLenderN, setveryLenderN] = useState("");
    const [quest49, setQuest49] = useState("");
    const [stillAct, setStillAct] = useState("");
    const [accClsYear, setAccClsYear] = useState([]);
    const [checklistOptions, setChecklistOptions] = useState([]);
    const[quest77Previous,setquest77Previous]=useState();
    const [inputListValues, setInputListValues] = useState([]);
    const [oldCard, setOldCard] = useState("");
    const [latePay, setlatePay] = useState("");
    const [sortCode, setSortCode] = useState(false);
    const [accNum, setAccNum] = useState(false);
    const [lenderName, setLenderName] = useState('');
  
    const {updateMilestone,updateUserMilestoneStatsQuest} = useUpdateMilestone();
  
  
    const { register, errors, trigger, setError, clearErrors, getValues } =
      useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
      });
  
    const getUuid = () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData != "") {
        var uuid = visitorData.uuid;
      } else {
        const query = new URLSearchParams(window.location.search);
        const query_uuid = query.get("uuid");
        const local_storage_uuid = localStorage.getItem("uuid");
        var uuid = query_uuid ? query_uuid : local_storage_uuid;
      }
      return uuid;
    };
    const uuid = getUuid();
  
    useEffect(() => {
      if (formdata === undefined || formdata === null || formdata == "") {
        (async () => {
          const name = localStorage.getItem("UcFname");
          const response = await getUserInfo(uuid);
          const set_formdata = setFormData(response);
          let dataLength = response.data.response.length;
          if (dataLength > 0) {
            const pageName = response.data.response[0].page_name
              ? response.data.response[0].page_name
              : " ";
            setSplitName(pageName);
            if (
              localStorage.getItem("split_name") != undefined &&
              localStorage.getItem("split_name") != undefined
            ) {
              setSplitNameForSkip(localStorage.getItem("split_name"));
            } else {
              setSplitNameForSkip(pageName);
            }
            const userName = response.data.response[0].first_name
              ? response.data.response[0].first_name +
                " " +
                response.data.response[0].last_name
              : " ";
            var userDobN = response.data.response[0].user_dob.split("/");
            var DobDayF = userDobN[0];
            var DobMonthF = userDobN[1];
            var DobYearF = userDobN[2];
            setSupportDocType(
              response.data.response[0]?.questionnaire[42]?.["answerId"]
            );
  
            setUserName(name);
            setUserEmail(response.data.response[0].email);
            const timer = setTimeout(() => {
              setShowLoad("hide");
            }, 3000);
          }
        })();
      } else {
        const name = localStorage.getItem("UcFname");
        const pageName = formdata.page_name;
        const form = splitForm.current;
        const values = getValues();
        const formData = values;
  
        let very_lender_id = formdata.productclaim;
        let very_lender = very_lender_id ? VanquisProducts[very_lender_id] : "";
        setveryLenderN(very_lender);
        setSplitName(pageName);
        if (
          localStorage.getItem("split_name") != undefined &&
          localStorage.getItem("split_name") != undefined
        ) {
          setSplitNameForSkip(localStorage.getItem("split_name"));
        } else {
          setSplitNameForSkip(pageName);
        }
        const userName = formdata.txtFName + " " + formdata.txtLName;
        setUserName(name);
        const dobMonth = formdata.DobMonth
          ? MonthNumberArray[formdata.DobMonth]
          : "";
  
        const timer = setTimeout(() => {
          setShowLoad("hide");
        }, 3000);
        setSupportDocType(formdata.question_42);
        setSupportDoc(answerArray[formdata.question_42]);
        setDocType(supportDocTypeArray[formdata.question_42]);
        setUserEmail(formdata.txtEmail);
      }
    }, [splitName]);
  
    useEffect(() => {
      const accOpenyr = yearSelected ? yearSelected : "2006";
      const accCloseYear = [{ label: "Select Year", value: "" }];
      const startAccYear = 2024;
      const endAccYear = accOpenyr;
      let aa = [];
      for (var k = startAccYear; k >= endAccYear; k--) {
        let objAccYear = {};
        objAccYear["label"] = k;
        objAccYear["value"] = k;
        aa.push(objAccYear);
      }
      setAccClsYear([...accCloseYear, ...aa]);
    }, [yearSelected]);
  
    state.prev = previous;
    state.closedPrev = stillAct;
    state.IandEPrev = IandEPrev;
    state.q77prev = quest77Previous;
    state.oldCardval = oldCard;
    state.latePayval = latePay;
  
    useEffect(() => {
      if (clickedSlide) {
        dispatch({ type: "showSlide", payload: { clickedSlide } });
        scrollToElement(clickedSlide.slide);
        setTickToElement(clickedSlide);
        setQuestionCounter(questionCounter + 1);
      }
    }, [clickedSlide]);
  
    const previousSlideChange = (e) => {
      setQuestionCounter(questionCounter - 1);
      setPreviousSlide({ slide: e.target.getAttribute("name") });
    };
  
    useEffect(() => {
      if (previousSlide) {
        dispatch({ type: "previousSlides", payload: { previousSlide } });
        scrollToElement(previousSlide.slide);
        // setTimeout(() => {
        //   window.scrollTo(0,0);
        // }, 50);
        slideBack(previousSlide);
        // setQuestionCounter(questionCounter-1);
      }
    }, [previousSlide]);
  
    const slideBack = (element) => {

      if (element.slide == "stillactive") {
        scrollRef.current["closedYear"]?.classList.remove("active");
      } else if (element.slide == "WhatYearThisHappen" && stillAct == 96) {
        scrollRef.current["stillactive"]?.classList.remove("active");
      } else if (element.slide == "stillactive") {
        scrollRef.current["stillactive"]?.classList.remove("active");
      }else if (element.slide == "dependents" && quest77Previous == '226') {
        scrollRef.current['cardMonthlyIncome']?.querySelector(".question-content").classList.remove("hide");
        scrollRef.current['cardMonthlyIncome']?.classList.remove("active");
      } else if (element.slide == "dependents" && quest77Previous == '227') {
        scrollRef.current['didReceiveIncome']?.querySelector(".question-content").classList.remove("hide");
        scrollRef.current['didReceiveIncome']?.classList.remove("active");
      }else if (element.slide == "wereAlreadyPaying") {
        scrollRef.current['dependents']?.querySelector(".question-content").classList.remove("hide");
        scrollRef.current['dependents']?.classList.remove("active");
      }  
      else {
        scrollRef.current[element.slide]?.classList.remove("active");
      }
  
      if (element.slide == "WhatYearThisHappen") {
        scrollRef.current["stillactive"]
          ?.querySelector(".question-content")
          .classList.remove("hide");
      } else if (element.slide == "WhatYearThisHappen" && previous == "206") {
        scrollRef.current["WhatYearThisHappen"]
          ?.querySelector(".question-content")
          .classList.remove("hide");
      } else if (element.slide == "incmAndMonth") {
        scrollRef.current["dependents"]
          ?.querySelector(".question-content")
          .classList.remove("hide");
          scrollRef.current['dependents']?.classList.remove("active");
      } else if (element.slide == "bank_acc_no") {
        scrollRef.current["btn_qest_76"]
          ?.querySelector(".question-content")
          .classList.remove("hide");
      }
      // else if (element.slide == "incmAndMonth" && IandEPrev == "214") {
      //   scrollRef.current["whetherIandE"]
      //     ?.querySelector(".question-content")
      //     .classList.remove("hide");
      // } 
      else if (element.slide == "stillactive" && stillAct == 96) {
        scrollRef.current["closedYear"]
          ?.querySelector(".question-content")
          .classList.remove("hide");
      } else if (element.slide == "stillactive" && stillAct == 95) {
        scrollRef.current["stillactive"]?.classList.remove("active");
        scrollRef.current["stillactive"]
          ?.querySelector(".question-content")
          .classList.remove("hide");
      } else {
        scrollRef.current[element.slide]
          ?.querySelector(".question-content")
          .classList.remove("hide");
      }
    };
  
    const storeQuestionnire = async (question_data) => {
      const response = await SaveQuestionnaire(
        visitorParameters.visitor_parameters,
        question_data,
        "question_store",
        visitorParameters.data,
        query_string,
        formdata
      );
    };
  
    const getLender = async () => {
      try {
          const response = await getLenderlist();
          const dataLength = response.data.providers.length;
  
          if (dataLength > 0) {
              setLenderList(response.data.providers);
          }
      } catch (error) {
          console.error("Error fetching lender list:", error);
      }
  }
  
  useEffect(() => {
    getLender();
  }, []);
  
    const onSelectAnswer = async (e) => {
      let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
      let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
      let anslabel = e.target.getAttribute("data-ans-label");
      const valuesreg = getValues();
      // let employment = valuesreg["employment"];
      let employment = splitForm.current["employment"].value;
      let validEmail = valuesreg["question_48"];
      let didReceiveIncome = valuesreg["didReceiveIncome"];
      // let dependents = valuesreg["dependents"];
      let dependents = splitForm.current["dependents"].value;
      let credLimitIncreased = splitForm.current["incresedCreditLimit"].value;
      let excredLimit = splitForm.current["exceedCreditLimit"].value;
      let minPayment = splitForm.current["minimumPayment"].value;
      let didYouAware = parseInt(splitForm.current["didYouAware"].value);
      let flage = false;
      let question_data = {};
      setCompletedQuestArr([...completedQuestArr, selectedQuest]);
  
      if (e.target.name == "employmentsts" && employment) {
        setClickedSlide({ slide: "employmentsts" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: employment,
          answer_text: "",
          input_answer: null,
        };
      } else if (e.target.name == "dependents") {
        setClickedSlide({ slide: "dependents" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: dependents,
          answer_text: "",
          input_answer: null,
        };
      } else if (e.target.name == "btn_qest_36") {
        setClickedSlide({ slide: "btn_qest_36" });
        if (selectedQuest) {
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: "",
            input_answer: optionSelected,
          };
        }
      } else if (e.target.name == "btn_qest_76") {
        setClickedSlide({ slide: "btn_qest_76" });
        if (selectedQuest) {
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: "",
            input_answer: SelectedOptions,
          };
        }
      } else if (e.target.name == "didYouAware") {
          setClickedSlide({ slide: "did_you_aware" });
          if (selectedQuest) {
            flage = true;
            question_data = {
              question_id: selectedQuest,
              option_id: didYouAware,
              answer_text: "",
              input_answer: null,
            };
          }
      }else if (e.target.name == "card_over_six_years") {
        setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
        if (selectedQuest) {
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: null,
            input_answer: null,
          };
        }
      }
      else if (e.target.name == "late_payment_letter") {
        setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
        if (selectedQuest) {
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: null,
            input_answer: null,
          };
        }
      }
      else if (e.target.name == "difficulty") {
        setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
        if (selectedQuest) {
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: null,
            input_answer: null,
          };
        }
      } 
      else if (e.target.name == "CredLimitInc" && credLimitIncreased) {
        setClickedSlide({ slide: "CredLimitInc" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: credLimitIncreased,
          answer_text: anslabel,
          input_answer: null,
        };
      } else if (e.target.name == "excdCreditLimit" && excredLimit) {
        setClickedSlide({ slide: "excdCreditLimit" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: excredLimit,
          answer_text: anslabel,
          input_answer: null,
        };
      } else if (e.target.name == "minmumPaymnt" && minPayment) {
        setClickedSlide({ slide: "minmumPaymnt" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: minPayment,
          answer_text: anslabel,
          input_answer: null,
        };
      } else if (e.target.name == "btn_qest_48" && validEmail) {
        setClickedSlide({ slide: "btn_qest_48" });
        flage = true;
        question_data = {
          question_id: 48,
          option_id: null,
          answer_text: null,
          input_answer: validEmail,
        };
      } else if (e.target.name == "question_61" && selectedAns == "163") {
        // setClickedSlide({ slide: "question_61"});
        totalQuestArr.push(48);
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: null,
          input_answer: null,
        };
      } else if (e.target.name == "question_42") {
        if (selectedAns && selectedQuest) {
          setClickedSlide({ slide: e.target.name });
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: null,
            input_answer: "",
          };
          if (selectedAns == "124") {
            setAgNoShow("show");
          }
        }
        setClickedSlide({ slide: "question_42" });
      } else if (e.target.name == "agreeyear") {
        setClickedSlide({ slide: "initialyears" });
        flage = true;
        question_data = {
          question_id: 49,
          option_id: e.target.value,
          answer_text: null,
          input_answer: null,
        };
      } else if (e.target.name == "struggle") {
        setClickedSlide({ slide: "struggle" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: e.target.value,
          answer_text: "",
          input_answer: null,
        };
      }else if (e.target.name == 'didReceiveIncome' && didReceiveIncome) {
        setClickedSlide({ slide: "didReceiveIncome" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: didReceiveIncome,
          answer_text: "",
          input_answer: null,
        }; 
      }else if (e.target.name == "wereAlreadyPaying") {
        setClickedSlide({ slide: "wereAlreadyPaying" });
        if (selectedQuest) {
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: "",
            input_answer: checklistOptions,
          };
        }
      } else {
        if (selectedAns && selectedQuest) {
          setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
          flage = true;
          question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: null,
            input_answer: "",
          };
        }
      }
      if (flage) {
        storeQuestionnire(question_data);
        let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (_existingEntries == null) _existingEntries = [];
        let existingIndex = _existingEntries.findIndex(
          (entry) => entry.question_id === question_data.question_id
        );
        if (existingIndex !== -1) {
          _existingEntries.splice(existingIndex, 1);
        }
        _existingEntries.push(question_data);
        localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  
        if (selectedAns == "123") {
          GtmDataLayer({
            question: "bolster complaint",
          });
          redirectToPreview();
        }
      }
    };
    const saveInputAns = async (e) => {
      let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
      let anslabel = e.target.getAttribute("data-ans-label");
      const valuesreg = getValues();
      let creditIncrease = valuesreg["RecentCreditIncrease"];
      let whatYear = valuesreg["whatYearThisHappen"];
      let highBalance = valuesreg["highest_balance"];
      let financeAgremmentNo = valuesreg["question_45"];
      let closedYr = valuesreg["closedyears"];
      let intialYear = valuesreg["initialyears"];
      let bankName = SelectBank.value;
      let cardMonthlyIncome = valuesreg["cardMonthlyIncome"];
      let causeProblm = valuesreg["question_84"];
      let aware_issue = valuesreg["awareSpecify"];
      let otherlenderValue = valuesreg["otherlenders"];

  
      setCompletedQuestArr([...completedQuestArr, selectedQuest]);
      let flage = false;
      let question_data = {};
  
      if (e.target.name == "recentcreditIncrease" && creditIncrease) {
        setClickedSlide({ slide: "recentcreditIncrease" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: creditIncrease,
        };
      } else if (e.target.name == "dontRecall") {
        setClickedSlide({ slide: "recentcreditIncrease" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: "I Don't Recall",
        };
      } else if (e.target.name == "WhatYearThisHappen" && whatYear) {
        setClickedSlide({ slide: "WhatYearThisHappen" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: whatYear,
        };
      } else if (e.target.name == "closedYear" && closedYr) {
        setClickedSlide({ slide: "closedYear" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: closedYr,
        };
      } else if (e.target.name == "banks" && bankName) {
        // setClickedSlide({ slide: "banks" });

        flage = true;
        if (bankName == 'others') {

          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: bankName,
            input_answer: otherlenderValue,
          };
        } else {

          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: bankName,
            input_answer: bankName,
          };
        }
      } else if (e.target.name == "bankOptions" && e.target.value) {
        // setClickedSlide({ slide: "banks" });
        flage = true;
        question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: e.target.value,
            input_answer: e.target.value,
        };
  
      }else if (e.target.name == "high_balance" && highBalance) {
        setClickedSlide({ slide: "high_balance" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: highBalance,
        };
      } else if (e.target.name == "initialyears") {
        setClickedSlide({ slide: "initialyears" });
        flage = true;
        if (selectedQuest) {
          question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: null,
            input_answer: intialYear,
          };
        }
      }
      else if (e.target.name == "openYearCredit") {
        setClickedSlide({ slide: "initialyears" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: null,
          input_answer: e.target.value,
        };
      } else if (selectedQuest == "45" && financeAgremmentNo) {
        setAgNoShow("hide");
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: financeAgremmentNo,
        };
      } else if (e.target.name == "cardMonthlyIncome") {
        setClickedSlide({ slide: "cardMonthlyIncome" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: cardMonthlyIncome,
        };
      } else if (e.target.name == "problem" && causeProblm) {
        setClickedSlide({ slide: "problem" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text:null,
          input_answer: causeProblm.trim(),
        };
      }else if (e.target.name == "didYouAware" && aware_issue) {
        setClickedSlide({ slide: "did_you_aware" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: 244,
          answer_text: "",
          input_answer: aware_issue.trim(),
        };
      } else if (e.target.name == "sortcode") {
        // setClickedSlide({ slide: "sortcode" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: sortCode,
        };
      } else if (e.target.name == "bank_acc_no") {
        // setClickedSlide({ slide: "bank_acc_no" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: accNum,
        };
      }
      if (flage) {
        storeQuestionnire(question_data);
  
        let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (_existingEntries == null) _existingEntries = [];
        let existingIndex = _existingEntries.findIndex(
          (entry) => entry.question_id === question_data.question_id
        );
        if (existingIndex !== -1) {
          _existingEntries.splice(existingIndex, 1);
        }
        _existingEntries.push(question_data);
        localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  
        if (selectedQuest == "45") {
          GtmDataLayer({
            question: "credit agreement number",
          });
          redirectToPreview();
        }
      }
    };
  
   
    useEffect(() => {
      var value = questionCounter;
      if (value == 1) {
        SetPercentage(0);
      } else {
        SetPercentage(Math.round(((value - 1) * 100) / totalQuestArr.length));
      }
    }, [questionCounter]);
  
    const scrollToElement = (element) => {
      scrollRef.current[element]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    };
  
    const setTickToElement = (element) => {
      if (element.slide == "question_61") {
        scrollRef.current["btn_qest_48"]?.classList.add("active");
      } else {
        scrollRef.current[element.slide]?.classList.add("active");
      }
      if (element.slide == "question_61" && element.slide_value == "162") {
        scrollRef.current["btn_qest_48"]
          ?.querySelector(".question-content")
          .classList.add("hide");
      }
      if (element.slide === "stillactive") {
        scrollRef.current["closedYear"]
          ?.querySelector(".question-content")
          .classList.remove("hide");
        // scrollRef.current['WhatYearThisHappen']?.querySelector(".question-content").classList.add("hide");
      }
      if (element.slide == "didReceiveIncomeYes"|| element.slide=='didReceiveIncomeNo') {
        // scrollRef.current["didReceiveIncome"]
        //   ?.querySelector(".question-content")
        //   .classList.remove("hide");
        scrollRef.current['didReceiveIncome']?.querySelector(".question-content").classList.add("hide");
      }
      scrollRef.current[element.slide]
        ?.querySelector(".question-content")
        .classList.add("hide");
    };
    const pushPageChangeV2 = () => {
      GtmDataLayer({
        question: "submit documentation",
      });
      updateUserMilestoneStatsQuest(
        visitorParameters.visitor_parameters.uuid,
        "live"
      );
      updateMilestone(visitorParameters.visitor_parameters.uuid,"questions","live");
  
      history.push(
        "/Verify_NDY_UB_4?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    };
  
    const redirectToPreview = async () => {
      updateMilestone(visitorParameters.visitor_parameters.uuid,"questions","live");
      history.push(
        "/Verify_NDY_UB_4?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    };
  
    // const SkippedLeads = async (type) => {
    //   const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);
    //   if (skippedLeadsResponse.data.status == "Success") {
    //     redirectToPreview();
    //   }
    // };
    const SkippedLeads = async (type,flag = true) => {
      const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);
      if (flag && skippedLeadsResponse.data.status == "Success") {
        redirectToPreview();
      }
    }
    const skipAgNo = async (e) => {
      setAgNoShow("hide");
      SkippedLeads("SkipAgreementNo");
    };
  
    const saveSlide80 = async (e) => {
      let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
      let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    
      setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    
      const values = getValues();
      let slide_array = [];
    
      if (checklistOptions.some((option) => option.id === 228)) {
        let answer228 = splitForm.current["question_228_229"].value;
        slide_array.push(["228", answer228]);
      }
      if (checklistOptions.some((option) => option.id === 229)) {
        let answer229 = splitForm.current["question_228_229"].value;
        slide_array.push(["229", answer229]);
      }
      if (checklistOptions.some((option) => option.id === 230)) {
        let answer230 = splitForm.current["question_230"].value;
        slide_array.push(["230", answer230]);
      }
      if (checklistOptions.some((option) => option.id === 231)) {
        let answer231 = splitForm.current["question_231"].value;
        slide_array.push(["231", answer231]);
      }
      if (checklistOptions.some((option) => option.id === 232)) {
        let answer232 = splitForm.current["question_232"].value;
        slide_array.push(["232", answer232]);
      }
      if (checklistOptions.some((option) => option.id === 233)) {
        let answer233 = splitForm.current["question_233"].value;
        slide_array.push(["233", answer233]);
      }
      if (checklistOptions.some((option) => option.id === 234)) {
        let answer234 = splitForm.current["question_234"].value;
        slide_array.push(["234", answer234]);
      }
      if (checklistOptions.some((option) => option.id === 235)) {
        let answer235 = splitForm.current["question_235"].value;
        slide_array.push(["235", answer235]);
      }
  
    
      var question_data = {
        slide_80: ["80",JSON.stringify(slide_array)],
      };
    
      setClickedSlide({ slide: "incmAndMonth" });
      storeQuestionnire(question_data);
    
      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    };
  
    const saveIncomeAndExpense = (checklistOptions, inputListValues) => {
      var question_data_79 = {
        question_id: 79,
        option_id: null,
        answer_text: null,
        input_answer: checklistOptions,
      };
      var question_data_80 = {
        slide_80: ["80", JSON.stringify(inputListValues)],
      };
  
      setClickedSlide({ slide: "wereAlreadyPaying" });
      storeQuestionnire(question_data_79);
      storeQuestionnire(question_data_80);
      setDataToLocalStorage(79, question_data_79);
      setDataToLocalStorage(80, question_data_80);
    };
  
    const setDataToLocalStorage = (quest_id, quest_data) => {
      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex;
      if(quest_id == 80) {
        existingIndex = _existingEntries.findIndex(entry => (entry?.slide_80));
      } else {
        existingIndex = _existingEntries.findIndex(entry => entry.question_id == quest_id);
      }
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(quest_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    };
  
  
    return (
      <>
        <GetVisitorsParams />
  
        <div className="questionnaire26">
          <section className="banner qs-section">
            <div className="container">
              <div>
                <div className="brand">
                  <div className="logo pt-3 text-center">
                    <a>
                      {" "}
                      <img src={logowhite} style={{ height: 60 }} alt=""></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="container mt-5 mb-5">
              <Loader showUserName={showUserName} showLoad={showLoad} />
  
              <div
                id="quetWraper"
                className="questionnair-section form-style mb-5"
                style={
                  showLoad === "hide"
                    ? { display: "block" }
                    : { display: "none " }
                }
              >
                <div
                  id="rangequestions"
                  className="qrange d-flex align-items-sm-center text-center"
                >
                  Completed
                  <br className="giveSpace" />
                  &nbsp;{percentage}%
                </div>
  
                <form
                  ref={splitForm}
                  name="split_form"
                  id="user_form"
                  autoComplete="off"
                >
                  <ul className="p-0 m-0 questionist mb-5">
                    <li>
                      <div
                        className={`questitem ${state.showSlide1}`}
                        id="slide-1"
                        ref={(el) => (scrollRef.current["initialyears"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          What year did you initially receive your credit card?
                        </h1>
                        <InitialYear
                          onSelectAnswer={onSelectAnswer}
                          saveInputAns={saveInputAns}
                          validation={register}
                          clearErrors={clearErrors}
                          trigger={trigger}
                          validationMsg={errors}
                          getValues={getValues}
                          setYearSelected={setYearSelected}
                          splitForm={splitForm}
                          setClickedSlide={setClickedSlide}
                          setQuest49={setQuest49}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide1a}`}>
                        Your response to this question help us in assessing your
                        eligibility for the claim process.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem ${state.showSlide2}`}
                        id="slide-2"
                        ref={(el) => (scrollRef.current["struggle"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          {" "}
                          {`Have you experienced any hardships or struggle in making the repayments to ${veryLenderN} on time while still meeting all your other commitments? `}{" "}
                        </h1>
  
                        <Hardship
                          onSelectAnswer={onSelectAnswer}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide2a}`}>
                        {`This is to understand you have any hardships or struggle in making the repayments.`}
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
  
                    <li>
                      <div
                        className={`questitem ${state.showSlide3}`}
                        id="slide-3"
                        ref={(el) => (scrollRef.current["filed"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          {`Have you, (or a company on your behalf), ever filed a claim with ${veryLenderN}? `}{" "}
                        </h1>
  
                        <Filed
                          onSelectAnswer={onSelectAnswer}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide3a}`}>
                        {`This inquiry aims to determine if you have previously submitted a claim to ${veryLenderN}. If you have, you will not qualify for another claim.`}
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem ${state.showSlide4}`}
                        id="slide-4"
                        ref={(el) => (scrollRef.current["bankruptcy"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          Are you currently subject to Bankruptcy or Trust deed?
                        </h1>
                        <Bankruptcy
                          onSelectAnswer={onSelectAnswer}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide4a}`}>
                        This question is posed to assess your financial situation.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem ${state.showSlide29} z-3`}
                        id="slide-29"
                        ref={(el) => (scrollRef.current["banks"] = el)}
                      >
                        <h1 className="que-title p-3">
                        {`Who were you banking with when you opened your account with ${veryLenderN}?`}
                        </h1>
  
                        <Banks
                          backClick={previousSlideChange}
                          lenderList={lenderList}
                          SelectBank={SelectBank}
                          setSelectBank={setSelectBank}
                          saveInputAns={saveInputAns}
                          oldCard={oldCard}
                          latePay={latePay}
                          show={state.showSlide29a}
                          setLenderName={setLenderName}
                          SkippedLeads={SkippedLeads}
                          setClickedSlide={setClickedSlide}
                          setAccNum={setAccNum}
                          lenderName={lenderName}
                          setSortCode={setSortCode}
                          validation={register}
                          validationMsg={errors}
                          setError={setError}
                          clearErrors={clearErrors}
                          getValues={getValues}
                          trigger={trigger}

                        />
                      </div>
                        <div className={`tipbox tip2 ${state.showSlide29a}`}>
                        {`We require your bank statements as these show your financial circumstances around the time of opening the account with ${veryLenderN}. We can request these from your bank. To do so, please provide the following`}
                          <div className="arrow  arrow-top" />
                        </div>
                    </li>
                    <li>
                      <div
                        className={`questitem q3 ${state.showSlide5}`}
                        id="slide-5"
                        ref={(el) => (scrollRef.current["btn_qest_36"] = el)}
                      >
                        <h1 className="que-title p-3">
                        {`At the time you secured your finance with ${veryLenderN} which of the below statements accurately describe your situation`}</h1>
                        <FurtherDetails
                          onSelectAnswer={onSelectAnswer}
                          questArr={questinnaire36Vanquis}
                          validation={register}
                          validationMsg={errors}
                          getValues={getValues}
                          trigger={trigger}
                          setError={setError}
                          optionSelected={optionSelected}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide5a}`}>
                        Your feedbacks helps support the basis of claims.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
  
                    <li>
                      <div
                        className={`questitem q3 ${state.showSlide6}`}
                        id="slide-6"
                        ref={(el) => (scrollRef.current["btn_qest_76"] = el)}
                      >
                        <h1 className="que-title p-3">
                          Thanks for that, please confirm if any of these
                          statements also accurately describe your situation.
                        </h1>
                        <ConfirmSituation
                          onSelectAnswer={onSelectAnswer}
                          optionArr={questinnaire76Vanquis}
                          validation={register}
                          validationMsg={errors}
                          getValues={getValues}
                          trigger={trigger}
                          setError={setError}
                          SelectedOptions={SelectedOptions}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide6a}`}>
                        Your feedbacks helps support the basis of claims.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem ${state.showSlide24}`}
                        id="slide-24"
                        ref={(el) => (scrollRef.current["card_over_six_years"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          Did you take the card over 6 years ago?
                        </h1>
                        <CardOverSixYears
                          setOldCard={setOldCard}
                          onSelectAnswer={onSelectAnswer}
                          backClick={previousSlideChange}
                        />
                      </div>
                      {/* <div className={`tipbox tip2 ${state.showSlide24a}`}>
                        Tooltip.
                        <div className="arrow  arrow-top" />
                      </div> */}
                    </li>
  
                    <li>
                      <div
                        className={`questitem  ${state.showSlide28}`}
                        id="slide-28"
                        ref={(el) => (scrollRef.current["did_you_aware"] = el)}
                      >
                        <h1 className="que-title p-3 ">{`When did you become aware you could make a claim against ${veryLenderN}? `}</h1>
                        <DidYouAware
                            onSelectAnswer={onSelectAnswer}
                            saveInputAns={saveInputAns}
                            splitForm={splitForm}
                            backClick={previousSlideChange}
                            validation={register}
                            trigger={trigger}
                            validationMsg={errors}
                            setError={setError}
                        />
                      </div>
                      {/* <div className={`tipbox tip2 ${state.showSlide28a}`}>
                      e.g. A change in circumstances / job loss / illness / divorce etc
                        <div className="arrow  arrow-top" />
                      </div> */}
                    </li>
  
                    <li>
                      <div
                        className={`questitem ${state.showSlide25}`}
                        id="slide-25"
                        ref={(el) => (scrollRef.current["late_payment_letter"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                        Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company?
                        </h1>
                        <LatePaymentLetters
                          onSelectAnswer={onSelectAnswer}
                          backClick={previousSlideChange}
                          setlatePay={setlatePay}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide25a}`}>
                      Do you recall ever receiving any messages from NewDay regarding late payments / issues / sold to Lowell / Arrow Global?
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
  
                    <li>
                      <div
                        className={`questitem ${state.showSlide26} z-3`}
                        id="slide-26"
                        ref={(el) => (scrollRef.current["difficulty"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                        When you received the communications from the lender, did you feel that any difficulties you had repaying / financially were:
                        </h1>
  
                        <Difficulties
                          backClick={previousSlideChange}
                          onSelectAnswer={onSelectAnswer}
                        />
                      </div>
                      {/* <div className={`tipbox tip2 ${state.showSlide26a}`}>
                      Do you recall ever receiving any messages from Vanquis regarding late payments / issues / sold to Lowell / Arrow Global?
                        <div className="arrow  arrow-top" />
                      </div> */}
                    </li>
  
                    <li>
                      <div
                        className={`questitem  ${state.showSlide27}`}
                        id="slide-27"
                        ref={(el) => (scrollRef.current["problem"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                        Please describe what really caused you to have problems in repaying / financially?
                        </h1>
                        <FinanceProblem
                           validation={register}
                           clearErrors={clearErrors}
                           trigger={trigger}
                           validationMsg={errors}
                           getValues={getValues}
                           backClick={previousSlideChange}
                           saveInputAns={saveInputAns}
                           setError={setError}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide27a}`}>
                      e.g. A change in circumstances / job loss / illness / divorce etc
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem  ${state.showSlide31}`}
                        id="slide-31"
                        ref={(el) => (scrollRef.current["bank_acc_no"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                        For your bank to locate your account please provide your Account Number
                        </h1>
                        <BankAccNo
                           validation={register}
                           clearErrors={clearErrors}
                           trigger={trigger}
                           validationMsg={errors}
                           getValues={getValues}
                           backClick={previousSlideChange}
                           saveInputAns={saveInputAns}
                           SkippedLeads={SkippedLeads}
                           setClickedSlide={setClickedSlide}
                           setAccNum={setAccNum}
                           lenderName={lenderName}
                        />
                      </div>
                      {/* <div className={`tipbox tip2 ${state.showSlide31a}`}>
                        {`This is required to cross confirm with ${veryLenderN} database and for effective communication. `}
                        <div className="arrow  arrow-top" />
                      </div> */}
                    </li>
                    <li>
                      <div
                        className={`questitem  ${state.showSlide30}`}
                        id="slide-30"
                        ref={(el) => (scrollRef.current["sortcode"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                        For your bank to locate your account please provide your sort code
                        </h1>
                        <SortCode
                           validation={register}
                           clearErrors={clearErrors}
                           trigger={trigger}
                           validationMsg={errors}
                           getValues={getValues}
                           backClick={previousSlideChange}
                           saveInputAns={saveInputAns}
                           SkippedLeads={SkippedLeads}
                           setClickedSlide={setClickedSlide}
                           setSortCode={setSortCode}
                           lenderName={lenderName}
                        />
                      </div>
                      {/* <div className={`tipbox tip2 ${state.showSlide30a}`}>
                        {`This is required to cross confirm with ${veryLenderN} database and for effective communication. `}
                        <div className="arrow  arrow-top" />
                      </div> */}
                    </li>
                  
                    <li>
                      <div
                        className={`questitem ${state.showSlide7}`}
                        id="slide-7"
                        ref={(el) => (scrollRef.current["stillactive"] = el)}
                      >
                        <h1 className="que-title p-3 ">{`Is your account with ${veryLenderN} still active? `}</h1>
                        <StillActive
                          onSelectAnswer={onSelectAnswer}
                          backClick={previousSlideChange}
                          setStillAct={setStillAct}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide7a}`}>
                        {`This is to determine the status of your ${veryLenderN} account.`}
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem ${state.showSlide18}`}
                        id="slide-18"
                        ref={(el) => (scrollRef.current["closedYear"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          What year did you close the account?
                        </h1>
                        <ClosedYear
                          saveInputAns={saveInputAns}
                          validation={register}
                          clearErrors={clearErrors}
                          trigger={trigger}
                          validationMsg={errors}
                          getValues={getValues}
                          backClick={previousSlideChange}
                          accClsYear={accClsYear}
                        />
                      </div>
                      {/* <div className={`tipbox tip2 ${state.showSlide18a}`}>
                        Your response to this question help us in assessing your
                        eligibility for the claim process. If your account was
                        closed prior to 2016, you will not qualify for this claim.
                        <div className="arrow  arrow-top" />
                      </div> */}
                    </li>
                    {/* <li>
                      <div className={`questitem ${state.showSlide19}`} id="slide-19" ref={(el) => (scrollRef.current['recentcreditIncrease'] = el)}>
                        <h1 className="que-title p-3 ">What year was the last credit limit increase on your account, if ever?
                        </h1>
                        <RecentCreditIncrease
                          saveInputAns={saveInputAns}
                          validation={register}
                          clearErrors={clearErrors}
                          trigger={trigger}
                          validationMsg={errors}
                          getValues={getValues}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide19a}`}>
                      If you're unsure,please select "I Don't Recall" to avoid neagatively effecting the claim.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li> */}
                    <li id="lop">
                      <div
                        className={`questitem ${state.showSlide8}`}
                        id="slide-8"
                        ref={(el) => (scrollRef.current["employmentsts"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          {" "}
                          {`In ${yearSelected},`} <b>at the time you took out the account with {`${veryLenderN}`}</b>, what was your employment status?{" "}
                        </h1>
                        <EmploymentStatus
                          splitForm={splitForm}
                          onSelectAnswer={onSelectAnswer}
                          validation={register}
                          clearErrors={clearErrors}
                          trigger={trigger}
                          validationMsg={errors}
                          getValues={getValues}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide8a}`}>
                        We require details regarding your employment status at the
                        time you obtained the credit card, as it aids in assessing your
                        financial situation..
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem q10 ${state.showSlide10}`}
                        id="slide-10"
                        ref={(el) => (scrollRef.current["high_balance"] = el)}
                      >
                        <h1 className="que-title p-3 ">Approximately what was the <b>highest balance you ever had with {`${veryLenderN}`} </b>credit account?</h1>
                        <HighestBalance
                          saveInputAns={saveInputAns}
                          validation={register}
                          clearErrors={clearErrors}
                          trigger={trigger}
                          validationMsg={errors}
                          getValues={getValues}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide10a}`}>
                        Your response to this question helps us to evaluate your
                        financial stability.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem q11 ${state.showSlide11}`}
                        id="slide-11"
                        ref={(el) => (scrollRef.current["CredLimitInc"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          Approximately how many{" "}
                          <strong>
                            {" "}
                            times was your credit limit increased on this account?
                          </strong>{" "}
                        </h1>
                        <CreditLimitIncreased
                          onSelectAnswer={onSelectAnswer}
                          validation={register}
                          clearErrors={clearErrors}
                          trigger={trigger}
                          validationMsg={errors}
                          getValues={getValues}
                          splitForm={splitForm}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide11a}`}>
                        This assists us in recognizing your ability to repay.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem q12 ${state.showSlide12}`}
                        id="slide-12"
                        ref={(el) => (scrollRef.current["excdCreditLimit"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          How often would you have a{" "}
                          <strong>
                            {" "}
                            balance which was close to, or exceeding your credit
                            limit?{" "}
                          </strong>
                        </h1>
                        <ExceedingCreditLimit
                          onSelectAnswer={onSelectAnswer}
                          splitForm={splitForm}
                          getValues={getValues}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide12a}`}>
                        This assists us in evaluating your expenses.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem q13  ${state.showSlide13}`}
                        id="slide-13"
                        ref={(el) => (scrollRef.current["minmumPaymnt"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          How often did you only{" "}
                          <strong>
                            {" "}
                            make the minimum payment due on the monthly account
                            statement
                          </strong>{" "}
                          (rather than paying off a larger amount)?
                        </h1>
                        <MinimumPayment
                          onSelectAnswer={onSelectAnswer}
                          splitForm={splitForm}
                          getValues={getValues}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide13a}`}>
                        This is to understand your repaying pattern every month.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    {/* <li>
                      <div
                        className={`questitem ${state.showSlide19}`}
                        id="slide-19"
                        ref={(el) => (scrollRef.current["whetherIandE"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                          {" "}
                          {`Would you also like to share your income and expenditure at the time you took out the finance with ${veryLenderN}? `}
                        </h1>
                        <WhetherIandE
                          onSelectAnswer={onSelectAnswer}
                          validation={register}
                          backClick={previousSlideChange}
                          setiandEPrev={setiandEPrev}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide19a}`}>
                        This helps us in evaluating your monthly income and
                        expenditures..
                        <div className="arrow  arrow-top" />
                      </div>
                    </li> */}
  
                    <li>
                      <div
                        className={`questitem ${state.showSlide21}`}
                        id="slide-23"
                        ref={(el) => (scrollRef.current["didReceiveIncome"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                         In the <b>year when you took the card</b> - did you receive any income?
                        </h1>
                        <DidReceiveIncome
                          onSelectAnswer={onSelectAnswer}
                          validation={register}
                          backClick={previousSlideChange}
                          setquest77Previous={setquest77Previous}
                        />
                      </div>
                      {/* <div className={`tipbox tip2 ${state.showSlide21a}`}>
                        This helps us in evaluating your monthly income and
                        expenditures..
                        <div className="arrow  arrow-top" />
                      </div> */}
                    </li>
                    <li>
                      <div
                        className={`questitem ${state.showSlide22}`}
                        id="slide-24"
                        ref={(el) =>
                          (scrollRef.current["cardMonthlyIncome"] = el)
                        }
                      >
                        <h1 className="que-title p-3 ">
                        When you <b>took out your card what was your monthly income?</b>
                        </h1>
                        <CardMonthlyIncome
                         saveInputAns={saveInputAns}
                         validation={register}
                         clearErrors={clearErrors}
                         trigger={trigger}
                         validationMsg={errors}
                         getValues={getValues}
                         backClick={previousSlideChange}
                        />
                      </div>
                      {/* <div className={`tipbox tip2 ${state.showSlide22a}`}>
                        If you're unsure,please select "I Don't Recall" to avoid
                        neagatively effecting the claim.
                        <div className="arrow  arrow-top" />
                      </div> */}
                    </li>
                    <li>
                      <div
                        className={`questitem  ${state.showSlide9}`}
                        id="slide-9"
                        ref={(el) => (scrollRef.current["dependents"] = el)}
                      >
                        <h1 className="que-title p-3 ">
                         How many dependents did you have at that time?
                        </h1>
                        <DependentsNew
                           splitForm={splitForm}
                           onSelectAnswer={onSelectAnswer}
                           validation={register}
                           clearErrors={clearErrors}
                           trigger={trigger}
                           validationMsg={errors}
                           getValues={getValues}
                           backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide9a}`}>
                      We need to determine the number of dependents in order to assess and evaluate your financial position.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem q25 ${state.showSlide23}`}
                        id="slide-25"
                        ref={(el) => (scrollRef.current["wereAlreadyPaying"] = el)}
                      >
                        <h1 className="que-title p-3">
                        When you <b>took out the card</b>, were you already paying monthly for one or more of the following:
                        </h1>
                        <WereAlreadyPaying
                          onSelectAnswer={onSelectAnswer}
                          questArr={wereUalreadyPaying}
                          validation={register}
                          validationMsg={errors}
                          getValues={getValues}
                          trigger={trigger}
                          setError={setError}
                          checklistOptions={checklistOptions}
                          backClick={previousSlideChange}
                          setChecklistOptions={setChecklistOptions}
                          setInputListValues={setInputListValues}
                          inputListValues={inputListValues}
                          saveIncomeAndExpense={saveIncomeAndExpense}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide23a}`}>
                       Select all that you had
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      {/* <div
                        className={`questitem q26 ${state.showSlide14}`}
                        id="slide-26"
                        ref={(el) => (scrollRef.current["incmAndMonth"] = el)}
                      >
                        <h1 className="que-title p-3">
                         
                          Please confirm the following MONTHLY / ANNUAL income and
                          expenditure details
                        </h1>
                        <IncomeAndExpV2
                          validation={register}
                          validationMsg={errors}
                          getValues={getValues}
                          trigger={trigger}
                          setError={setError}
                          splitForm={splitForm}
                          questArr={wereUalreadyPaying}
                          saveSlide80={saveSlide80}
                          scrollToElement={scrollToElement}
                          backClick={previousSlideChange}
                          checklistOptions={checklistOptions}
                        />
                      </div> */}
                      {/* <div className={`tipbox tip2 ${state.showSlide26a}`}>
                        slide26a
                        <div className="arrow  arrow-top" />
                      </div> */}
                    </li>
                    <li>
                      <div
                        className={`questitem q15 ${state.showSlide15}`}
                        id="slide-15"
                        ref={(el) => (scrollRef.current["btn_qest_48"] = el)}
                      >
                        <div
                          className={
                            isRegisteredEmail == "hide" ? "hide" : "show"
                          }
                        >
                          <h1 className="que-title p-3 regemail">
                            {" "}
                            {`Is this the email address ${userEmail} that you registered with ${veryLenderN} `}
                          </h1>
                        </div>
                        <div
                          className={
                            isNewEmailVisible == "show" ? "show" : "hide"
                          }
                        >
                          <h1 className="p-3 newemail que-title">{`Please provide the e-mail address you registered with ${veryLenderN} `}</h1>
                        </div>
                        <Emailconfirmation
                          onSelectAnswer={onSelectAnswer}
                          splitForm={splitForm}
                          getValues={getValues}
                          setIsRegisteredEmail={setIsRegisteredEmail}
                          setIsNewEmailVisible={setIsNewEmailVisible}
                          isRegisteredEmail={isRegisteredEmail}
                          isNewEmailVisible={isNewEmailVisible}
                          validation={register}
                          clearErrors={clearErrors}
                          trigger={trigger}
                          validationMsg={errors}
                          pushPageChange={pushPageChangeV2}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide15a}`}>
                        {`This is required to cross confirm with ${veryLenderN} database and for effective communication. `}
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem q16 ${state.showSlide16}`}
                        id="slide-16"
                        ref={(el) => (scrollRef.current["question_42"] = el)}
                      >
                        <h1 className="que-title p-3 ">{` What supporting documentation are you able to provide for your claim with ${veryLenderN}? `}</h1>
                        <SupportingDoc
                          onSelectAnswer={onSelectAnswer}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide16a}`}>
                      Please provide one of the above to support your claim
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                    <li>
                      <div
                        className={`questitem q17 ${state.showSlide17}`}
                        id="slide-17"
                      >
                        <h1 className="que-title p-3 ">
                          Supporting Documentation
                        </h1>
                        <SupportingDocUpload
                          saveInputAns={saveInputAns}
                          validation={register}
                          validationMsg={errors}
                          getValues={getValues}
                          trigger={trigger}
                          setError={setError}
                          backClick={previousSlideChange}
                          pushPageChange={pushPageChangeV2}
                          docType={docType}
                          split_name={splitNameForSkip}
                          uuid={uuid}
                          onSelectAnswer={onSelectAnswer}
                        />
                      </div>
                      <div className={`tipbox tip2 ${state.showSlide17a}`}>
                        Uploading any Supporting documents enable us to understand
                        your financial status.
                        <div className="arrow  arrow-top" />
                      </div>
                    </li>
                  </ul>
                  <AgreementNoModal
                    validation={register}
                    validationMsg={errors}
                    trigger={trigger}
                    saveInputAns={saveInputAns}
                    agNoShow={agNoShow}
                    skipAgNo={skipAgNo}
                    setAgNoShow={setAgNoShow}
                  />
                </form>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  };
  export default QuestionnaireV26;
  