import { useEffect, useState } from "react";
import { obLender } from "../../../../Hooks/useOblender";
import Select from "react-select";
import ErrorMessage from "./ErrorMessage";
import Autocomplete from "../../../UI/AutoComplete";

export default function BankSelect({ 
  setSelectedBank,
   setBankErrMsg, 
   bankErrMsg, 
   selectedBank,
   validation,
   getValues,
   trigger,
   validationMsg,
   setError,
  clearErrors,
 }) {

  const { getLenderlist } = obLender();

  const [lenderList, setLenderList] = useState([]);
  const [showOtherOpts,setShowOtherOpts] = useState(false);

  const customStyles = {
    control: base => ({
      ...base,
      //height: 50,
      minHeight: 50
    })
  };

  let BankList = [];

  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards");
    }).map(function (val) {
      return {
        value: val.provider,
        label: val.display_name
      };
    }));
  }
  function handleSelect(opt) {

    setSelectedBank([opt]);
    setBankErrMsg("");
    if(opt.value!="others"){
      setShowOtherOpts(false);
  }else{
    setShowOtherOpts(true);
  }
  }
  const getLender = async () => {
    try {
      const response = await getLenderlist();
      const dataLength = response.data.providers.length;

      if (dataLength > 0) {
        setLenderList(response.data.providers);
      }
    } catch (error) {
      console.error("Error fetching lender list:", error);
    }
  };

  useEffect(() => {
    getLender();

  }, []);

  return (
    <>
    <div className="mt-3 mb-3">
      <h5 className="title mb-3"> Select Your Bank</h5>
      <div className="bank-select">
        <Select
          options={BankList}
          placeholder="Select Bank"
          aria-label="Username"
          name="bankName"
          id="bankName"
          value={selectedBank}
          onChange={handleSelect}
          isSearchable={true}
          styles={customStyles}
        />
      </div>
      {
        '' !== bankErrMsg && <ErrorMessage errMessage={bankErrMsg} />
      }
    </div>
    <div className={`col-lg-12 mb-2 ${showOtherOpts ? "show" : "hide"}`}>
    <div className="row">
      <div className="col-lg-12 mb-2 z-1 autocomplete-wrapper">
        <Autocomplete 
          validation={validation}
          clearErrors={clearErrors}
          />
        {  validationMsg.otherlenders && (
          <span id="bank_err" className="error_msg">
            { validationMsg.otherlenders.message}
          </span>
        )}
      </div>
      </div>
    </div>
    </>
  );
}
