import React, { useState } from 'react';
import TextField from '../../../UI/TextField';

const HighestCreditLimit = ({
    trigger,
    validation,
    getValues,
    saveInputAns,
    backClick
}) => {
    const [error, setError] = useState(false);

    const handleClick = async(e) => {
        const values = getValues();
        const creditLimitHigh = values["creditLimitHigh"] ;
        let questionrslt = await trigger("creditLimitHigh");
        const re = /^[1-9]\d*$/;

        if (questionrslt) {
        if (creditLimitHigh !== "" && !re.test(creditLimitHigh)) {
            setError("Please enter a valid credit limit");
        }else{
            setError("");
            saveInputAns(e);
        }
    }else{
        setError("Please enter credit limit");
    }
    }
    const handleChange = (e) => {
        const re = /^[1-9]\d*$/;
        if (e.target.value !== "" && !re.test(e.target.value)) {
          e.target.value = e.target.value.replace(/\D/g, "") * 1;
        }
      };

    return (
        <div className="question-content mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
            <div className="row">
                <div className="col-lg-8 mb-3">
                    <TextField
                        type="tel"
                        className="form-control"
                        name="creditLimitHigh"
                        placeholder="Please Enter "
                        onChange={handleChange}
                        validation={validation({
                            required : true
                        })}
                    />
                    {error && (
                        <span className="error_msg">{error}</span>
                    )}
                </div>
                <div className="col-lg-4 mb-2">
                    <button
                        type="button"
                        name="highestcreditLimit"
                        className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                        onClick={handleClick}
                        data-quest-id={94}
                    >
                        Next  &#8594;
                    </button>
                </div>
            </div>
            <p className="text-center bck-marwid">
                <button
                    name="bankActedIrresponsibly"
                    className="mt-1 btn btn-secondary backbtn1a"
                    id=""
                    value=""
                    type="button"
                    onClick={backClick}
                >
                    Go Back
                </button>
            </p>
        </div>
    )
}

export default HighestCreditLimit;
