import React, { useState, useRef, useEffect, useReducer, useContext} from 'react';
// import "../../assets/OD_UFL_4/scss/modules/custom.scss";
// import "../../assets/OD_UFL_4/scss/modules/questionnaire.scss";
import "../../assets/OD_UFL_4/scss/main.scss";
import { useForm } from "react-hook-form";
import { wereUalreadyPaying } from '../../Constants/Questions';
import { useQuestionnaire } from '../../Hooks/useQuestionnaire';
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import logowhite from "../../assets/OD_UFL_4/img/logo-white.png";
import Loader from '../Includes/Layouts/QuestionnaireV24/Loader';
import loader_review2 from "../../assets/OD_UFL_4/img/loader-review2.svg";
import Footer from '../Includes/Layouts/OD_UFL_4/Footer';
import OverdraftComplain from '../Includes/Layouts/QuestionnaireV24/OverdraftComplain';
import MayRemoveOverdraft from '../Includes/Layouts/QuestionnaireV24/MayRemoveOverdraft';
import JointAccount from '../Includes/Layouts/QuestionnaireV24/JointAccount';
import Sortcode from '../Includes/Layouts/QuestionnaireV24/Sortcode';
import EverClaimed from '../Includes/Layouts/QuestionnaireV24/EverClaimed';
import OpeningCreditLimit from '../Includes/Layouts/QuestionnaireV24/OpeningCreditLimit';
import EverIncreCredLimit from '../Includes/Layouts/QuestionnaireV24/EverIncreCredLimit';
import BankActedIrresponsibly from '../Includes/Layouts/QuestionnaireV24/BankActedIrresponsibly';
import HighestCreditLimit from '../Includes/Layouts/QuestionnaireV24/HighestCreditLimit';
import ExceedCreditLimit from '../Includes/Layouts/QuestionnaireV24/ExceedCreditLimit';
import HowOftenExceedCredit from '../Includes/Layouts/QuestionnaireV24/HowOftenExceedCredit';
import OverLimitCharges from '../Includes/Layouts/QuestionnaireV24/OverLimitCharges';
import SufferedFinancialHardship from '../Includes/Layouts/QuestionnaireV24/SufferedFinancialHardship';
import ThinkBack from '../Includes/Layouts/QuestionnaireV24/ThinkBack';
import RecieveAnyIncome from '../Includes/Layouts/QuestionnaireV24/RecieveAnyIncome';
import MonthlyIncome from '../Includes/Layouts/QuestionnaireV24/MonthlyIncome';
import Dependents from '../Includes/Layouts/QuestionnaireV24/Dependents';
import WereAlreadyPaying from '../Includes/Layouts/QuestionnaireV24/WereAlreadyPaying';
import IncomeAndExpV2 from '../Includes/Layouts/QuestionnaireV24/IncomeAndExpV2';
import FurtherBorrowing from '../Includes/Layouts/QuestionnaireV24/FurtherBorrowing';
import StruggledRepayments from '../Includes/Layouts/QuestionnaireV24/StruggledRepayments';
import MaintainedCreditBalance from '../Includes/Layouts/QuestionnaireV24/MaintainedCreditBalance';
import StuckInOverdraft from '../Includes/Layouts/QuestionnaireV24/StuckInOverdraft';
import HowStuckAffects from '../Includes/Layouts/QuestionnaireV24/HowStuckAffects';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { FormData } from "../../Utility/FormData";
import { userInfo } from "../../Hooks/userInfo";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import { useHistory } from "react-router-dom";

const initialState = {
  openingCreditLimit: "show",
  everIncresedCredLimit: "hide",
  bankActedIrresponsibly: "hide",
  highestCreditLimit: "hide",
  exceedCredLimit: "hide",
  oftenExceedCredit: "hide",
  overLimit: "hide",
  showSlideH3: "hide",
  financialHardship: "hide",
  // thinkBackbtn: "hide",
  recieveAnyIncome: "hide",
  monthlyIncome: "hide",
  dependents: "hide",
  wereAlreadyPaying: "hide",
  incmAndExp: "hide",
  showSlideH4: "hide",
  furtherBorrowing: "hide",
  struggledRepayments: "hide",
  maintainedCreditBalance: "hide",
  stuckInOverdraft: "hide",
  howStuckAffects: "hide",
  exceedCredLimitBck: "",
  financialhardshipBck: "",
  dependentsBck: ""
};
const FormReducer = (state, action) => {
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide == "openingCreditLimit") {
        return {
          ...state,
          everIncresedCredLimit: "show",
        };
      } else if (action.payload.clickedSlide.slide == "everincresedCredLimit" && action.payload.clickedSlide.slide_value == "247") {
        return {
          ...state,
          bankActedIrresponsibly: "show",
        };
      } else if (action.payload.clickedSlide.slide == "everincresedCredLimit" && action.payload.clickedSlide.slide_value == "248") {
        return {
          ...state,
          exceedCredLimit: "show",
        };
      } else if (action.payload.clickedSlide.slide == "bankActedIrresponsibly") {
        return {
          ...state,
          highestCreditLimit: "show",
        };
      } else if (action.payload.clickedSlide.slide == "highestcreditLimit") {
        return {
          ...state,
          exceedCredLimit: "show",
        };
      } else if (action.payload.clickedSlide.slide == "exceedCredLimit" && action.payload.clickedSlide.slide_value == "251") {
        return {
          ...state,
          oftenExceedCredit: "show",
        };
      } else if (action.payload.clickedSlide.slide == "exceedCredLimit" && action.payload.clickedSlide.slide_value == "252") {
        return {
          ...state,
          showSlideH3: "show",
          financialHardship: "show",
        };
      } else if (action.payload.clickedSlide.slide == "oftenexceedCredit") {
        return {
          ...state,
          overLimit: "show",
        };
      } else if (action.payload.clickedSlide.slide == "overlimit") {
        return {
          ...state,
          financialHardship: "show",
          showSlideH3: "show",
        };
      } else if (action.payload.clickedSlide.slide == "financialhardship") {
        return {
          ...state,
          recieveAnyIncome: "show",
        };
      }
      //  else if (action.payload.clickedSlide.slide == "thinkBackbtn") {
      //   return {
      //     ...state,
      //     recieveAnyIncome: "show",
      //   };
      // }
       else if (action.payload.clickedSlide.slide == "recieveanyincome" && action.payload.clickedSlide.slide_value == "267") {
        return {
          ...state,
          monthlyIncome: "show",
        };
      } else if (action.payload.clickedSlide.slide == "recieveanyincome" && action.payload.clickedSlide.slide_value == "268") {
        return {
          ...state,
          dependents: "show",
        };
      } else if (action.payload.clickedSlide.slide == "monthlyincome") {
        return {
          ...state,
          dependents: "show",
        };
      } else if (action.payload.clickedSlide.slide == "dependents") {
        return {
          ...state,
          wereAlreadyPaying: "show",
        };
      } else if (action.payload.clickedSlide.slide == "wereAlreadyPaying") {
        return {
          ...state,
          incmAndExp: "show",
        };
      } else if (action.payload.clickedSlide.slide == "incmAndexp") {
        return {
          ...state,
          furtherBorrowing: "show",
          showSlideH4: "show",
        };
      } else if (action.payload.clickedSlide.slide == "furtherborrowing") {
        return {
          ...state,
          struggledRepayments: "show"
        };
      } else if (action.payload.clickedSlide.slide == "struggledrepayments") {
        return {
          ...state,
          maintainedCreditBalance: "show"
        };
      } else if (action.payload.clickedSlide.slide == "maintainedcreditbalance") {
        return {
          ...state,
          stuckInOverdraft: "show"
        };
      } else if (action.payload.clickedSlide.slide == "stuckinoverdraft" && action.payload.clickedSlide.slide_value != "293") {
        return {
          ...state,
          howStuckAffects: "show"
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    case "previousSlides": {
    if (action.payload.previousSlide.slide == "openingCreditLimit") { 
        return {
          ...state,
          everIncresedCredLimit: "hide",
          openingCreditLimit: "show",
        };
      }
      else if (action.payload.previousSlide.slide == "everincresedCredLimit" && state.exceedCredLimitBck == "247") {
        return {
          ...state,
          bankActedIrresponsibly: "hide",
          everIncresedCredLimit: "show",
        };
      } else if (action.payload.previousSlide.slide == "everincresedCredLimit" && state.exceedCredLimitBck == "248") {
        return {
          ...state,
          exceedCredLimit: "hide",
          everIncresedCredLimit: "show",
        };
      } else if (action.payload.previousSlide.slide == "bankActedIrresponsibly") {
        return {
          ...state,
          highestCreditLimit: "hide",
          bankActedIrresponsibly: "show",
        };
      } else if (action.payload.previousSlide.slide == "highestcreditLimit") {
        return {
          ...state,
          exceedCredLimit: "hide",
          highestCreditLimit: "show",
        };
      } else if (action.payload.previousSlide.slide == "exceedCredLimit" && state.financialhardshipBck == "251") {
        return {
          ...state,
          oftenExceedCredit: "hide",
          exceedCredLimit: "show",
        };
      } else if (action.payload.previousSlide.slide == "exceedCredLimit" && state.financialhardshipBck == "252") {
        return {
          ...state,
          financialHardship: "hide",
          showSlideH3: "hide",
          exceedCredLimit: "show",
        };
      } else if (action.payload.previousSlide.slide == "oftenexceedCredit") {
        return {
          ...state,
          overLimit: "hide",
          oftenExceedCredit: "show",
        };
      } else if (action.payload.previousSlide.slide == "overlimit") {
        return {
          ...state,
          financialHardship: "hide",
          overLimit: "show",
          showSlideH3: "hide",
        };
      } 
      else if (action.payload.previousSlide.slide == "financialhardship") {
        return {
          ...state,
          recieveAnyIncome: "hide",
          financialHardship: "show",
        };
      } 
      // else if (action.payload.previousSlide.slide == "thinkBackbtn") {
      //   return {
      //     ...state,
      //     recieveAnyIncome: "hide",
      //     financialhardship: "show",
      //   };
      // } 
      else if (action.payload.previousSlide.slide == "recieveanyincome" && state.dependentsBck == "267") {
        return {
          ...state,
          monthlyIncome: "hide",
          recieveAnyIncome: "show",
        };
      } else if (action.payload.previousSlide.slide == "recieveanyincome" && state.dependentsBck == "268") {
        return {
          ...state,
          dependents: "hide",
          recieveAnyIncome: "show",
        };
      } else if (action.payload.previousSlide.slide == "monthlyincome") {
        return {
          ...state,
          dependents: "hide",
          monthlyIncome: "show",
        };
      } else if (action.payload.previousSlide.slide == "dependents") {
        return {
          ...state,
          wereAlreadyPaying: "hide",
          dependents: "show",
        };
      } else if (action.payload.previousSlide.slide == "wereAlreadyPaying") {
        return {
          ...state,
          incmAndExp: "hide",
          wereAlreadyPaying: "show",
        };
      } else if (action.payload.previousSlide.slide == "incmAndexp") {
        return {
          ...state,
          furtherBorrowing: "hide",
          showSlideH4: "hide",
          incmAndExp: "show",
        };
      } else if (action.payload.previousSlide.slide == "furtherborrowing") {
        return {
          ...state,
          struggledRepayments: "hide",
          furtherBorrowing: "show",
        };
      } else if (action.payload.previousSlide.slide == "struggledrepayments") {
        return {
          ...state,
          maintainedCreditBalance: "hide",
          struggledRepayments: "show",
        };
      } else if (action.payload.previousSlide.slide == "maintainedcreditbalance") {
        return {
          ...state,
          stuckInOverdraft: "hide",
          maintainedCreditBalance: "show",
        };
      } else if (action.payload.previousSlide.slide == "stuckinoverdraft") {
        return {
          ...state,
          howStuckAffects: "hide",
          stuckInOverdraft: "show",
        };
      }
    }
    default:
      return state;
  }
}

const QuestionnaireV24 = () => {
  const splitForm = useRef(null);
  const scrollRef = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [showUserName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState();
  const [showLoad, setShowLoad] = useState("");
  const [loader, setLoader] = useState("");
  const [loader2, setLoader2] = useState("");
  const [loader3, setLoader3] = useState("");
  const [everIncreased, setEverIncreased] = useState("");
  const [recieveIncomeback, setRecieveIncomeback] = useState("");
  const [stuckinOverdraftbck, setStuckinOverdraftbck] = useState("");
  const [exceedCredit, setExceedCredit] = useState("");
  const [sortCode, setSortCode] = useState(false);
  const [checklistOptions, setChecklistOptions] = useState([]);
  const [checkedOptions, setCheckedOptions] = useState([]);
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query_string = localStorage.getItem("queryString");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const { setFormData } = FormData();
  const { getUserInfo } = userInfo();
  const [splitName, setSplitName] = useState();
  const [splitNameForSkip, setSplitNameForSkip] = useState();
  const [percentage, SetPercentage] = useState(0);
  const [questionCounter, setQuestionCounter] = useState(0);
  const {updateMilestone} = useUpdateMilestone();
  const history = useHistory();
  let [totalQuestArr, setTotalQuestArr] = useState([
     91,92,95,98,100,101,102,103,104,105,106,3,79,80
  ]);


  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

    const getUuid = () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData != "") {
        var uuid = visitorData.uuid;
      } else {
        const query = new URLSearchParams(window.location.search);
        const query_uuid = query.get("uuid");
        const local_storage_uuid = localStorage.getItem("uuid");
        var uuid = query_uuid ? query_uuid : local_storage_uuid;
      }
      return uuid;
    };
    const uuid = getUuid();
  useEffect (() => {
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const name = localStorage.getItem("UcFname");
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const pageName = response.data.response[0].page_name
            ? response.data.response[0].page_name
            : " ";
          setSplitName(pageName);
          if (
            localStorage.getItem("split_name") != undefined &&
            localStorage.getItem("split_name") != undefined
          ) {
            setSplitNameForSkip(localStorage.getItem("split_name"));
          } else {
            setSplitNameForSkip(pageName);
          }
          var userDobN = response.data.response[0].user_dob.split("/");
          setUserName(name);
          setUserEmail(response.data.response[0].email);
      setTimeout(() => {
          setShowLoad("hide");
        }, 3000);
      }
})();
} else {
  const name = localStorage.getItem("UcFname");
  const pageName = formdata.page_name;
  setSplitName(pageName);
  if (
    localStorage.getItem("split_name") != undefined &&
    localStorage.getItem("split_name") != undefined
  ) {
    setSplitNameForSkip(localStorage.getItem("split_name"));
  } else {
    setSplitNameForSkip(pageName);
  }
  setUserName(name);
  setUserEmail(formdata.txtEmail);
  setTimeout(() => {
    setShowLoad("hide");
  }, 3000);
}
}, [splitName]);

  state.exceedCredLimitBck = everIncreased;
  state.financialhardshipBck = exceedCredit;
  state.dependentsBck = recieveIncomeback;

  useEffect(() => {
    var value = questionCounter;
    if (value == 1) {
      SetPercentage(0);
    } else {
      SetPercentage(Math.round(((value - 1) * 100) / totalQuestArr.length));
    }
  }, [questionCounter]);


  const scrollToElement = (element) => {
    scrollRef.current[element]?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  };


  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
      scrollToElement(clickedSlide.slide);
      setTickToElement(clickedSlide);
        setQuestionCounter(questionCounter + 1);
    }
  }, [clickedSlide]);

  const previousSlideChange = (e) => {
    setQuestionCounter(questionCounter - 1);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
      scrollToElement(previousSlide.slide);
      // setTimeout(() => {
      //   window.scrollTo(0, 0);
      // }, 50);
      slideBack(previousSlide);
    }
  }, [previousSlide]);

  const setTickToElement = (element) => {
    scrollRef.current[element.slide]?.classList.add("active");

    scrollRef.current[element.slide]
      ?.querySelector(".question-content")
      .classList.add("hide");
  };

  const slideBack = (element) => {
    scrollRef.current[element.slide]?.classList.remove("active");
    scrollRef.current[element.slide]
      ?.querySelector(".question-content")
      .classList.remove("hide");
  }
  
  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };

  const onSelectAnswer = async (e, param) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let flage = false;
    let question_data = {};

    console.log(selectedAns, selectedQuest);
   
    if ((selectedAns == "252" && selectedQuest == "95") || selectedAns && selectedQuest == "97") {
      setTimeout(() => {
        setLoader2("show");

        setTimeout(() => {
          setLoader2("hide");
        }, 2000);
      }, 0);
      setClickedSlide({ slide: e.target.name });
      totalQuestArr.push(selectedQuest);
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: null,
        input_answer: null,
      };
    }

    if (e.target.name == "wereAlreadyPaying") {
      setClickedSlide({ slide: e.target.name });
      totalQuestArr.push(79);
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: checklistOptions,
        };
      }
    }
    if (e.target.name == "furtherborrowing") {
      totalQuestArr.push(102);
      setClickedSlide({ slide: e.target.name });
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: checkedOptions,
        };
      }
    }
    if (selectedAns && selectedQuest == 105 ) {
      totalQuestArr.push(105)
      setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: null,
        input_answer: null,
      };
    }

    if (selectedAns && selectedQuest) {
      totalQuestArr.push(selectedQuest)
      setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: null,
        input_answer: null,
      };
    }
    console.log(question_data);
    
    if (flage) {
      storeQuestionnire(question_data);
      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(
        (entry) => entry.question_id === question_data.question_id
      );
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));

      if (selectedAns == 293 && selectedQuest == 105) {
        redirectToPreview();
      }
    }
  }
  const saveSlide80 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));

    // setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    const values = getValues();
    let slide_array = [];

   
    if (checklistOptions.some((option) => option.id === 229)) {
      let answer229 = splitForm.current["question_228_229"].value;
      slide_array.push(["229", answer229]);
    }
    if (checklistOptions.some((option) => option.id === 230)) {
      let answer230 = splitForm.current["question_230"].value;
      slide_array.push(["230", answer230]);
    }
    if (checklistOptions.some((option) => option.id === 231)) {
      let answer231 = splitForm.current["question_231"].value;
      slide_array.push(["231", answer231]);
    }
    if (checklistOptions.some((option) => option.id === 232)) {
      let answer232 = splitForm.current["question_232"].value;
      slide_array.push(["232", answer232]);
    }
    if (checklistOptions.some((option) => option.id === 233)) {
      let answer233 = splitForm.current["question_233"].value;
      slide_array.push(["233", answer233]);
    }
    if (checklistOptions.some((option) => option.id === 234)) {
      let answer234 = splitForm.current["question_234"].value;
      slide_array.push(["234", answer234]);
    }
    if (checklistOptions.some((option) => option.id === 235)) {
      let answer235 = splitForm.current["question_235"].value;
      slide_array.push(["235", answer235]);
    }


    var question_data = {
      slide_80: ["80", JSON.stringify(slide_array)],
    };

    setClickedSlide({ slide: "incmAndexp" });
    setTimeout(() => {
      setLoader3("show");

      setTimeout(() => {
        setLoader3("hide");
      }, 2000);
    }, 0);
    storeQuestionnire(question_data);
    console.log(question_data);
    

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
    if (existingIndex !== -1) {
      _existingEntries.splice(existingIndex, 1);
    }
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };
  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let flage = false;
    let question_data = {};
    let openingcrditlimit = valuesreg['creditLimit'];
    let creditLimitHigh =valuesreg['creditLimitHigh'];
    let thinkback =valuesreg['thinkback'];
    let stuckAffects =valuesreg['stuckAffects'].trim();

    if (selectedQuest == 91 && openingcrditlimit) {
      setClickedSlide({ slide: e.target.name });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: openingcrditlimit,
      };
    }else if(selectedQuest == 94 && creditLimitHigh){
      setClickedSlide({ slide: e.target.name });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: creditLimitHigh,
      };
    }else if(selectedQuest == 99 && thinkback){
      setClickedSlide({ slide: e.target.name });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: thinkback,
      };
    }else if(selectedQuest == 106 && stuckAffects){
      setClickedSlide({ slide: e.target.name });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: stuckAffects,
      };
    }
    console.log(question_data);
    
    if (flage) {
      storeQuestionnire(question_data);

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(
        (entry) => entry.question_id === question_data.question_id
      );
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
      if(selectedQuest == "106" && stuckAffects)
        redirectToPreview();
      }
    
  };

  const redirectToPreview = async () => {
    updateMilestone(visitorParameters.visitor_parameters.uuid,"questions","live");
    history.push(
      "/Verify_VAQ_UB_4?uuid=" + visitorParameters.visitor_parameters.uuid
    );
  };
  return (
    <>
    <GetVisitorsParams />
    <div className='OD_UFL_4'>
      <section className="banner qs-section pb-5">

        <div className="container">
          <div>
            <div className="brand">
              <div className="logo pt-3 text-center">
                <a>
                  {" "}
                  <img src={logowhite} style={{ height: 60 }} alt=""></img>
                </a>
              </div>
            </div>
          </div>
        </div>


        <div className="container mt-5 mb-5">

          <Loader showUserName={showUserName} showLoad={showLoad} />


          <div style={showLoad === "hide" ? { display: "block" } : { display: "none " }}>
          <div
                id="rangequestions"
                className="qrange d-flex align-items-sm-center text-center"
              >
                Completed
                <br className="giveSpace" />
                &nbsp;{percentage}%
              </div>
            <form
              ref={splitForm}
              name="split_form"
              id="user_form"
              autoComplete="off"
            >
              {/* <h2 className="text-white mb-4 fs-4 " id="qstitle">
                <div className="d-flex">
                  <div className="pe-2"> <i className="bi bi-question-circle" /> </div>
                  <div> Your Details</div>
                </div>
              </h2> */}
              {/* <div id="quetWraper" className="questionnair-section form-style mb-4"> */}

              {/* <ul className="p-0 m-0 questionist mb-5"> */}
              {/* <li>
                    <div className={`questitem ${state.showSlide1}`} id="slide1"
                      ref={(el) => (scrollRef.current["complainOverdraft"] = el)}>
                      <h1 className="que-title p-3 "> I would like to complain about the sale of my overdraft </h1>
                      <OverdraftComplain
                        onSelectAnswer={onSelectAnswer}
                      />
                    </div>
                  </li> */}
              {/* <li>
                    <div className={`questitem ${state.showSlide2}`} id="slide2"
                      ref={(el) => (scrollRef.current["mayRemoveOverdraft"] = el)}>
                      <h1 className="que-title p-3 "> *Please note banks may remove the overdraft facility for upto a year should
                        your claim be succssful.
                      </h1>
                      <MayRemoveOverdraft
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li> */}
              {/* <li>
                    <div className={`questitem ${state.showSlide3}`} id="slide3"
                      ref={(el) => (scrollRef.current["jointAccount"] = el)}
                    >
                      <h1 className="que-title p-3 ">Is this a joint account ?
                      </h1>
                      <JointAccount
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li> */}
              {/* <li>
                    <div className={`questitem ${state.showSlide4}`} id="slide4"
                      ref={(el) => (scrollRef.current["sortcode"] = el)}>
                      <h1 className="que-title p-3 ">Please provide sort code and last 4 digits of account if possible </h1>
                      <Sortcode
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                        setSortCode={setSortCode}
                      />
                    </div>
                  </li> */}
              {/* <li>
                    <div className={`questitem ${state.showSlide5}`} id="slide3"
                      ref={(el) => (scrollRef.current["everclaimed"] = el)}
                    >
                      <h1 className="que-title p-3 ">Has TMS Legal ever claimed for you before, for a different product?
                      </h1>
                      <EverClaimed
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                </ul>
              </div> */}

              <h2 className="text-white mb-4 fs-4 qstitle drop-in" id="qsTitle2">
                <div className="d-flex"  >
                  <div className="pe-2"> <i className="bi bi-question-circle" /> </div>
                  <div> About your overdraft Facility ?</div>
                </div>
              </h2>

              <div id="quetWraper2" className="drop-in questionnair-section form-style mb-4">
                <ul className="p-0 m-0 questionist mb-4">
                  <li>
                    <div className={`questitem ${state.openingCreditLimit}`} id="slide1" ref={(el) => (scrollRef.current["openingCreditLimit"] = el)}>
                      <h1 className="que-title p-3 "> What was your overdraft opening credit limit ? </h1>
                      <OpeningCreditLimit
                        trigger={trigger}
                        validation={register}
                        getValues={getValues}
                        saveInputAns={saveInputAns}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.everIncresedCredLimit}`} id="slide2"
                      ref={(el) => (scrollRef.current["everincresedCredLimit"] = el)}>
                      <h1 className="que-title p-3 "> Was your credit limit ever increased?
                      </h1>
                      <EverIncreCredLimit
                        setEverIncreased={setEverIncreased}
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.bankActedIrresponsibly}`} id="slide3"
                      ref={(el) => (scrollRef.current["bankActedIrresponsibly"] = el)}>
                      <h1 className="que-title p-3 ">The bank acted irresponsibly by increasing my overdraft limit without
                        conducting a proper affordability assessment - do you agree ?
                      </h1>
                      <BankActedIrresponsibly
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li id="scrollfx">
                    <div className={`questitem ${state.highestCreditLimit}`} ref={(el) => (scrollRef.current["highestcreditLimit"] = el)}>
                      <h1 className="que-title p-3 "> What is the highest credit limit you had on your overdraft ? </h1>
                      <HighestCreditLimit
                      trigger={trigger}
                        validation={register}
                        getValues={getValues}
                        saveInputAns={saveInputAns}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.exceedCredLimit}`} id="slide3" ref={(el) => (scrollRef.current["exceedCredLimit"] = el)}>
                      <h1 className="que-title p-3 "> Did you ever exceed your credit limit?
                      </h1>
                      <ExceedCreditLimit
                        everIncreased={everIncreased}
                        setExceedCredit={setExceedCredit}
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.oftenExceedCredit}`} id="slide3" ref={(el) => (scrollRef.current["oftenexceedCredit"] = el)}>
                      <h1 className="que-title p-3 ">How oftern did you exceed you credit limit?
                      </h1>
                      <HowOftenExceedCredit
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.overLimit}`} id="always3" ref={(el) => (scrollRef.current["overlimit"] = el)}>
                      <h1 className="que-title p-3 ">Did you receive overlimit charges ?
                      </h1>
                      <OverLimitCharges
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="text-center " id="loader1" style={loader2 === "show" ? { display: "block" } : { display: "none " }}>
                <img height={50} src={loader_review2} alt />
              </div>
              <div style={loader2 === "hide" ? { display: "block" } : { display: "none " }}>
                <h2 className={`text-white mb-4 fs-4 qstitle drop-in  ${state.showSlideH3}`} id="qsTitle3">
                  <div className="d-flex">
                    <div className="pe-2"> <i className="bi bi-question-circle" /> </div>
                    <div>  You financial commitments when you took the Overdraft</div>
                  </div>
                </h2>
              </div>
              <div id="quetWraper3" className="questionnair-section form-style mb-4 drop-in" style={loader2 === "hide" ? { display: "block" } : { display: "none " }}>
                <ul className="p-0 m-0 questionist mb-4">
                  <li>
                    <div className={`questitem ${state.financialHardship}`} id="slide2" ref={(el) => (scrollRef.current["financialhardship"] = el)}>
                      <h1 className="que-title p-3 "> I have suffered financial hardship due to interest and charges being applied
                        to my account - do you agree ?
                      </h1>
                      <SufferedFinancialHardship
                        exceedCredit={exceedCredit}
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  {/* <li>
                    <div className={`questitem ${state.thinkBackbtn}`} ref={(el) => (scrollRef.current["thinkBackbtn"] = el)}>
                      <h1 className="que-title p-3 "> Think back to when you took the overdraft please answer the questions in this
                        section relating to that time:</h1>
                      <ThinkBack
                        backClick={previousSlideChange}
                        trigger={trigger}
                        getValues={getValues}
                        validation={register}
                        saveInputAns={saveInputAns}
                      />
                    </div>
                  </li> */}
                  <li id="scrollfx2body">
                    <div className={`questitem ${state.recieveAnyIncome}`} ref={(el) => (scrollRef.current["recieveanyincome"] = el)}>
                      <h1 className="que-title p-3 ">In the year when you took the overdraft did you receive any income ? </h1>
                      <RecieveAnyIncome
                        setRecieveIncomeback={setRecieveIncomeback}
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.monthlyIncome}`} ref={(el) => (scrollRef.current["monthlyincome"] = el)}>
                      <h1 className="que-title p-3 "> When you took out your overdraft, what was your monthly income
                      </h1>
                      <MonthlyIncome
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.dependents}`} ref={(el) => (scrollRef.current["dependents"] = el)}>
                      <h1 className="que-title p-3 ">How many dependents did you have at that time
                      </h1>
                      <Dependents
                        recieveIncomeback={recieveIncomeback}
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.wereAlreadyPaying}`} ref={(el) => (scrollRef.current["wereAlreadyPaying"] = el)}>
                      <h1 className="que-title p-3 ">When you <b>took out the overdraft</b>, were you already paying monthly for one or more of the following:
                      </h1>
                      <WereAlreadyPaying
                        onSelectAnswer={onSelectAnswer}
                        questArr={wereUalreadyPaying}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        checklistOptions={checklistOptions}
                        backClick={previousSlideChange}
                        setChecklistOptions={setChecklistOptions}
                      />
                    </div>
                  </li>
                  <li id="seperat3body">
                    <div className={`questitem ${state.incmAndExp}`} ref={(el) => (scrollRef.current["incmAndexp"] = el)}>
                      <h1 className="que-title p-3 ">My Monthly expenses
                      </h1>
                      <IncomeAndExpV2
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        questArr={wereUalreadyPaying}
                        saveSlide80={saveSlide80}
                        // scrollToElement={scrollToElement}
                        backClick={previousSlideChange}
                        checklistOptions={checklistOptions}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="text-center " id="loader1" style={loader3 === "show" ? { display: "block" } : { display: "none " }}>
                <img height={50} src={loader_review2} alt />
              </div>
              <div style={loader3 === "hide" ? { display: "block" } : { display: "none " }}>
                <h2 className={`text-white mb-4 fs-4 qstitle drop-in  ${state.showSlideH4}`} id="qsTitle4" >
                  <div className="d-flex">
                    <div className="pe-2"> <i className="bi bi-question-circle" /> </div>
                    <div>  Your financial commitments during the duration of the overdraft</div>
                  </div>
                </h2>
              </div>
              <div id="quetWraper4" className=" questionnair-section form-style mb-4" style={loader3 === "hide" ? { display: "block" } : { display: "none " }}>
                <ul className="p-0 m-0 questionist mb-4">
                  <li>
                    <div className={`questitem drop-in ${state.furtherBorrowing}`} ref={(el) => (scrollRef.current["furtherborrowing"] = el)}>
                      <h1 className="que-title p-3 ">Did you take out further borrowing so that you could manage your finances? Yes
                      </h1>
                      <FurtherBorrowing
                        onSelectAnswer={onSelectAnswer}
                        setCheckedOptions={setCheckedOptions}
                        checkedOptions={checkedOptions}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem drop-in ${state.struggledRepayments}`} ref={(el) => (scrollRef.current["struggledrepayments"] = el)}>
                      <h1 className="que-title p-3 "> Have you struggled to meet your essential repayments as result of being in your overdraft ? </h1>
                      <StruggledRepayments
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.maintainedCreditBalance}`} ref={(el) => (scrollRef.current["maintainedcreditbalance"] = el)}>
                      <h1 className="que-title p-3 "> I had not maintained a credit balance for a significant perion of time which ought to have made the bank aware of my financial difficulties - do you agree ?
                      </h1>
                      <MaintainedCreditBalance
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.stuckInOverdraft}`} ref={(el) => (scrollRef.current["stuckinoverdraft"] = el)}>
                      <h1 className="que-title p-3 ">  I was stuck in my overdraft and didn't see a positive balance
                      </h1>
                      <StuckInOverdraft
                        setStuckinOverdraftbck={setStuckinOverdraftbck}
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                  </li>
                  <li>
                    <div className={`questitem ${state.howStuckAffects}`} ref={(el) => (scrollRef.current["howstuckaffects"] = el)}>
                      <h1 className="que-title p-3 text-style">You've told us you were stuck in your overdraft as a result you didn't see a frequent positive balance- how did this affect you ?</h1>
                      <HowStuckAffects
                        saveInputAns={saveInputAns}
                        backClick={previousSlideChange}
                        stuckinOverdraftbck={stuckinOverdraftbck}
                        trigger={trigger}
                        validation={register}
                        getValues={getValues}
                      />
                    </div>
                  </li>
                </ul>
              </div>

            </form>
          </div>



        </div>
      </section>
      <Footer />
    </div>
    </>
  )
}

export default QuestionnaireV24