import React, { useState } from "react";
import TextField from "../../../UI/TextField";

const HowStuckAffects = ({ 
    validation,
     backClick, 
     saveInputAns,
     getValues }) => {
    const [error, setError] = useState("");

    const handleClick = async (e) => {
  
        const values = getValues();
        const reason = values['stuckAffects'].trim();
        if (reason == "" || reason.length < 3) {
            setError("Please enter");
        } else {
            setError("");
            saveInputAns(e);
        }
    };
    
    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
                <div className="row">
                    <div className="col-lg-8 mb-3">
                        <TextField
                            type="text"
                            className="form-control"
                            name="stuckAffects"
                            placeholder="Enter"
                            validation={validation({
                                required : true
                            })}
                        />
                        {error && (
                            <span className="error_msg">{error}</span>
                        )}
                    </div>
                    <div className="col-lg-4 mb-2">
                        <button
                            type="button"
                            name="howstuckaffects"
                            className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            onClick={handleClick}
                            data-quest-id={106}
                        >
                            Submit &#8594;
                        </button>
                    </div>
                </div>
                <p className="text-center bck-marwid">
                    <button
                        name="stuckinoverdraft"
                        className="btn btn-secondary backbtn1a"
                        value=""
                        type="button"
                        onClick={backClick}
                    >
                        Go Back
                    </button>
                </p>
            </div>
        </>
    );
};

export default HowStuckAffects;
