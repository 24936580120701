
const questinnare1 = {
  questionId: 31,
  question: "Income and Expenditure Details",
  question_sub: "Please confirm the following MONTHLY income and expenditure details as at the time of the car finance as accurately as possible:-",
  answers: [
    {
      label: "Select an amount range",
      value: ""
    },
    {
      label: "£100",
      value: "25"
    },
    {
      label: "£100 - £200",
      value: "26"
    },
    {
      label: "£200 - £300",
      value: "27"
    },
    {
      label: "£300 - £400",
      value: "28"
    },
    {
      label: "£400 - £500",
      value: "29"
    },
    {
      label: "£500 - £600",
      value: "30"
    },
    {
      label: "£600 - £700",
      value: "31"
    },
    {
      label: "£700 - £800",
      value: "32"
    },
    {
      label: "£800 - £900",
      value: "33"
    },
    {
      label: "£900 - £1000",
      value: "34"
    },
    {
      label: "£1000 - £1500",
      value: "35"
    },
    {
      label: "Over £1500",
      value: "36"
    },
  ]
};
const questinnare2 = {
  questionId: 36,
  //question : "Please select at least one that applies to your circumstances:",
  question: "Thanks for that, please confirm if any of these statements also accurately describe your situation.",
  answers: [
    {
      label: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
      value: "103"
    },
    {
      label: "The lender didn’t ask me any questions to confirm that I would be able to repay the loan in a sustainable way",
      value: "104"
    },
    {
      label: "I already had multiple other loans at the time I applied for this loan",
      value: "105"
    },
    {
      label: "I was in arrears with other lenders when I took the finance",
      value: "106"
    },
    {
      label: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
      value: "107"
    },
    {
      label: "I was in arrears with my household bills when I took the finance",
      value: "108"
    },
    {
      label: "My income changed between taking out and repaying the loan but the lender didn’t check",
      value: "109"
    },
    {
      label: "I regularly missed my monthly repayments",
      value: "110"
    },
    {
      label: "I was sometimes not managing to make the minimum monthly payment required on my credit card.",
      value: "215"
    },
    {
      label: "None of the above",
      value: "155"
    },
  ]
};
const questinnaire76MB = {
  questionId: 76,
  question: "Further details to be considered:",
  answers: [
    {
      label: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit.",
      value: "216"
    },
    {
      label: "I regularly used on-line betting/gambled, leading up to or while holding the credit.",
      value: "217"
    },
    {
      label: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit.",
      value: "218"
    },
    {
      label: "My overdraft was being used to fund my credit card payments.",
      value: "219"
    },
    {
      label: "I used my credit card to make cashpoint withdrawals.",
      value: "220"
    },
    {
      label: "I was borrowing off friends or family leading up to or while holding the credit.",
      value: "221"
    },
    {
      label: "I was close to, or exceeding my credit limit, and using the money for essential spending.",
      value: "222"
    },
    {
      label: "My income while holding the credit was not always reliable.",
      value: "223"
    },
    {
      label: "I was sometimes being charged late payment fees on my credit card.",
      value: "224"
    },
    {
      label: "None of the above",
      value: "225"
    },
  ]
};
const questinnaire76MB_2 = {
  questionId: 76,
  question: "At the time you secured your finance with MoneyBarn, which of the below statements accurately describe your situation",
  answers: [
    {
      label: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit.",
      value: "216"
    },
    {
      label: "I regularly used on-line betting/gambled, leading up to or while holding the credit.",
      value: "217"
    },
    {
      label: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit.",
      value: "218"
    },
    {
      label: "My overdraft was being used to fund my credit card payments.",
      value: "219"
    },
    {
      label: "I used my credit card to make cashpoint withdrawals.",
      value: "220"
    },
    {
      label: "I was borrowing off friends or family leading up to or while holding the credit.",
      value: "221"
    },
    {
      label: "I was close to, or exceeding my credit limit, and using the money for essential spending.",
      value: "222"
    },
    {
      label: "My income while holding the credit was not always reliable.",
      value: "223"
    },
    {
      label: "I was sometimes being charged late payment fees on my credit card.",
      value: "224"
    },
    {
      label: "None of the above",
      value: "225"
    },
  ]
};
const questinnare2MB = {
  questionId: 36,
  //question : "Please select at least one that applies to your circumstances:",
  
  question: "Thanks for that, please confirm if any of these statements also accurately describe your situation.",
  answers: [
    {
      label: "I had to take out additional lending to pay for the car finance to avoid it being repossessed",
      value: "103"
    },
    {
      label: "The lender didn’t ask me any questions to confirm that I would be able to repay the loan in a sustainable way",
      value: "104"
    },
    {
      label: "I already had multiple other loans at the time I applied for this loan",
      value: "105"
    },
    {
      label: "I was in arrears with other lenders when I took the finance",
      value: "106"
    },
    {
      label: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
      value: "107"
    },
    {
      label: "I was in arrears with my household bills when I took the finance",
      value: "108"
    },
    {
      label: "My income changed between taking out and repaying the loan but the lender didn’t check",
      value: "109"
    },
    {
      label: "I regularly missed my monthly repayments",
      value: "110"
    },
    {
      label: "I was sometimes not managing to make the minimum monthly payment required on my car finance.",
      value: "215"
    },
    {
      label: "None of the above",
      value: "155"
    },
  ]
};
const questinnaire76MB_4_2 = {
  questionId: 76,
  question: "At the time you secured your finance with MoneyBarn, which of the below statements accurately describe your situation",
  answers: [
    {
      label: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit.",
      value: "216"
    },
    {
      label: "I regularly used on-line betting/gambled, leading up to or while holding the credit.",
      value: "217"
    },
    {
      label: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit.",
      value: "218"
    },
    {
      label: "My overdraft was being used to fund my car finance payments.",
      value: "219"
    },
    {
      label: "I was borrowing off friends or family leading up to or while holding the credit.",
      value: "221"
    },
    {
      label: "My income while holding the credit was not always reliable.",
      value: "223"
    },
    {
      label: "I was sometimes being charged late payment fees on my car finance.",
      value: "224"
    },
    {
      label: "None of the above",
      value: "225"
    },
  ]
};

const questinnare2LS = {
  questionId: 36,
  //question : "Please select at least one that applies to your circumstances:",
  question: "Thanks for that, please confirm if any of these statements also accurately describe your situation.",
  answers: [
    {
      label: "I had to take out additional lending to pay for the loan",
      value: "128"
    },
    {
      label: "The lender didn’t ask me any questions to confirm that I would be able to repay the loan in a sustainable way",
      value: "104"
    },
    {
      label: "I already had multiple other loans at the time I applied for this loan",
      value: "105"
    },
    {
      label: "I was in arrears with other lenders when I took the loan",
      value: "129"
    },
    {
      label: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
      value: "107"
    },
    {
      label: "I was in arrears with my household bills when I took the loan",
      value: "130"
    },
    {
      label: "My income changed between taking out and repaying the loan but the lender didn’t check",
      value: "109"
    },
    {
      label: "I regularly missed my monthly repayments",
      value: "110"
    },
    {
      label: "None of the above",
      value: "155"
    },
  ]
};
const questinnare2Very = {
  questionId: 36,
  //question : "Please select at least one that applies to your circumstances:",
  question: "Further details to be considered:",
  answers: [
    {
      label: "I had to take out additional lending to pay for the credit",
      value: "156"
    },
    {
      label: "The lender didn’t ask me any questions to confirm that I would be able to repay the credit in a sustainable way",
      value: "157"
    },
    {
      label: "I already had multiple other borrowings at the time I applied for this credit",
      value: "158"
    },
    {
      label: "I was in arrears with other lenders when I took the credit",
      value: "159"
    },
    {
      label: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
      value: "107"
    },
    {
      label: "I was in arrears with my household bills when I took the credit",
      value: "160"
    },
    {
      label: "My income changed between taking out and repaying the credit but the lender didn’t check",
      value: "161"
    },
    {
      label: "I regularly missed my monthly repayments",
      value: "110"
    },
    {
      label: "I was sometimes not managing to make the minimum monthly payment required on my credit card.",
      value: "215"
    },
    {
      label: "None of the above",
      value: "155"
    },
  ]
};
const questinnare2VeryV2 = {
  questionId: 36,
  //question : "Please select at least one that applies to your circumstances:",
  question: "Thanks for that, please confirm if any of these statements also accurately describe your situation.",
  answers: [
    {
      label: "I had to take out additional lending to pay for the credit",
      value: "156"
    },
    {
      label: "The lender didn’t ask me any questions to confirm that I would be able to repay the credit in a sustainable way",
      value: "157"
    },
    {
      label: "I already had multiple other borrowings at the time I applied for this credit",
      value: "158"
    },
    {
      label: "I was in arrears with other lenders when I took the credit",
      value: "159"
    },
    {
      label: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
      value: "107"
    },
    {
      label: "I was in arrears with my household bills when I took the credit",
      value: "160"
    },
    {
      label: "My income changed between taking out and repaying the credit but the lender didn’t check",
      value: "161"
    },
    {
      label: "I regularly missed my monthly repayments",
      value: "110"
    },
    {
      label: "I was sometimes not managing to make the minimum monthly payment required on my credit card.",
      value: "215"
    },
    {
      label: "None of the above",
      value: "155"
    },
  ]
};
const questinnaire36Vanquis = {
  questionId: 36,
  //question : "Please select at least one that applies to your circumstances:",
  question: "Further details to be considered:",
  answers: [
    {
      label: "I had to take out additional lending to pay for the credit",
      value: "156"
    },
    {
      label: "The lender didn’t ask me any questions to confirm that I would be able to repay the credit in a sustainable way",
      value: "157"
    },
    {
      label: "I already had multiple other borrowings at the time I applied for this credit",
      value: "158"
    },
    {
      label: "I was in arrears with other lenders when I took the credit",
      value: "159"
    },
    {
      label: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
      value: "107"
    },
    {
      label: "I was in arrears with my household bills when I took the credit",
      value: "160"
    },
    {
      label: "My income changed between taking out and repaying the credit but the lender didn’t check",
      value: "161"
    },
    {
      label: "I regularly missed my monthly repayments",
      value: "110"
    },
    {
      label: "I was sometimes not managing to make the minimum monthly payment required on my credit card.",
      value: "215"
    },
    {
      label: "None of the above",
      value: "155"
    },
  ]
};

const questinnaire76Vanquis = {
  questionId: 76,
  question: "Thanks for that, please confirm if any of these statements also accurately describe your situation.",
  answers: [
    {
      label: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit.",
      value: "216"
    },
    {
      label: "I regularly used on-line betting/gambled, leading up to or while holding the credit.",
      value: "217"
    },
    {
      label: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit.",
      value: "218"
    },
    {
      label: "My overdraft was being used to fund my credit card payments.",
      value: "219"
    },
    {
      label: "I used my credit card to make cashpoint withdrawals.",
      value: "220"
    },
    {
      label: "I was borrowing off friends or family leading up to or while holding the credit.",
      value: "221"
    },
    {
      label: "I was close to, or exceeding my credit limit, and using the money for essential spending.",
      value: "222"
    },
    {
      label: "My income while holding the credit was not always reliable.",
      value: "223"
    },
    {
      label: "I was sometimes being charged late payment fees on my credit card.",
      value: "224"
    },
    {
      label: "None of the above",
      value: "225"
    },
  ]
};

const questinnaire36VanquisV2 = {
  questionId: 36,
  //question : "Please select at least one that applies to your circumstances:",
  question: "Thanks for that, please confirm if any of these statements also accurately describe your situation.",
  answers: [
    {
      label: "I had to take out additional lending to pay for the credit",
      value: "156"
    },
    {
      label: "The lender didn’t ask me any questions to confirm that I would be able to repay the credit in a sustainable way",
      value: "157"
    },
    {
      label: "I already had multiple other borrowings at the time I applied for this credit",
      value: "158"
    },
    {
      label: "I was in arrears with other lenders when I took the credit",
      value: "159"
    },
    {
      label: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
      value: "107"
    },
    {
      label: "I was in arrears with my household bills when I took the credit",
      value: "160"
    },
    {
      label: "My income changed between taking out and repaying the credit but the lender didn’t check",
      value: "161"
    },
    {
      label: "I regularly missed my monthly repayments",
      value: "110"
    },
    {
      label: "I was sometimes not managing to make the minimum monthly payment required on my credit card.",
      value: "215"
    },
    {
      label: "None of the above",
      value: "155"
    },
  ]
};

const questinnaire76VanquisV2 = {
  questionId: 76,
  question: "Further details to be considered:",
  answers: [
    {
      label: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit.",
      value: "216"
    },
    {
      label: "I regularly used on-line betting/gambled, leading up to or while holding the credit.",
      value: "217"
    },
    {
      label: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit.",
      value: "218"
    },
    {
      label: "My overdraft was being used to fund my credit card payments.",
      value: "219"
    },
    {
      label: "I used my credit card to make cashpoint withdrawals.",
      value: "220"
    },
    {
      label: "I was borrowing off friends or family leading up to or while holding the credit.",
      value: "221"
    },
    {
      label: "I was close to, or exceeding my credit limit, and using the money for essential spending.",
      value: "222"
    },
    {
      label: "My income while holding the credit was not always reliable.",
      value: "223"
    },
    {
      label: "I was sometimes being charged late payment fees on my credit card.",
      value: "224"
    },
    {
      label: "None of the above",
      value: "225"
    },
  ]
};

const questinnaire76s3FLP = {
  questionId: 76,
  question: "At the time you secured your finance with MoneyBarn, which of the below statements accurately describe your situation",
  answers: [
    {
      label: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit.",
      value: "216"
    },
    {
      label: "I regularly used on-line betting/gambled, leading up to or while holding the credit.",
      value: "217"
    },
    {
      label: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit.",
      value: "218"
    },
    {
      label: "My overdraft was being used to fund my credit card payments.",
      value: "219"
    },
    {
      label: "I used my credit card to make cashpoint withdrawals.",
      value: "220"
    },
    {
      label: "I was borrowing off friends or family leading up to or while holding the credit.",
      value: "221"
    },
    {
      label: "I was close to, or exceeding my credit limit, and using the money for essential spending.",
      value: "222"
    },
    {
      label: "My income while holding the credit was not always reliable.",
      value: "223"
    },
    {
      label: "I was sometimes being charged late payment fees on my credit card.",
      value: "224"
    },
    {
      label: "None of the above",
      value: "225"
    },
  ]
};

const wereUalreadyPaying = {
  questionId: 79,
  //question : "Please select at least one that applies to your circumstances:",
  question: "When you took out the card, were you already paying monthly for one or more of the following:",
  answers: [
    // {
    //   label: "Mortgage",
    //   value: "228"
    // },
    {
      label: "Mortgage / Rent",
      value: "229"
    },
    {
      label: "Utility Bills",
      value: "230"
    },
    {
      label: "Loans",
      value: "231"
    },
    {
      label: "Credit Cards",
      value: "232"
    },
    {
      label: "Car Finance",
      value: "233"
    },
    {
      label: "Store Cards",
      value: "234"
    },
    {
      label: "Other",
      value: "235"
    },
  ]
};

const furtherBorrows = {

  questionId: 102,
  answers: [
    {
      label: "Payday lending",
      value: "282"
    },
    {
      label: "Loans",
      value: "283"
    },
    {
      label: "Credit cards",
      value: "284"
    },
    {
      label: "Borrowing from friends and family",
      value: "285"
    },
  ]
};



const questinnare3 = {
  questionId: 15,
  question: "Is your finance with Money Barn still in place?",
  answers: [
    {
      label: "Yes",
      value: "37"
    },
    {
      label: "No",
      value: "38"
    }
  ]
};

const questinnare4 = {
  questionId: 16,
  question: "Do you still have this vehicle?",
  answers: [
    {
      label: "Yes",
      value: "39"
    },
    {
      label: "No",
      value: "40"
    }
  ]
};
const questinnare5 = {
  questionId: 17,
  question: "Did MoneyBarn check you had enough disposible income to afford the monthly repayments of the new finance agreement?",
  answers: [
    {
      label: "Yes",
      value: "41"
    },
    {
      label: "No",
      value: "42"
    }
  ]
};

const questinnare6 = {
  questionId: 18,
  question: "Your approximate average monthly income when your finance began?",
  answers: [
    {
      label: "Select an amount range",
      value: ""
    },
    {
      label: "Less than £100",
      value: "43"
    },
    {
      label: "Between £100 - £200",
      value: "44"
    },
    {
      label: "Between £200 - £300",
      value: "45"
    },
    {
      label: "Between £300 - £400",
      value: "46"
    },
    {
      label: "Between £400 - £500",
      value: "47"
    },
    {
      label: "Between £500 - £600",
      value: "48"
    },
    {
      label: "Between £600 - £700",
      value: "49"
    },
    {
      label: "Between £700 - £800",
      value: "50"
    },
    {
      label: "Between £800 - £900",
      value: "51"
    },
    {
      label: "Between £900 - £1000",
      value: "52"
    },
    {
      label: "Between £1000 - £1100",
      value: "53"
    },
    {
      label: "Between £1100 - £1200",
      value: "54"
    },
    {
      label: "Between £1200 - £1300",
      value: "55"
    },
    {
      label: "Between £1300 - £1400",
      value: "56"
    },
    {
      label: "Between £1400 - £1500",
      value: "57"
    },
    {
      label: "Between £1500 - £1600",
      value: "58"
    },
    {
      label: "Between £1600 - £1700",
      value: "59"
    },
    {
      label: "Between £1700 - £1800",
      value: "60"
    },
    {
      label: "Between £1800 - £1900",
      value: "61"
    }
  ]
};
const questinnare7 = {
  questionId: 19,
  question: "Approximately how much per month were you typically paying towards other credit commitments (loans and card accounts, etc)?",
  answers: [
    {
      label: "Select an amount range",
      value: ""
    },
    {
      label: "Less than £100",
      value: "62"
    },
    {
      label: "Between £100 - £200",
      value: "63"
    },
    {
      label: "Between £200 - £300",
      value: "64"
    },
    {
      label: "Between £300 - £400",
      value: "65"
    },
    {
      label: "Between £400 - £500",
      value: "66"
    },
    {
      label: "Between £500 - £600",
      value: "67"
    },
    {
      label: "Between £600 - £700",
      value: "68"
    },
    {
      label: "Between £700 - £800",
      value: "69"
    },
    {
      label: "Between £800 - £900",
      value: "70"
    },
    {
      label: "Between £900 - £1000",
      value: "71"
    },
    {
      label: "Between £1000 - £1100",
      value: "72"
    },
    {
      label: "Between £1100 - £1200",
      value: "73"
    },
    {
      label: "Between £1200 - £1300",
      value: "74"
    },
    {
      label: "Between £1300 - £1400",
      value: "75"
    },
    {
      label: "Between £1400 - £1500",
      value: "76"
    },
    {
      label: "Between £1500 - £1600",
      value: "77"
    },
    {
      label: "Between £1600 - £1700",
      value: "78"
    },
    {
      label: "Between £1700 - £1800",
      value: "79"
    },
    {
      label: "Between £1800 - £1900",
      value: "80"
    }
  ]
};

const questinnare8 = {
  questionId: 20,
  question: "When my finance agreement began, I was…",
  answers: [
    {
      label: "Regularly using ‘bad credit’ lending (payday, guarantor, doorstep and high-interest)",
      value: "81"
    },
    {
      label: "Using a lot of my income for gambling or online betting",
      value: "82"
    },
    {
      label: "Receiving a very low and unreliable income (e.g. due to job loss, state benefits, being a student,etc)",
      value: "83"
    },
    {
      label: "Managing my income and outgoings ok, but still believe the finance agreement was mis-sold to me",
      value: "84"
    }
  ]
};
const questinnare9 = {
  questionId: 21,
  question: "When the finance agreement began, were these things typically happening in your bank account? <br>	A) Direct Debits or Standing Order payments were bouncing and being rejected <br> B) Late payment or overlimit fees were being applied <br> C) Money spent was more than money received in any one month",
  answers: [
    {
      label: "Yes",
      value: "85"
    },
    {
      label: "No",
      value: "86"
    }
  ]
};

const questinnare10 = {
  questionId: 22,
  question: "Are you subject to or have you ever been subject to an Individual Voluntary Arrangement (IVA) or have you proposed an IVA yet to be approved or rejected by creditors?",
  answers: [
    {
      label: "Yes",
      value: "87"
    },
    {
      label: "No",
      value: "88"
    }
  ]
};

const questinnare11 = {
  questionId: 23,
  question: "Have ever been declared bankrupt; or currently subject to a bankruptcy petition?",
  answers: [
    {
      label: "Yes",
      value: "89"
    },
    {
      label: "No",
      value: "90"
    }
  ]
};

const questinnare12 = {
  questionId: 24,
  question: "Have you been subject to a debt relief order?",
  answers: [
    {
      label: "Yes",
      value: "91"
    },
    {
      label: "No",
      value: "92"
    }
  ]
};

const questinnare13 = {
  questionId: 25,
  question: "Do you have an adverse County Court Debt Judgment or Sequestration?",
  answers: [
    {
      label: "Yes",
      value: "93"
    },
    {
      label: "No",
      value: "94"
    }
  ]
};

const questinnare14 = {
  questionId: 10,
  question: "Registration",
  answers: []
};
const questinnare15 = {
  questionId: 11,
  question: "Keeper Date",
  answers: []
};
const questinnare16 = {
  questionId: 12,
  question: "Verify Vehicle",
  answers: []
};

const Salutation = [
  {
    label: "Select Title",
    value: ""
  },
  {
    label: "Mr.",
    value: "Mr"
  },
  {
    label: "Mrs.",
    value: "Mrs"
  },
  {
    label: "Ms.",
    value: "Ms"
  },
  {
    label: "Miss.",
    value: "Miss"
  },
  {
    label: "Dr.",
    value: "Dr"
  },
  {
    label: "Rev.",
    value: "Rev"
  },
  {
    label: "Dame.",
    value: "Dame"
  },
  {
    label: "Lady.",
    value: "Lady"
  },
  {
    label: "Sir.",
    value: "Sir"
  },
  {
    label: "Lord.",
    value: "Lord"
  },
];


const questinnare7Sub1 = {
  question: "Q7 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  questionId: 'sub1',
  answers: [
    {
      label: "Option-1",
      value: "Option-1"
    },
    {
      label: "Option-2",
      value: "Option-2"
    },
    {
      label: "Option-3",
      value: "Option-3"
    },
    {
      label: "Option-4",
      value: "Option-4"
    },
    {
      label: "Option-5",
      value: "Option-5"
    }
  ]
}
const questinnare7Sub2 = {
  question: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  questionId: 'sub2',
  answers: [
    {
      label: "Yes",
      value: "yes"
    },
    {
      label: "No",
      value: "no"
    }
  ]
}

const questinnare38 = {
  questionId: 38,
  question: "Number of consecutive months borrowed from Lending Stream?",
  answers: [
    {
      label: "Select Number of Months",
      value: ""
    },
    {
      label: "Just 1 month",
      value: "Just 1 month"
    },
    {
      label: "2",
      value: "2"
    },
    {
      label: "3",
      value: "3"
    },
    {
      label: "4",
      value: "4"
    },
    {
      label: "5",
      value: "5"
    },
    {
      label: "6",
      value: "6"
    },
    {
      label: "7",
      value: "7"
    },
    {
      label: "8",
      value: "8"
    },
    {
      label: "9",
      value: "9"
    },
    {
      label: "10",
      value: "10"
    },
    {
      label: "11 - 15",
      value: "11 - 15"
    },
    {
      label: "16 - 20",
      value: "16 - 20"
    },
    {
      label: "20 - 30",
      value: "20 - 30"
    },
    {
      label: "30 - 40",
      value: "30 - 40"
    },
    {
      label: "40 - 50",
      value: "40 - 50"
    },
    {
      label: "50 - 60",
      value: "50 - 60"
    },
    {
      label: "60 - 70",
      value: "60 - 70"
    },
    {
      label: "70 - 80",
      value: "70 - 80"
    },
    {
      label: "80 - 90",
      value: "80 - 90"
    },
  ]
};

const questinnare39 = {
  questionId: 39,
  question: "How much would you typically borrow?",
  answers: [
    {
      label: "Please Select",
      value: ""
    },
    {
      label: "£100",
      value: "100"
    },
    {
      label: "£200",
      value: "200"
    },
    {
      label: "£300",
      value: "300"
    },
    {
      label: "£400",
      value: "400"
    },
    {
      label: "£500",
      value: "500"
    },
    {
      label: "£600",
      value: "600"
    },
    {
      label: "£700",
      value: "700"
    },
    {
      label: "£800",
      value: "800"
    },
    {
      label: "£900",
      value: "900"
    },
    {
      label: "£1000",
      value: "100"
    },
    {
      label: "£1100",
      value: "1100"
    },
    {
      label: "£1200",
      value: "1200"
    },
    {
      label: "£1300",
      value: "1300"
    },
    {
      label: "£1400",
      value: "1400"
    },
    {
      label: "£1500",
      value: "1500"
    },
    {
      label: "£1500 and Above",
      value: "1500 and Above"
    },
    
  ]
};

const answerArray = {
  // 117: "Your Finance Agreement Number",
  // 118: "A Copy of Your Finance Agreement",
  // 119: "A Copy of the Initial Draft of the Finance Agreement",
  // 120: "A Statement of Account",
  // 123: "NA",
  124: "Loan Agreement number",
  125: "Any Documents Relating to your Loan agreement",
  120: "A Statement of Account",
  126: "Bank statements (one month before and after the start of your Loan(s)",
  127: "Arrears Letters",
  123: "NA",
};

const answerArrayLS = {
  124: "Loan Agreement number",
  125: "Any Documents Relating to your Loan agreement",
  120: "A Statement of Account",
  126: "Bank statements (one month before and after the start of your Loan(s)",
  127: "Arrears Letters",
  123: "NA",
};

const supportDocTypeArray = {
  117 :"FinanceAgreementNumber",
  118 :"FinanceAgreement",
  119 :"InitialDraftOfAgreement",
  120 :"StatementOfAccount",
  123 :"NoneOfTheAbove",
  124 :"LoanAgreementNumber",
  125 :"LoanAgreementDoc",
  126 :"BankStatment",
  127 :"ArrearsLetters"
};

const answerArrayVery = {
  124: "Credit Agreement number",
  125: "Any Documents Relating to your Credit agreement",
  120: "A Statement of Account",
  126: "Bank statements (one month before and after the start of your Credit(s)",
  127: "Arrears Letters",
  123: "NA",
};

const employment = {
  questionId: 2,
  question: "In $year, at the time you secured finance, what was your employment status?",
  answers: [
    {
      label: "Employed",
      value: "3"
    },
    {
      label: "Self-employed",
      value: "4"
    },
    {
      label: "Student",
      value: "5"
    },
    {
      label: "Retired",
      value: "6"
    }
  ]
};

const employmentV2 = {
  questionId: 2,
  question: "In $year, at the time you secured finance, what was your employment status?",
  answers: [
    {
      label: "Employed",
      value: "3"
    },
    {
      label: "Unemployed",
      value: "199"
    },
    {
      label: "Self-employed",
      value: "4"
    },
    {
      label: "Student",
      value: "5"
    },
    {
      label: "Retired",
      value: "6"
    }
  ]
};

const employmentVAQ3 = {
  questionId: 2,
  question: "In $year, at the time you secured finance, what was your employment status?",
  answers: [
    {
      label: "Employed",
      value: "3"
    },
    {
      label: "Unemployed",
      value: "199"
    },
    {
      label: "Self-employed",
      value: "4"
    },
    {
      label: "Student",
      value: "5"
    },
    {
      label: "Retired",
      value: "6"
    },
    {
      label: "Benefits main source of income",
      value: "200"
    },
    {
      label: "Part-time job",
      value: "201"
    },
    {
      label: "Temporary worker",
      value: "202"
    },
    {
      label: "Long Term Sick",
      value: "203"
    },
    {
      label: "Maternity",
      value: "204"
    },
    {
      label: "Rolling/Zero hours contract",
      value: "205"
    }
  ]
};
const employmentMB4_2 = {
  questionId: 2,
  question: "In $year, at the time you secured finance, what was your employment status?",
  answers: [
    {
      label: "Employed",
      value: "3"
    },
    {
      label: "Unemployed",
      value: "199"
    },
    {
      label: "Self-employed",
      value: "4"
    },
    {
      label: "Student",
      value: "5"
    },
    {
      label: "Retired",
      value: "6"
    },
    {
      label: "Benefits main source of income",
      value: "200"
    },
    {
      label: "Part-time job",
      value: "201"
    },
    {
      label: "Temporary worker",
      value: "202"
    },
    {
      label: "Long Term Sick",
      value: "203"
    },
    {
      label: "Maternity",
      value: "204"
    },
    {
      label: "Rolling/Zero hours contract",
      value: "205"
    },
    {
      label: "Not Sure",
      value: "195"
    }
  ]
};

const questionnaire50 = {
  questionId: 50,
  question: "Which brand would you like to register a complaint with?",
  answers: [
    {
      label: "Very",
      value: "133"
    },
    {
      label: "Shop Direct",
      value: "134"
    },
    {
      label: "Littlewoods",
      value: "135"
    }
  ]
};

const questionnaire59 = {
  questionId: 59,
  questions: [
    {
      label: "Barclay Card",
      id:'201',
      field: "barclay_card"
    },
    {
      label: "Visa",
      id:'202',
      field: "visa"
    },
    {
      label: "High St Bank Loan / Card",
      id:'3',
      field: "high_st_bank_loan_card"
    },
    {
      label: "Lendable",
      id:'4',
      field: "lendable"
    },
    {
      label: "118 Money",
      id:'5',
      field: "118_money"
    },
    {
      label: "Lendingstream",
      id:'6',
      field: "lendingstream"
    },
    {
      label: "Vanquis",
      id:'7',
      field: "vanquis"
    },
    {
      label: "Capital One",
      id:'8',
      field: "capital_one"
    },
    {
      label: "Bamboo",
      id:'9',
      field: "bamboo"
    },
    {
      label: "Creation Finance",
      id:'10',
      field: "creation_finance"
    },
    {
      label: "Everyday Loans",
      id:'11',
      field: "everyday_loans"
    },
    {
      label: "George Banko",
      id:'12',
      field: "george_banko"
    },
    {
      label: "Likeley Loans",
      id:'13',
      field: "likeley_loans"
    },
    {
      label: "Aqua",
      id:'14',
      field: "aqua"
    },
    {
      label: "Marbles",
      id:'15',
      field: "marbles"
    },


    // {
    //   label: "Very",
    //   id:'16',
    //   field: "very"
    // },
    // {
    //   label: "Littlewoods",
    //   id:'17',
    //   field: "littlewoods"
    // },
    // {
    //   label: "Shop Direct",
    //   id:'18',
    //   field: "shop_direct"
    // },


    {
      label: "Drafty",
      id:'19',
      field: "drafty"
    },
    {
      label: "Luma",
      id:'20',
      field: "luma"
    },
    {
      label: "Jacamo",
      id:'21',
      field: "jacamo"
    },
    {
      label: "Simply Be",
      id:'22',
      field: "simply_be"
    },
    {
      label: "Other",
      id:'23',
      field: "other"
    }
  ]
};
const questionnaire60 = {
  questionId: 60,
  questions: [
    {
      label: "Groceries / Supermarket",
      id:'101',
      field: "groceries_supermarket"
    },
    {
      label: "Fuel",
      id:'102',
      field: "fuel"
    },
    {
      label: "Clothing",
      id:'103',
      field: "clothing"
    },
    {
      label: "Bills / Utilities",
      id:'104',
      field: "bills_utilities"
    },
    {
      label: "Takeaway",
      id:'105',
      field: "takeaway"
    },
    {
      label: "Pub / Nights out",
      id:'106',
      field: "pub_nights_out"
    },
    {
      label: "Betting",
      id:'107',
      field: "betting"
    },
    {
      label: "Cash withdrawals",
      id:'108',
      field: "cash_withdrawals"
    },
    {
      label: "Child maintenance",
      id:'109',
      field: "child_maintenance"
    },
    {
      label: "Mobile phone",
      id:'110',
      field: "mobile_phone"
    },
    {
      label: "Online shopping",
      id:'111',
      field: "online_shopping"
    },
    {
      label: "Coffee / Tea",
      id:'112',
      field: "coffee_tea"
    },
    {
      label: "Holiday payments",
      id:'113',
      field: "holiday_payments"
    },
    {
      label: "Cigarettes / E-cigs",
      id:'114',
      field: "cigarettes_e-cigs"
    },
    {
      label: "Money owed to Family or Friends",
      id:'115',
      field: "money_owed_to_family_or_friends"
    }
  ]
};
const SalutationVAQUB4 = [
  {
    label: "Title",
    value: ""
  },
  {
    label: "Mr.",
    value: "Mr"
  },
  {
    label: "Mrs.",
    value: "Mrs"
  },
  {
    label: "Ms.",
    value: "Ms"
  },
  {
    label: "Miss.",
    value: "Miss"
  },
  {
    label: "Dr.",
    value: "Dr"
  },
  {
    label: "Rev.",
    value: "Rev"
  },
  {
    label: "Dame.",
    value: "Dame"
  },
  {
    label: "Lady.",
    value: "Lady"
  },
  {
    label: "Sir.",
    value: "Sir"
  },
  {
    label: "Lord.",
    value: "Lord"
  },
];

export const wereUalreadyPayingNew = {
  questionId: 79,
  //question : "Please select at least one that applies to your circumstances:",
  question: "When you took out the card, were you already paying monthly for one or more of the following:",
  answers: [
    {
      label: "Mortgage/Rent",
      value: "228_229"
    },
    {
      label: "Utility Bills",
      value: "230"
    },
    {
      label: "Loans",
      value: "231"
    },
    {
      label: "Credit Cards",
      value: "232"
    },
    {
      label: "Car Finance",
      value: "233"
    },
    {
      label: "Store Cards",
      value: "234"
    },
    {
      label: "Other",
      value: "235"
    },
  ]
};
const questinnaire36VAQV5 = {
  questionId: 36,
  //question : "Please select at least one that applies to your circumstances:",
  question: "Further details to be considered:",
  answers: [
    {
      label: "I had to take out additional lending to pay for the credit",
      value: "156"
    },
    {
      label: "The lender didn’t ask me any questions to confirm that I would be able to repay the credit in a sustainable way",
      value: "157"
    },
    {
      label: "I already had multiple other borrowings at the time I applied for this credit",
      value: "158"
    },
    {
      label: "I was in arrears with other lenders when I took the credit",
      value: "159"
    },
    {
      label: "I was struggling to pay essentials (rent, bills, food) because of my repayment",
      value: "107"
    },
    {
      label: "I was in arrears with my household bills when I took the credit",
      value: "160"
    },
    {
      label: "My income changed between taking out and repaying the credit but the lender didn’t check",
      value: "161"
    },
    {
      label: "I regularly missed my monthly repayments",
      value: "110"
    },
    {
      label: "I was sometimes not managing to make the minimum monthly payment required on my credit card.",
      value: "215"
    },
    // {
    //   label: "None of the above",
    //   value: "155"
    // },
  ]
};

const questinnaire76VAQ5= {
  questionId: 76,
  question: "Thanks for that, please confirm if any of these statements also accurately describe your situation.",
  answers: [
    {
      label: "I borrowed from high-cost credit lenders e.g. payday loans leading up to or while holding the credit.",
      value: "216"
    },
    {
      label: "I regularly used on-line betting/gambled, leading up to or while holding the credit.",
      value: "217"
    },
    {
      label: "I was reliant on my overdraft to make ends meet leading up to or while holding the credit.",
      value: "218"
    },
    {
      label: "My overdraft was being used to fund my credit card payments.",
      value: "219"
    },
    {
      label: "I used my credit card to make cashpoint withdrawals.",
      value: "220"
    },
    {
      label: "I was borrowing off friends or family leading up to or while holding the credit.",
      value: "221"
    },
    {
      label: "I was close to, or exceeding my credit limit, and using the money for essential spending.",
      value: "222"
    },
    {
      label: "My income while holding the credit was not always reliable.",
      value: "223"
    },
    {
      label: "I was sometimes being charged late payment fees on my credit card.",
      value: "224"
    },
    {
      label: "None of the above",
      value: "225"
    },
  ]
};



export {
  questinnare1,
  questinnare2,
  questinnare3,
  questinnare4,
  questinnare5,
  questinnare6,
  questinnare7,
  questinnare8,
  questinnare9,
  questinnare10,
  questinnare11,
  questinnare12,
  questinnare13,
  Salutation,
  SalutationVAQUB4,
  questinnare7Sub1,
  questinnare7Sub2,
  questinnaire76MB,
  questinnaire76MB_2,
  questinnaire76MB_4_2,
  questinnare14,
  questinnare15,
  questinnare16,
  questinnare38,
  questinnare39,
  answerArray,
  answerArrayLS,
  supportDocTypeArray,
  employment,
  questinnare2MB,
  questinnare2LS,
  questinnare2Very,
  questinnare2VeryV2,
  questinnaire36Vanquis,
  questinnaire76Vanquis,
  questinnaire36VanquisV2,
  questinnaire76VanquisV2,
  questionnaire50,
  answerArrayVery,
  questionnaire59,
  questionnaire60,
  employmentV2,
  employmentVAQ3,
  employmentMB4_2,
  questinnaire76s3FLP,
  wereUalreadyPaying,
  questinnaire36VAQV5,
  questinnaire76VAQ5,
  furtherBorrows
};


