import { useRef, useState, useContext, useReducer, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { useSignature } from "../../../../Hooks/useSignature";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import ErrorMessage from "./ErrorMessage";
import LOA from '../../../../assets/pdf/LOA_Overdraft.pdf';
import TOB from '../../../../assets/pdf/Business_Terms_TMS.pdf';
import DECL from '../../../../assets/pdf/Overdraft_Declaration.pdf';
import VQ from '../../../../assets/pdf/Overdraft-Questionnaire.pdf';
import DSAR from '../../../../assets/pdf/DSAR_LOA_OVERDRAFT.pdf';

const Sign = ({
  basicDetailsValidation,
  openPrevPopup,
  setSignatureDataURL,
}) => {
  const initialState = {
    isAgreed: false,
    isCanvasEmpty: false,
    isValid: false,
  };
  const signatureReducer = (state, action) => {
    switch (action.type) {
      case "signPadChange": {
        return {
          ...state,
          isCanvasEmpty: action.payload.value,
        };
      }
      case "validSignature": {
        return {
          ...state,
          isValid: action.payload.value,
        };
      }
      default:
        return state;
    }
  };
  const { phoneValidation } = usePhoneValidation();
  const { errors, register, setError, getValues } = useFormContext();
  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const [isDisable,setIsDisable] = useState(false)
  const signatureWrapRef = useRef(null);
  const phoneNumber = getValues("txtPhone");

  const validatePhoneNumber = async () => {
    console.log("phoneNumber", phoneNumber);
    const getPhoneValidation = await phoneValidation(phoneNumber);
    if (getPhoneValidation.data.status_code === 0) {
      setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Working Phone Number",
      });
      document.getElementById("txtPhone").focus();
      return 0;
    } else if (getPhoneValidation.data.status_code === 2) {
      setError("txtPhone", {
        type: "manual",
        message: "Phone Number Already Exist",
      });
      document.getElementById("txtPhone").focus();

      return 0;
    } else if (getPhoneValidation.data.status_code === 3) {
      setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
      document.getElementById("txtPhone").focus();

      return 0;
    } else {
      return 1;
    }
  };
  const handleClick = async (e) => {
    const validcheck = await basicDetailsValidation();

    if (validcheck) {
      try {
        e.preventDefault();
        const signPad = signPadRef.current;
        if (signPad.isEmpty()) {
          return dispatch({ type: "signPadChange", payload: { value: true } });
        }
      
        const rpdata = signPad.toData();
        var signLength = 0;
        for (var i = 0; i < rpdata.length; i++) {
          signLength += Object.keys(rpdata[i]).length;
        }
        if (signLength > 13) {
          const validatePhone = await validatePhoneNumber();
          if (validatePhone) {
            openPrevPopup();
            const canvasDataURL = signPadRef.current
              .getCanvas()
              .toDataURL("image/png");
            setSignatureDataURL(canvasDataURL);
          }
        } else {
          return dispatch({ type: "validSignature", payload: { value: true } });
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };

  return (
    <>
      <div className="sign-sec">
        <h5 className=" mb-3 mt-4">
          Please sign your signature in the box below to confirm you understand
          the above
        </h5>
        <div className="signbox" ref={signatureWrapRef}>
          <SignatureCanvas
            canvasProps={{
              id: "myID",
              width: signatureWrapWidth,
              height: 200,
            }}
            ref={signPadRef}
            onEnd={() => {
              if (!signPadRef.current.isEmpty()) {
                dispatch({
                  type: "signPadChange",
                  payload: { value: false },
                });
                dispatch({
                  type: "validSignature",
                  payload: { value: false },
                });
              }
            }}
          />
        </div>
        <div className="">
        {state?.isCanvasEmpty && (
          <ErrorMessage errMessage="Signature is required!" />
        )}
        {state?.isValid && <ErrorMessage errMessage="Draw valid signature!" />}
        </div>

      </div>
       <div className="d-flex justify-content-between clearfixxx">
          <input
            type="button"
            id=""
            name=""
            className="clearbutton"
            value="Clear"
            onClick={clearSign}
          />

          <button
            className=" btn-comm2 w-50 mb-lg-0 mb-md-0 mb-sm-0 mb-3 z-0"
            id="formSubmitBtn"
            name="formSubmitBtn"
            type="button"
            onClick={handleClick}
            disabled={isDisable}
          >
            <span>Start</span>
          </button>
        </div>
        <div className="row mt-2 pdf-doc-links">
          <div className="col-md-5">
            <ul className="m-0 p-0 pdflink">
              <li>
                <a href={LOA} target="_blank">
                  <i className="bi bi-arrow-up-right-square" /> Letter of
                  Authority
                </a>
              </li>
              <li>
                <a href={TOB} target="_blank">
                  <i className="bi bi-arrow-up-right-square" /> Terms of Business Agreement
                </a>
              </li>
              <li>
                <a href={DSAR} target="_blank">
                  <i className="bi bi-arrow-up-right-square" /> DSAR Letter of Authority
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-7">
            <ul className="m-0 p-0 mb-2 pdflink">
              <li>
                <a href={VQ} target="_blank">
                  <i className="bi bi-arrow-up-right-square" /> Questionnaire
                </a>
              </li>
              <li>
                <a href={DECL} target="_blank">
                  <i className="bi bi-arrow-up-right-square" /> Declaration Form
                </a>
              </li>
            </ul>
          </div>
        </div>
        
    </>
  );
};

export default Sign;
