import React, {
    useEffect,
    useState,
    useReducer,
    useRef,
    useContext,
} from "react";
import { useForm } from "react-hook-form";
import Loader from "../Includes/Layouts/QuestionaireVaqV5/Loader";
import "../../assets/QuestionaireVaqV5/css/customVaqV5.scss";
import "../../assets/QuestionaireVaqV5/css/questionnaireVaqV5.scss";
import TMSLOGO from "../../assets/QuestionaireVaqV5/img/logo-white.png";
import FurtherDetails from "./../Includes/Layouts/QuestionaireVaqV5/FurtherDetails";
import ConfirmSituation from "./../Includes/Layouts/QuestionaireVaqV5/ConfirmSituation";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { FormData } from "../../Utility/FormData";
import { userInfo } from "../../Hooks/userInfo";
import { useHistory } from "react-router-dom";
import { MonthNumberArray } from "../../Constants/Constants";
import { questinnare1, questinnaire36Vanquis, answerArray, supportDocTypeArray, wereUalreadyPaying, questinnaire76VAQ5 ,questinnaire36VAQV5 } from "../../Constants/Questions";
import Footer from "../Includes/Layouts/VAQ_UB_5/Footer";
import PrevAddressPopup from "../Includes/Layouts/VAQ_UB_5/PrevAddressPopup";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import Banks from "../Includes/Layouts/QuestionaireVaqV5/Banks";
import { obLender } from "../../Hooks/useOblender";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";

const initialState = {
    showSlide3: "show",
    showSlide3a: "show",
    showSlide1: "hide",
    showSlide1a: "hide",
    showSlide2: "hide",
    showSlide2a: "hide",
};
const FormReducer = (state, action) => {
    switch (action.type) {
        case "showSlide": {

          if (action.payload.clickedSlide.slide == "banks") {
            return {
              ...state,
              showSlide3a: "hide",
              // showSlide34a: "show",
              showSlide1: "show",
              showSlide1a: "show"
            };
          } else if(action.payload.clickedSlide.slide == "btn_qest_36") {
              GtmDataLayer({
                question: "applicable statements",
              });
                return {
                    ...state,
                    showSlide1: "show",
                    showSlide1a: "hide",
                    showSlide2: "show",
                    showSlide2a: "show",
                };
            } else {
                return {
                    ...state,
                };
            }
        }

        case "previousSlides": {

          if (action.payload.previousSlide.slide == "banks") {
            return {
              ...state,
              showSlide1: "hide",
              showSlide3: "show",
              showSlide3a: "show",
              showSlide1a: "hide"
            };
          } else if(action.payload.previousSlide.slide == "btn_qest_36") {
              return {
                ...state,
                showSlide2a: "hide",
                showSlide2: "hide",
                showSlide1: "show",
                showSlide1a: "show",
              };
            } 
        }
        default:
            return state;
    }
};
const QuestionaireVaqV5 = () => {
    const {
        register,
        errors,
        trigger,
        setError,
        clearErrors,
        getValues,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });

    const [showLoad, setShowLoad] = useState("");
    const [state, dispatch] = useReducer(FormReducer, initialState);
    const splitForm = useRef(null);
    const scrollRef = useRef([]);
    const [optionSelected] = useState([]);
    const [SelectedOptions] = useState([]);
    const [clickedSlide, setClickedSlide] = useState([]);
    const [questionCounter, setQuestionCounter] = useState(0);
    const history = useHistory();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const query_string = localStorage.getItem("querystring");
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const title =localStorage.getItem("title");
    const { setFormData } = FormData();
    const [splitName, setSplitName] = useState();
    const { getUserInfo } = userInfo();
    const { isLoading, SaveQuestionnaire } = useQuestionnaire();
    const [showUserName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState();
    const [splitNameForSkip, setSplitNameForSkip] = useState();
    const [supportDocType, setSupportDocType] = useState("");
    const [previousSlide, setPreviousSlide] = useState([]);
    const [showPrevAddressPopup, setShowPrevAddressPopup] = useState(false);
    const surnameTitles = ["Mrs", "Ms", "Miss", "Dame", "Lady"];
    const [showError36, setshowError36] = useState("hide");
    const [showError76, setshowError76] = useState("hide");
    const [stickyBttn, setStickyBttn] = useState("hide");
    const [lenderList, setLenderList] = useState([]);
    const { getLenderlist } = obLender();
    const [SelectBank, setSelectBank] = useState([]);
    const [lenderName, setLenderName] = useState("");
    const { skippedLeads } = useSkippedLeads();
    const [sortCode, setSortCode] = useState(false);
    const [accNum, setAccNum] = useState(false);
    const [BankValidationMsg, setBankValidationMsg] = useState("");
    const [shareInfoPopUp, setShareInfoPopUp] = useState("hide");

    const getUuid = () => {
        const visitorData = visitorParameters.visitor_parameters;
        if (visitorData != "") {
            var uuid = visitorData.uuid;
        } else {
            const query = new URLSearchParams(window.location.search);
            const query_uuid = query.get("uuid");
            const local_storage_uuid = localStorage.getItem("uuid");
            var uuid = query_uuid ? query_uuid : local_storage_uuid;
        }
        return uuid;
    };
    const uuid = getUuid();

    useEffect(() => {

        if (formdata === undefined || formdata === null || formdata == "") {
            (async () => {
                const name = localStorage.getItem("UcFname");
                const response = await getUserInfo(uuid);
                const set_formdata = setFormData(response);
                let dataLength = response.data.response.length;
                if (dataLength > 0) {
                    const pageName = response.data.response[0].page_name
                        ? response.data.response[0].page_name
                        : " ";
                    setSplitName(pageName);
                    if (
                        localStorage.getItem("split_name") != undefined &&
                        localStorage.getItem("split_name") != undefined
                    ) {
                        setSplitNameForSkip(localStorage.getItem("split_name"));
                    } else {
                        setSplitNameForSkip(pageName);
                    }
                    const userName = response.data.response[0].first_name
                        ? response.data.response[0].first_name +
                        " " +
                        response.data.response[0].last_name
                        : " ";
                    var userDobN = response.data.response[0].user_dob.split("/");
                    var DobDayF = userDobN[0];
                    var DobMonthF = userDobN[1];
                    var DobYearF = userDobN[2];
                    // setSupportDocType(
                    //     response.data.response[0]?.questionnaire[42]["answerId"]
                    // );

                    setUserEmail(response.data.response[0].email);
                    const timer = setTimeout(() => {
                        setShowLoad("hide");
                        setShowPrevAddressPopup(true);
                    }, 3000);
                }
            })();
        } else {
            const name = localStorage.getItem("UcFname");
            const pageName = formdata.page_name;
            setSplitName(pageName);
            if (
                localStorage.getItem("split_name") != undefined &&
                localStorage.getItem("split_name") != undefined
            ) {
                setSplitNameForSkip(localStorage.getItem("split_name"));
            } else {
                setSplitNameForSkip(pageName);
            }
            const userName = formdata.txtFName + " " + formdata.txtLName;
            setUserName(name);
            const dobMonth = formdata.DobMonth
                ? MonthNumberArray[formdata.DobMonth]
                : "";

            const timer = setTimeout(() => {
                setShowLoad("hide");
                setShowPrevAddressPopup(true);
            }, 3000);
            setUserEmail(formdata.txtEmail);
        }
    }, [splitName]);

    useEffect(() => {
        if (clickedSlide) {
            dispatch({ type: "showSlide", payload: { clickedSlide } });
            scrollToElement(clickedSlide.slide);
            setTickToElement(clickedSlide);
            setQuestionCounter(questionCounter + 1);
        }
    }, [clickedSlide]);

    const setTickToElement = (element) => {
        console.log(element);
        if (element.slide == "question_61") {
            scrollRef.current["btn_qest_48"]?.classList.add("active");
        } else {
            scrollRef.current[element.slide]?.classList.add("active");
        }
        scrollRef.current[element.slide]
            ?.querySelector(".question-content")
            .classList.add("hide");
        // scrollRef.current[element]?.querySelector(".tipbox").classList.add("hide");
    };

    const scrollToElement = (element) => {
        scrollRef.current[element]?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "center",
        });
    };

    const previousSlideChange = (e) => {
        setQuestionCounter(questionCounter - 1);
        setPreviousSlide({ slide: e.target.getAttribute("name") });
      };

      useEffect(() => {
        if (previousSlide) {
          dispatch({ type: "previousSlides", payload: { previousSlide } });
          scrollToElement(previousSlide.slide);
          // setTimeout(() => {
          //   window.scrollTo(0,0);
          // }, 50);
          slideBack(previousSlide);
          // setQuestionCounter(questionCounter-1);
        }
      }, [previousSlide]);

      const slideBack = (element) => {
        scrollRef.current[element.slide]?.classList.remove("active");
    
        scrollRef.current[element.slide]
          ?.querySelector(".question-content")
          .classList.remove("hide");
      };

    const storeQuestionnire = async (question_data) => {
        const response = await SaveQuestionnaire(
            visitorParameters.visitor_parameters,
            question_data,
            "question_store",
            visitorParameters.data,
            query_string,
            formdata
        );
    };

    const onSelectAnswer = async (e) => {
        let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        let anslabel = e.target.getAttribute("data-ans-label");
        const valuesreg = getValues();
        let flage = false;
        let question_data = {};

        if (e.target.name == "btn_qest_76") {
          GtmDataLayer({
            question: "situation statements",
          });
            setClickedSlide({ slide: "btn_qest_76" });
            if (selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: null,
                    answer_text: anslabel,
                    input_answer: SelectedOptions,
                };
                redirectToPreview();

            }
        } else if (e.target.name == "btn_qest_36") {
            setClickedSlide({ slide: "btn_qest_36" });
            if (selectedQuest) {
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: null,
                    answer_text: anslabel,
                    input_answer: optionSelected,
                };
            }
        } else {
            if (selectedAns && selectedQuest) {
                // totalQuestArr.push(selectedQuest);
                setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
                flage = true;
                question_data = {
                    question_id: selectedQuest,
                    option_id: selectedAns,
                    answer_text: null,
                    input_answer: "",
                };
            }
        }
        if (flage) {
            storeQuestionnire(question_data);
            let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (_existingEntries == null) _existingEntries = [];
            let existingIndex = _existingEntries.findIndex(
                (entry) => entry.question_id === question_data.question_id
            );
            if (existingIndex !== -1) {
                _existingEntries.splice(existingIndex, 1);
            }
            _existingEntries.push(question_data);
            localStorage.setItem("questionData", JSON.stringify(_existingEntries));
        }
    };

    const redirectToPreview = async () => {
      history.push(
        "/MeritPage_Vaq_V5?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    };

    const handleClose = () => {
      setShowPrevAddressPopup(false);
      showStickyBtn(true);
    };
  
    const showStickyBtn = (isPopup) => {
        if (isPopup === true) {
              setStickyBttn("show");
        }
    };

    const openPrevPopup = async () => {
        setShowPrevAddressPopup(true);
    };

    const MobBtnClick = async (e) => {
      if (e.target.name === "banks") {
        let LengthselbankCount = SelectBank.length;
        if (LengthselbankCount < 1 || LengthselbankCount == "") {
          setBankValidationMsg("Please select your Bank");
          
      } else {
          // onSelectAnswer(e);
          setShareInfoPopUp("show");
          saveInputAns(e);
      }

      const otherLender = document.getElementById('otherlenders').value;

      } else if(e.target.name === "btn_qest_36") {
        let selectedCount = optionSelected.length;
        if (selectedCount == 0) {
          setshowError36("show");
          scrollToElement("further_details_first");
      } else {
          onSelectAnswer(e);
      }
      } else {
      let selectedCount = SelectedOptions.length;
      if (selectedCount == 0) {   
        setshowError76("show");
        scrollToElement("further_details_second");
      } else {
        onSelectAnswer(e);
      }
      }
  };


    // bank lenders
    const getLender = async () => {
      try {
        const response = await getLenderlist();
        const dataLength = response.data.providers.length;
  
        if (dataLength > 0) {
          setLenderList(response.data.providers);
        }
      } catch (error) {
        console.error("Error fetching lender list:", error);
      }
    };
    useEffect(() => {
      getLender();
    }, []);
     

    const saveInputAns = async (e) => {
      let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
      const valuesreg = getValues();
      let flage = false;
      let question_data = {};
      let bud_lender = SelectBank.value;
      let otherlenderValue = valuesreg["otherlenders"];
      let otherlender = otherlenderValue.trim();

      if (e.target.name == "banks") {
        GtmDataLayer({
          question: "banking partner",
        });
        // setClickedSlide({ slide: "banks" });
        if(bud_lender !="others"){
          flage = true;
          question_data = {
              question_id: selectedQuest,
              option_id: null,
              answer_text: bud_lender,
              input_answer: bud_lender,
          };
        } else {
          flage = true;
          question_data = {
              question_id: selectedQuest,
              option_id: null,
              answer_text: bud_lender,
              input_answer: otherlender,
          };
        }

      } else if (e.target.name == "bankOptions" && e.target.value) {
        GtmDataLayer({
          question: "banking partner",
        });
        // setClickedSlide({ slide: "banks" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: e.target.value,
          input_answer: e.target.value,
        };
      } else if (e.target.name == "sortcode") {
        // setClickedSlide({ slide: "sortcode" });
        setClickedSlide({ slide: "banks" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: sortCode,
        };
      } else if (e.target.name == "bank_acc_no") {
        // setClickedSlide({ slide: "bank_acc_no" });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: accNum,
        };
      }

      if (flage) {
        storeQuestionnire(question_data);
        // var existingEntries = JSON.parse(localStorage.getItem("questionData"));
        // if (existingEntries == null) existingEntries = [];
        // localStorage.setItem(
        //   "currentquestionData",
        //   JSON.stringify(question_data)
        // );
        // existingEntries.push(question_data);
        // localStorage.setItem("questionData", JSON.stringify(existingEntries));
  
        let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (_existingEntries == null) _existingEntries = [];
        let existingIndex = _existingEntries.findIndex(
          (entry) => entry.question_id === question_data.question_id
        );
        if (existingIndex !== -1) {
          _existingEntries.splice(existingIndex, 1);
        }
        _existingEntries.push(question_data);
        localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  
      }

    };


    const SkippedLeads = async (type, flag = true) => {
      const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);
      if (flag && skippedLeadsResponse.data.status == "Success") {
        // redirectToPreview();
      }
    };


    const shareInfoSave = async (val) => {
      GtmDataLayer({
        question: "account details",
      });
      let selectedAns = val;
      let selectedQuest = 107;
      let flage = true;
      let question_data = {};
    
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: null,
          input_answer: null,
        };
  
      if (flage) {
        storeQuestionnire(question_data);
  
        let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (_existingEntries == null) _existingEntries = [];
        let existingIndex = _existingEntries.findIndex(
          (entry) => entry.question_id === question_data.question_id
        );
        if (existingIndex !== -1) {
          _existingEntries.splice(existingIndex, 1);
        }
        _existingEntries.push(question_data);
        localStorage.setItem("questionData", JSON.stringify(_existingEntries));
      }
    };
      
    return (
      <>
        <GetVisitorsParams />

        <div className="QuestVaqV5A">
          <section className="banner qs-section">
            <div className="container">
              <div>
                <div className="brand">
                  <div className="logo pt-3 text-center">
                    <a>
                      {" "}
                      <img src={TMSLOGO} height="60" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-5 mb-5">
              <Loader showLoad={showLoad} />

              <div
                id="quetWraper"
                className="questionnair-section form-style mb-5"
                style={
                  showLoad === "hide"
                    ? { display: "block" }
                    : { display: "none " }
                }
              >
                <h1 className="que-title text-center ques_titleTag text-white pt-0 p-3">
                We prepare your claim application with statements detailing the struggles and hardships you had faced, when you secured your finance with Vanquis.
                </h1>
                {/* <h2 className="que-title-sub text-center ques_titleTag text-white pt-0 p-3">Please select all applicable answers that accurately reflect your experiences.</h2> */}

                {/* <div
                  id="rangequestions"
                  className="qrange d-flex align-items-sm-center text-center"
                >
                  Question <br className="d-none d-md-block" /> &nbsp;
                  {questionCounter}/2
                </div> */}
                <form
                  ref={splitForm}
                  name="split_form"
                  id="user_form"
                  autoComplete="off"
                >
                  <ul className="p-0 m-0 questionist mb-5">
                  <li>
                    <div
                      className={`questitem q4 ${state.showSlide3} z-1`}
                      id="slide-21"
                      ref={(el) => (scrollRef.current["banks"] = el)}
                    >
                      <h1 className="que-title qt-1 p-3" style={{color:'#000'}}>
                      Your bank transactions provides compelling evidence of your repayment with Vanquis, and also acts as a solid proof to support your hardship and struggles.<br/>
                      <br/>Please select who were you banking with when you opened your account with Vanquis.
                      </h1>
                      <Banks
                        backClick={previousSlideChange}
                        lenderList={lenderList}
                        SelectBank={SelectBank}
                        setSelectBank={setSelectBank}
                        saveInputAns={saveInputAns}
                        show={state.showSlide3a}
                        setLenderName={setLenderName}
                        SkippedLeads={SkippedLeads}
                        setClickedSlide={setClickedSlide}
                        setAccNum={setAccNum}
                        lenderName={lenderName}
                        setSortCode={setSortCode}
                        onSelectAnswer={onSelectAnswer}
                        shareInfoSave={shareInfoSave}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        BankValidationMsg={BankValidationMsg}
                        setBankValidationMsg={setBankValidationMsg}
                        shareInfoPopUp={shareInfoPopUp}
                        setShareInfoPopUp={setShareInfoPopUp}
                        clearErrors={clearErrors}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide3a}`}
                    >
                      We need to confirm transactional details with your bank to
                      produce a compelling complaint to Vanquis.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                    <li>
                      <div
                        className={`questitem q3  ${state.showSlide1}`}
                        id="slide-3"
                        ref={(el) => (scrollRef.current["btn_qest_36"] = el)}
                      >
                        <h1 className="que-title p-3 text-style">
                          {" "}
                          Please select all applicable statements that accurately describes your situation.
                        </h1>
                        <FurtherDetails
                          onSelectAnswer={onSelectAnswer}
                          questArr={questinnaire36VAQV5}
                          validation={register}
                          validationMsg={errors}
                          getValues={getValues}
                          trigger={trigger}
                          setError={setError}
                          optionSelected={optionSelected}
                          showError36={showError36}
                          setshowError36={setshowError36}
                          backClick={previousSlideChange}
                        />
                      </div>
                      <div 
                        className={`tipbox tip2 ${state.showSlide1a}`} 
                        ref={(el) => (scrollRef.current["further_details_first"] = el)}
                      >
                      
                        Your feedbacks helps support the basis of claim.
                        <div className="arrow  arrow-top"></div>
                      </div>
                    </li>

                    <li>
                      <div
                        className={`questitem q3  ${state.showSlide2}`}
                        id="slide-4"
                        ref={(el) => (scrollRef.current["btn_qest_76"] = el)}
                      >
                        <h1 className="que-title p-3 text-style">
                          {" "}
                          Thanks for that, please confirm if any of these
                          statements also accurately describe your situation.
                        </h1>
                        <ConfirmSituation
                          onSelectAnswer={onSelectAnswer}
                          optionArr={questinnaire76VAQ5}
                          validation={register}
                          validationMsg={errors}
                          getValues={getValues}
                          trigger={trigger}
                          setError={setError}
                          SelectedOptions={SelectedOptions}
                          backClick={previousSlideChange}
                          showError76={showError76}
                          setshowError76={setshowError76}
                        />
                      </div>
                      <div 
                      className={`tipbox tip3 ${state.showSlide2a}`}
                      ref={(el) => (scrollRef.current["further_details_second"] = el)}
                      >
                        Your feedbacks helps support the basis of claim.
                        <div className="arrow  arrow-top"></div>
                      </div>
                    </li>
                  </ul>
                </form> 
              </div>
            </div>
          </section>
          <PrevAddressPopup
            show={showLoad === "hide" && showPrevAddressPopup}
            handleClose={handleClose}
            titleQuest={surnameTitles.includes(formdata?.lstSalutation||title)}
            formdata={formdata}
            query_string={query_string}
          />
          <Footer />
          

          {/* <div className={
                  stickyBttn === 'show'
                    ? 'show'
                    : 'hide'
                }>
            <button className={`regNextBtn-2 d-sm-none`}
              type="button"
              name="banks"
              id="q64"
              onClick={MobBtnClick}
              data-quest-id={64}
            >
              Next &#8594;
            </button>
          </div> */}


          <div className={`${state.showSlide1}`}>
            <button className={`regNextBtn-2 d-sm-none`}
              type="button"
              name="btn_qest_36"
              id="q76"
              onClick={MobBtnClick}
              data-quest-id={36}
            >
              Next &#8594;
            </button>
          </div>
          <div className={`${state.showSlide2a}`}>
            <button className={`regNextBtn-2 d-sm-none`}
              type="button"
              name="btn_qest_76"
              id="q76"
              onClick={MobBtnClick}
              data-quest-id={76}
            >
              Next &#8594;
            </button>
          </div>

          
        </div>
      </>
    );

};

export default QuestionaireVaqV5;