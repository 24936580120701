import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import Split_1 from "./Components/Pages/Splits/Split_1";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Questionnaire from "./Components/Pages/Questionnaire";
import Followup from "./Components/Pages/Splits/Followup";
import Preview from "./Components/Pages/Preview";
import Proceed from "./Components/Pages/Proceed";
import Unqualified from "./Components/Pages/Unqualified";
import Signature from "./Components/Pages/Signature";
import SignatureMB from "./Components/Includes/Layouts/MB_UFGN_1/SignatureMB"
import Thankyou from "./Components/Pages/Thankyou";
import EnvelopeThankyou from "./Components/Pages/EnvelopeThankyou";
import ThankyouBL from "./Components/Pages/ThankyouBL";
// import IdUpload from "./Components/Pages/IdUpload"
import UserDocument from "./Components/Pages/IdUpload";

import PreviewLoa from "./Components/Pages/PreviewLoa";
import PreviewV2 from "./Components/Pages/PreviewV2";
import NotFound from "./Components/Pages/NotFound";
import ADV_1 from "./Components/Pages/Advertorials/ADV_1";
import ADV1_Contact from "./Components/Pages/Advertorials/ADV1_Contact"
import MB_UBRF_1_1 from "./Components/Pages/Splits/MB_UBRF_1_1";
import MB_UBRF_1 from "./Components/Pages/Splits/MB_UBRF_1";
import MB_UBRF_2 from "./Components/Pages/Splits/MB_UBRF_2";
import TMS_G_V3 from "./Components/Pages/Splits/TMS_G_V3";
import QuestionnaireV2 from "./Components/Pages/QuestionnaireV2";
import QuestionnaireV3 from "./Components/Pages/QuestionnaireV3";
import QuestionnaireV4 from "./Components/Pages/QuestionaireV4";
import QuestionnaireV6 from "./Components/Pages/QuestionaireV6";
import QuestionnaireV7 from "./Components/Pages/QuestionnaireV7";
import ViewDocs from "./Components/Pages/Viewdocs";
import LS_UB_1 from "./Components/Pages/Splits/LS_UB_1";
import PreviewV3 from "./Components/Pages/PreviewV3";
import ViewdocsFollowup from "./Components/Pages/ViewdocsFollowup";
import PreviewFollowup from "./Components/Pages/PreviewFollowup";
import IdUploadFollowup from "./Components/Pages/IdUploadFollowup";
import FollowupV2 from "./Components/Pages/Splits/FollowupV2";
import ViewdocsFollowup_LS from "./Components/Pages/ViewdocsFollowup_LS";
import PreviewFollowupV3 from "./Components/Pages/PreviewFollowupV3";
import SignProceed from "./Components/Pages/SignProceed";
import VCC_UB_1 from "./Components/Pages/Splits/VCC_UB_1";
import PreviewV4 from "./Components/Pages/PreviewV4";
import FollowupV3 from "./Components/Pages/Splits/FollowupV3";
import PreviewFollowupV4 from "./Components/Pages/PreviewFollowupV4";
import VCC_UB_2 from "./Components/Pages/Splits/VCC_UB_2";
import VCC_UB_3 from "./Components/Pages/Splits/VCC_UB_3";
import QuestionnaireV8 from "./Components/Pages/QuestionnaireV8";
import QuestionnaireV5 from "./Components/Pages/QuestionaireV5";
import PreviewV5 from "./Components/Pages/PreviewV5";

import PreviewV6 from "./Components/Pages/PreviewV6";
import VAQ_UB_1 from "./Components/Pages/Splits/VAQ_UB_1";
import TMSV_SQL_V1 from "./Components/Pages/Advertorials/TMSV_SQL_V1";

import TMSLS_SQL_V1 from "./Components/Pages/Advertorials/TMSLS_SQL_V1";
import FollowupV5 from "./Components/Pages/Splits/FollowupV5";
import PreviewFollowupV5 from "./Components/Pages/PreviewFollowupV5";
import NDY_UB_1 from "./Components/Pages/Splits/NDY_UB_1";
import PreviewV7 from "./Components/Pages/PreviewV7";
import IdFollowup from "./Components/Pages/Splits/IdFollowup";

import FollowupS1 from "./Components/Pages/FollowupS1";
import PreviewFollowupV7 from "./Components/Pages/PreviewFollowupV7";

import MB_UBRF_3 from "./Components/Pages/Splits/MB_UBRF_3";
import QuestionnaireV9 from "./Components/Pages/QuestionnaireV9";

import MB_OB_UBRF_3_1 from "./Components/Pages/Splits/MB_OB_UBRF_3_1";
import QuestionnaireV16 from "./Components/Pages/QuestionnaireV16";

import LS_UB_2 from "./Components/Pages/Splits/LS_UB_2";
import QuestionnaireV10 from "./Components/Pages/QuestionnaireV10";

import VAQ_UB_2 from "./Components/Pages/Splits/VAQ_UB_2";
import QuestionnaireV11 from "./Components/Pages/QuestionnaireV11";

import OpenBankingProceed from "./Components/Pages/OpenBankingProceed";
import OpenBankingProceedV1 from "./Components/Pages/OpenBankingProceedV1";
import BudsRedirectPage from "./Components/Pages/BudsRedirectPage";

import NDY_UB_2 from "./Components/Pages/Splits/NDY_UB_2";
import QuestionnaireV12 from "./Components/Pages/QuestionnaireV12";

import Privacy_Policy from "./Components/Includes/Layouts/Privacy_Policy";
import QuestionnaireOb from "../src/Components/Pages/QuestionnaireOb";

import MB_OBV1 from "./Components/Pages/Splits/MB_OBV1";
import ObEnvelopeQuestionnaire from "./Components/Pages/ObEnvelopeQuestionnaire";
import OpenBanking from "./Components/Pages/OpenBanking";
import OpenBankingV2 from "./Components/Pages/OpenBankingV2";
import Analyse from "./Components/Pages/Analyse";

import CreditCheck from "./Components/Pages/CreditCheck";
import OB_Thankyou02 from "./Components/Pages/OB_Thankyou02";
import ObSplashCancel from "./Components/Pages/ObSplashCancel";
import OpenBankingProceedV2 from "./Components/Pages/OpenBankingProceedV2";
import OB_AnalyzeV2 from "./Components/Includes/Layouts/ObFollowupV2/OB_AnalyzeV2";
import CreditCheckV2 from "./Components/Includes/Layouts/ObFollowupV2/CreditCheckV2";
import BankStmtUploadPageV2 from "./Components/Includes/Layouts/ObFollowupV2/BankStmtUploadPageV2";

import FosFollowupV1 from "./Components/Pages/FosFollowupV1";
import CreditCheckFos from "./Components/Includes/Layouts/FosFollowupV1/CreditCheckFos";
import OB_AnalyzeFos from "./Components/Includes/Layouts/FosFollowupV1/OB_AnalyzeFos";
import ThankyouExtFos from "./Components/Includes/Layouts/FosFollowupV1/ThankyouExtFos";

import FOSCreditCheckFLP from "./Components/Pages/FOSCreditCheckFLP";
import FLPClicktoCall from "./Components/Pages/FLPClicktoCall";

import FollowupS2 from "./Components/Pages/FollowupS2";
import ViewdocsFollowupS2 from "./Components/Pages/ViewdocsFollowupS2";
import IdFollowupS2 from "./Components/Pages/IdFollowupPageS2";
import PreviewFollowupS2 from "./Components/Pages/PreviewFollowupS2";
import CreditCheckS2 from "./Components/Pages/CreditCheckS2";
import BankStmtUploadPageS2 from "./Components/Pages/BankStmtUploadPageS2";
import OpenBankingProceedS2 from "./Components/Pages/OpenBankingProceedS2";
import OB_AnalyzeS2 from "./Components/Pages/OB_AnalyzeS2";
import DocsPreviewPageS2 from "./Components/Pages/DocsPreviewPageS2";

import VanquisThankYou from "./Components/Pages/VanquisTY";
import MB_UFGN_1 from "./Components/Pages/Splits/MB_UFGN_1";
import AnalyseMB_UFgn_1 from "./Components/Pages/AnalyseMB_UFGN_1";
import OpenBankingProceedMB1 from "./Components/Pages/OpenBankingProceedMB1";
import OB_AnalyseMB1 from "./Components/Pages/OB_AnalyseMB1";
import BankStmtUploadPageMB1 from "./Components/Pages/BankStmtUploadPageMB1";
import MB_UFGN_1_Questionnaire from "./Components/Pages/MB_UFGN_1_Questionnaire";
import Viewdocs_MB_UFGN_1 from "./Components/Pages/Viewdocs_MB_UFGN_1";
import PreviewMB_UFGN_1 from "./Components/Pages/PreviewMB_UFGN_1";
import ObUnqualified from "./Components/Pages/ObUnqualified";
import DocsPreviewPageMB_UFGN_1 from "./Components/Pages/DocsPreviewPageMB_UFGN_1";
import CreditCheckMB_UFGN_1 from "./Components/Pages/CreditCheckMB_UFGN_1";
import AnalyseT2a from "./Components/Pages/AnalyseT2a";
import OpenBankingProceedMB2 from "./Components/Pages/OpenBankingProceedMB2";
import VAQ_UB_3 from "./Components/Pages/Splits/VAQ_UB_3";
import questionnaireV14 from "./Components/Pages/QuestionnaireV14";
import PreviewV8 from "./Components/Pages/PreviewV8";
import NotFound1 from "./Components/Pages/NotFound1";
import AnalyseMB_NEW from "./Components/Pages/AnalyseMB_UFGN_NEW";
import CheckList from "./Components/Pages/CheckList";
import PersonKycMB_GN_2 from "./Components/Pages/PersonKycMB_GN_2";
import VerifyMB_UF_GN_2 from "./Components/Pages/VerifyMB_UF_GN_2";
import FollowupS3 from "./Components/Pages/FollowupS3";
import CheckListFLPS3 from "./Components/Pages/CheckListFLPS3";
import FollowupcarS3 from "./Components/Pages/FollowupcarS3";
import FollowupS3Quest from "./Components/Pages/FollowupS3Quest";
import BankIncomeStatementS3 from "./Components/Pages/BankIncomeStatementS3";
import VerifyDataS3 from "./Components/Pages/VerifyFLPS3"
import PersonKycFLPS3 from "./Components/Pages/PersonKycFLPS3";
import OpenBankingProceedFLPS3 from "./Components/Pages/OpenBankingProceedS3";
import OB_AnalyzeS3 from "./Components/Pages/OB_AnalyzeS3";
import CreditCheckFLPS3 from "./Components/Pages/CreditCheckFLPS3";
import SignatureVAQ from "./Components/Pages/SignatureVAQ";
import PreviewFollowupS3 from "./Components/Pages/PreviewFollowupS3";
import QuestionnaireV15 from "./Components/Pages/QuestionnaireV15";
import QuestionnaireV19 from "./Components/Pages/QuestionnaireV19";
import VAQ_UB_4 from "./Components/Pages/Splits/VAQ_UB_4";
import VAQ_UB_4_2 from "./Components/Pages/Splits/VAQ_UB_4_2";
import SignatureNDY from "./Components/Pages/SignatureNDY";
import NDY_OB_UB_2_1 from "./Components/Pages/Splits/NDY_OB_UB_2_1";
import QuestionnaireV17 from "./Components/Pages/QuestionnaireV17";
import VAQ_ID_UPLOAD from "./Components/Pages/VAQ_ID_UPLOAD";
import PreviewVAQ_UB_4 from "./Components/Pages/PreviewVAQ_UB_4";
import Verify_VAQ_UB_4 from "./Components/Pages/VerifyData_VAQ_4";
import Verify_VAQ_UB_4_2 from "./Components/Pages/VerifyData_VAQ_UB_4_2";
import VAQ_OB_UB_3_1 from "./Components/Pages/Splits/VAQ_OB_UB_3_1";
import QuestionnaireV18 from "./Components/Pages/QuestionnaireV18";
import PreviewVAQ4New from "./Components/Pages/PreviewVAQ_UB_4_NEW"
import FollowupVAQ from "./Components/Pages/FollowupVAQ";
import PreviewVAQFlp from "./Components/Pages/PreviewVAQFlp";
import VAQ_UB_4_3 from "./Components/Pages/Splits/VAQ_UB_4_3";
import QuestionnaireV20 from "./Components/Pages/QuestionnaireV20";
import VerifyData_VAQ_UB_4_3 from "./Components/Pages/VerifyData_VAQ_UB_4_3";
import FollowupNDY from "./Components/Pages/FollowupNDY";
import FollowupVERY from "./Components/Pages/FollowupVERY";
import FollowupMB from "./Components/Pages/FollowupMB";
import CheckListGN_4 from "./Components/Pages/CheckListGN_4";
import QuestionnaireGN_4 from "./Components/Pages/QuestionnaireGN_4";
import PreviewMB_UFGN_4 from "./Components/Pages/PreviewMB_UFGN_4";
import QuestionaireMBGN_4 from "./Components/Pages/QuestionaireMBGN_4";
import AnalyseMB_UFGN_4 from "./Components/Pages/AnalyseMB_UFGN_4";
import VerifyMB_UF_GN_4 from "./Components/Pages/VerifyMB_UF_GN_4";
import PersonKycMB_GN_4 from "./Components/Pages/PersonKycMB_GN_4";

import MB_UFGN_4 from "./Components/Pages/Splits/MB_UFGN_4";
import MB_UFGN_4_1 from "./Components/Pages/Splits/MB_UFGN_4_1";
import QuestionnaireGN_4_1 from "./Components/Pages/QuestionaireMBGN_4_1";
import NDY_UB_4 from "./Components/Pages/Splits/NDY_UB_4";
import NDY_ID_UPLOAD from "./Components/Pages/NDY_ID_UPLOAD";
import VerifyData_NDY_UB_4 from "./Components/Pages/VerifyData_NDY_UB_4";

import QuestionnaireV22 from "./Components/Pages/QuestionnaireV22"
import PreviewNDY from "./Components/Pages/PreviewNDY";
import PreviewNDYFLP from "./Components/Pages/Preview_NDY_FLP";
import NDY_UB_4_2 from "./Components/Pages/Splits/NDY_UB_4_2";
import PreviewMeritPage from "./Components/Pages/PreviewMeritPage";
import CheckMyId from "./Components/Pages/CheckMyId";
import VAQ_UB_4_3_1 from "./Components/Pages/Splits/VAQ_UB_4_3_1";
import QuestionnaireV23 from "./Components/Pages/QuestionnaireV23";
import PreviewVAQ_UB_4_3_1 from "./Components/Pages/PreviewVAQ_UB_4_3_1";

import ThankyouNDY from "./Components/Pages/ThankyouNDY";
import VAQ_UB_4_3_2 from "./Components/Pages/Splits/VAQ_UB_4_3_2";
import QuestionnaireV25 from "./Components/Pages/QuestionnaireV25";
import NDY_UB_4_3 from "./Components/Pages/Splits/NDY_UB_4_3";
import QuestionnaireV26 from "./Components/Pages/QuestionnaireV26";
import NDY_UB_4_4 from "./Components/Pages/Splits/NDY_UB_4_4";
import QuestionnaireV27 from "./Components/Pages/QuestionnaireV27";
import VAQ_UB_4_3_1_B from "./Components/Pages/Splits/VAQ_UB_4_3_1_B";
import VAQ_UB_4_3_1_C from "./Components/Pages/Splits/VAQ_UB_4_3_1_C";
import QuestionnaireV30 from "./Components/Pages/QuestionnaireV30";
import PreviewVAQ_UB_4_3_C from "./Components/Pages/PreviewVAQ_UB_4_3_C";

import FlpVaqV2 from "./Components/Pages/FlpVaqV2";
import VAQ_UB_4_3_1_D from "./Components/Pages/Splits/VAQ_UB_4_3_1_D";

import VAQ_UB_5 from "./Components/Pages/Splits/VAQ_UB_5";
import VAQ_ID_UPLOAD_V5 from "./Components/Pages/VAQ_ID_UPLOAD_V5";
import PreviewOneVaq_V5 from "./Components/Pages/PreviewOneVaq_V5";
import PreviewQuestVaq_V5 from "./Components/Pages/PreviewQuestVaq_V5";
import MeritPage_Vaq_V5 from "./Components/Pages/MeritPage_Vaq_V5";

import QuestionaireVAQV5 from './Components/Pages/QuestionaireVaqV5';
import QuestionnaireV29 from "./Components/Pages/QuestionnaireV29";
import VAQ_UB_4_3_1_C2 from "./Components/Pages/Splits/VAQ_UB_4_3_1_C2";
import QuestionnaireV31 from "./Components/Pages/QuestionnaireV31";
import MB_UFGN_4_2 from "./Components/Pages/Splits/MB_UFGN_4_2";
import QuestionaireMBGN_4_2 from "./Components/Pages/QuestionaireMBGN_4_2"
import QuestionnaireV28 from "./Components/Pages/QuestionnaireV28";
import ClaimOutcome from "./Components/Pages/ClaimOutcome";
import PersonKycMB_GN_4_2 from "./Components/Pages/PersonKycMB_GN_4_2";
import QuestionnaireV24 from "./Components/Pages/QuestionnaireV24";
import OD_UFL_4 from "./Components/Pages/Splits/OD_UFL_4";
import PreviewOD_UFL_4 from "./Components/Pages/PreviewOD_UFL_4";
import ThankyouODUFL4 from "./Components/Pages/ThankyouODUFL4.js";

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/Split_1"]} component={Split_1} />
              <Route exact path={"/questionnaire"} component={Questionnaire} />
              <Route exact path={"/questionnaireV2"} component={QuestionnaireV2} />
              <Route exact path={"/questionnaireV3"} component={QuestionnaireV3} />
              <Route exact path={"/questionnaireV4"} component={QuestionnaireV4} />
              <Route exact path={"/questionnaireV6"} component={QuestionnaireV6} />
              <Route exact path={"/QuestionnaireV7"} component={QuestionnaireV7} />
              <Route exact path={"/followup"} component={Followup} />
              <Route exact path={["/splash-page"]} component={Preview} />
              <Route exact path={"/proceed"} component={Proceed} />
              <Route exact path={"/unqualified"} component={Unqualified} />
              <Route exact path={"/signature"} component={Signature} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={"/thankyou-bl"} component={ThankyouBL} />
              {/* <Route exact path={["/id-upload"]} component={IdUpload} /> */}
              <Route exact path={["/id-upload"]} component={UserDocument} />
              
              <Route exact path={["/preview"]} component={PreviewLoa} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/ADV_1"]} component={ADV_1} />
              <Route exact path={["/ADV1/contact"]} component={ADV1_Contact}/>
              <Route exact path={["/MB_UBRF_1.1"]} component={MB_UBRF_1_1} />
              <Route exact path={["/MB_UBRF_1"]} component={MB_UBRF_1} />
              <Route exact path={["/MB_UBRF_2"]} component={MB_UBRF_2} />
              <Route exact path={["/MB_UBRF_3"]} component={MB_UBRF_3} />
              <Route exact path={["/TMS_G_V3"]} component={TMS_G_V3} />
              <Route exact path={"/previewV2"} component={PreviewV2} />
              <Route exact path={"/view_docs"} component={ViewDocs} />
              <Route exact path={"/LS_UB_1"} component={LS_UB_1} />
              <Route exact path={"/previewV3"} component={PreviewV3} />
              <Route exact path={"/view_docs_flp"} component={ViewdocsFollowup} />
              <Route exact path={["/preview_flp"]} component={PreviewFollowup} />
              <Route exact path={["/id-upload-flp"]} component={IdUploadFollowup} />
              <Route exact path={"/followupV2"} component={FollowupV2} />
              <Route exact path={"/view_docs_ls"} component={ViewdocsFollowup_LS} />
              <Route exact path={["/preview_flp_v3"]} component={PreviewFollowupV3} />
              <Route exact path={["/sign_proceed"]} component={SignProceed} />
              <Route exact path={"/VCC_UB_1"} component={VCC_UB_1} />
              <Route exact path={"/previewV4"} component={PreviewV4} />
              <Route exact path={"/followupV3"} component={FollowupV3} />
              <Route exact path={"/preview_flp_v4"} component={PreviewFollowupV4} />
              <Route exact path={"/previewV6"} component={PreviewV6} />
              <Route exact path={"/VAQ_UB_1"} component={VAQ_UB_1} />
              <Route exact path={"/TMSV_SQL_V1"} component={TMSV_SQL_V1} />
              <Route exact path={"/TMSLS_SQL_V1"} component={TMSLS_SQL_V1} />
              <Route exact path={"/followupV5"} component={FollowupV5} />
              <Route exact path={"/preview_flp_v5"} component={PreviewFollowupV5} />

              <Route exact path={["/MB_OB_UBRF_3.1"]} component={MB_OB_UBRF_3_1} />
              <Route exact path={"/questionnaireV16"} component={QuestionnaireV16} />

              <Route exact path={"/VCC_UB_2"} component={VCC_UB_2} />
              <Route exact path={"/questionnaireV5"} component={QuestionnaireV5} />
              <Route exact path={"/previewV5"} component={PreviewV5} />
              <Route exact path={"/NDY_UB_1"} component={NDY_UB_1} />
              <Route exact path={"/PreviewV7"} component={PreviewV7} />
              <Route exact path={"/id-followup"} component={IdFollowup} />

              <Route exact path={"/followup-s1"} component={FollowupS1} />
              <Route exact path={["/preview_flp_v7"]} component={PreviewFollowupV7} />
              <Route exact path={"/VCC_UB_3"} component={VCC_UB_3} />
              <Route exact path={"/questionnaireV8"} component={QuestionnaireV8} />
              <Route exact path={"/questionnaireV9"} component={QuestionnaireV9} />
              <Route exact path={"/proceed-ob"} component={OpenBankingProceed} />
              <Route exact path={"/proceed-obV1"} component={OpenBankingProceedV1} />
              <Route exact path={"/ob-thankyou"} component={BudsRedirectPage} />
              <Route exact path={"/envelope-thankyou"} component={EnvelopeThankyou} />

              <Route exact path={"/LS_UB_2"} component={LS_UB_2} />
              <Route exact path={"/questionnaireV10"} component={QuestionnaireV10} />

              <Route exact path={"/VAQ_UB_2"} component={VAQ_UB_2} /> 
              <Route exact path={"/questionnaireV11"} component={QuestionnaireV11} />

              <Route exact path={"/NDY_UB_2"} component={NDY_UB_2} />
              <Route exact path={"/questionnaireV12"} component={QuestionnaireV12}/>

              <Route exact path={"/privacy_policy"} component={Privacy_Policy} />

              <Route exact path={"/MB_OBV1"} component={MB_OBV1} />
              <Route exact path={"/QuestionnaireOb"} component={QuestionnaireOb} />
              <Route exact path={"/OBV1"} component={OpenBanking} />
              <Route exact path={"/OBV2"} component={OpenBankingV2} />
              <Route exact path={"/analyse"} component={Analyse} />

              <Route exact path={"/credit_check"} component={CreditCheck} />

              <Route exact path={"/ob-thankyou02"} component={OB_Thankyou02} />

              <Route exact path={"/ob_splash_cancel"} component={ObSplashCancel} />


              
              
              <Route exact path={"/QuestionnaireEnvlp"} component={ObEnvelopeQuestionnaire}/>
              <Route exact path={"/proceed-obV2"} component={OpenBankingProceedV2} />
              <Route exact path={"/ob-analyzeV2"} component={OB_AnalyzeV2} />
              <Route exact path={"/credit_checkV2"} component={CreditCheckV2} />
              <Route exact path={"/ob_bank_stmt_uploadV2"} component={BankStmtUploadPageV2} />

              <Route exact path={"/followup-fos"} component={FosFollowupV1} />
              <Route exact path={"/credit_check_fos"} component={CreditCheckFos} />
              <Route exact path={"/ob-analyze_fos"} component={OB_AnalyzeFos} />
              <Route exact path={"/thankyou_external_fos"} component={ThankyouExtFos} />

              <Route exact path={"/followup-CreditCheckFos"} component={FOSCreditCheckFLP} />
              
              <Route exact path={"/flp-clicktocall"} component={FLPClicktoCall} />
              <Route exact path={"/Vanquisthankyou"} component={VanquisThankYou} />
              
              <Route exact path={"/followup-s2"} component={FollowupS2} />
              <Route exact path={"/view_docs_flp-s2"} component={ViewdocsFollowupS2} />
              <Route exact path={"/id-upload-followup-s2"} component={IdFollowupS2} />
              <Route exact path={"/preview_flp-s2"} component={PreviewFollowupS2} />
              <Route exact path={"/credit_check-s2"} component={CreditCheckS2} />
              <Route exact path={"/ob_bank_stmt_upload-s2"} component={BankStmtUploadPageS2} />
              <Route exact path={"/proceed-ob-s2"} component={OpenBankingProceedS2} />
              <Route exact path={"/ob-analyze-s2"} component={OB_AnalyzeS2} />
              <Route exact path={"/document-preview-s2"} component={DocsPreviewPageS2} />
              

              <Route exact path={"/MB_UFGN_1"} component={MB_UFGN_1} />
              <Route exact path={"/signatureMB"} component={SignatureMB} />
              <Route exact path={"/AnalyseMB_UFGN_1"} component={AnalyseMB_UFgn_1} />
              <Route exact path={"/proceed-ob-mb1"} component={OpenBankingProceedMB1} />
              <Route exact path={"/ob-analyse-mb1"} component={OB_AnalyseMB1} />
              <Route exact path={"/ob_bank_stmt_upload-mb1"} component={BankStmtUploadPageMB1} />
              <Route exact path={"/questionnaireV13"} component={MB_UFGN_1_Questionnaire} />
              <Route exact path={"/view_docs_MB_UFGN_1"} component={Viewdocs_MB_UFGN_1} />
              <Route exact path={"/preview_MB_UFGN_1"} component={PreviewMB_UFGN_1} />
              <Route exact path={"/unqualified-ob"} component={ObUnqualified} />
              <Route exact path={"/DocsPreviewPageMB_UFGN_1"} component={DocsPreviewPageMB_UFGN_1} />
              <Route exact path={"/unqualified-ob"} component={ObUnqualified} />
              <Route exact path={"/CreditCheck_MB_UFGN_1"} component={CreditCheckMB_UFGN_1} />
              <Route exact path={"/AnalysePersonData"} component={AnalyseT2a} />
              <Route exact path={"/proceed-ob-mb2"} component={OpenBankingProceedMB2} />

              <Route exact path={"/VAQ_UB_3"} component={VAQ_UB_3} />
              <Route exact path={"/QuestionnaireV14"} component={questionnaireV14} />
              <Route exact path={"/PreviewV8"} component={PreviewV8} />

              <Route exact path={["/Not_Found"]} component={NotFound1} />
              <Route exact path={"/AnalyseMB"} component={AnalyseMB_NEW} />
              <Route exact path={"/CheckList"} component={CheckList} />
              <Route exact path={"/PersonDataKyc"} component={PersonKycMB_GN_2} />
              <Route exact path={"/VerifyData"} component={VerifyMB_UF_GN_2} />

              <Route exact path={"/followup-s3"} component={FollowupS3} />
              <Route exact path={"/checkListFLPS3"} component={CheckListFLPS3} />
              <Route exact path={"/FollowupcarS3"} component={FollowupcarS3} />
              <Route exact path={"/FollowupS3quest"} component={FollowupS3Quest} />
              <Route exact path={"/bank_statement_S3"} component={BankIncomeStatementS3} />
              <Route exact path={"/VerifyDataS3"} component={VerifyDataS3} />
              <Route exact path={"/PersonKycFLPS3"} component={PersonKycFLPS3} />
              <Route exact path={"/proceed-ob-s3"} component={OpenBankingProceedFLPS3} />
              <Route exact path={"/ob-analyze-s3"} component={OB_AnalyzeS3} />
              <Route exact path={"/credit_check-s3"} component={CreditCheckFLPS3} />
              <Route exact path={"/signatureVAQ"} component={SignatureVAQ} />
              <Route exact path={"/preview_flp-s3"} component={PreviewFollowupS3} />

              <Route exact path={"/questionnaireV15"} component={QuestionnaireV15} />
              <Route exact path={"/signatureNDY"} component={SignatureNDY} />
              <Route exact path={"/VAQ_UB_4_ID"} component={VAQ_ID_UPLOAD} />
              <Route exact path={"/PreviewVAQ_UB_4"} component={PreviewVAQ_UB_4} /> 
              <Route exact path={"/Verify_VAQ_UB_4"} component={Verify_VAQ_UB_4} />         
        

              <Route exact path={"/VAQ_UB_4"} component={VAQ_UB_4} />
              <Route exact path={"/NDY_OB_UB_2.1"} component={NDY_OB_UB_2_1} />
              <Route exact path={"/questionnaireV17"} component={QuestionnaireV17}/>

              <Route exact path={"/VAQ_OB_UB_3.1"} component={VAQ_OB_UB_3_1} />
              <Route exact path={"/questionnaireV18"} component={QuestionnaireV18} />
              <Route exact path={"/PreviewVAQ4"} component={PreviewVAQ4New} />
              <Route exact path={"/preview-vaq-flp"} component={PreviewVAQFlp} />

              <Route exact path={"/VAQ_UB_4_2"} component={VAQ_UB_4_2} />
              <Route exact path={"/questionnaireV19"} component={QuestionnaireV19} />
              <Route exact path={"/Verify_VAQ_UB_4_2"} component={Verify_VAQ_UB_4_2} />
              {/* <Route exact path={"/PreviewVAQ_UB_4_2"} component={PreviewVAQ_UB_4_2} /> */}
              {/* <Route exact path={"/VAQ_UB_4_2_ID"} component={VAQ_ID_UPLOAD} /> */}



              <Route exact path={"/followup-vaq"} component={FollowupVAQ} />
              <Route exact path={"/VAQ_UB_4_3"} component={VAQ_UB_4_3} />
              <Route exact path={"/questionnaireV20"} component={QuestionnaireV20} />
              <Route exact path={"/Verify_VAQ_UB_4_3"} component={VerifyData_VAQ_UB_4_3} />
              <Route exact path={"/followup-ndy"} component={FollowupNDY} />

              <Route exact path={"/followup-very"} component={FollowupVERY} />

              <Route exact path={"/followup-mb"} component={FollowupMB} />
              <Route exact path={"/questionnaireMBGN_4"} component={QuestionaireMBGN_4} />
              <Route exact path={"/AnalyseMB_UFGN_4"} component={AnalyseMB_UFGN_4} />

              <Route exact path={"/VerifyData_MB4"} component={VerifyMB_UF_GN_4} />
              <Route exact path={"/PersonDataKyc_MB4"} component={PersonKycMB_GN_4} />



              <Route exact path={"/CheckListGN_4"} component={CheckListGN_4} />
              <Route exact path={"/questionnaireV21"} component={QuestionnaireGN_4} />
              <Route exact path={"/preview_MB_UFGN_4"} component={PreviewMB_UFGN_4} />



              <Route exact path={"/MB_UFGN_4"} component={MB_UFGN_4} />
              <Route exact path={"/MB_UFGN_4_1"} component={MB_UFGN_4_1} />


              <Route exact path={"/questionnaireMBGN_4_1"} component={QuestionnaireGN_4_1} />

              <Route exact path={"/NDY_UB_4"} component={NDY_UB_4} />
              <Route exact path={"/NDY_UB_4_ID"} component={NDY_ID_UPLOAD} />
              <Route exact path={"/Verify_NDY_UB_4"} component={VerifyData_NDY_UB_4} /> 
              <Route exact path={"/questionnaireV22"} component={QuestionnaireV22} />
              <Route exact path={"/PreviewNDY"} component={PreviewNDY} />
              <Route exact path={"/preview-newday-flp"} component={PreviewNDYFLP} />

              <Route exact path={"/NDY_UB_4_2"} component={NDY_UB_4_2} />

              <Route exact path={"/PreviewMeritPage"} component={PreviewMeritPage} />
              <Route exact path={"/checkid"} component={CheckMyId} />
              <Route exact path={"/thankyouNDY"} component={ThankyouNDY} />

              <Route exact path={"/VAQ_UB_4_3_1"} component={VAQ_UB_4_3_1} />
              <Route exact path={"/questionnaireV23"} component={QuestionnaireV23} />
              <Route exact path={"/PreviewVAQ_UB_4_3_1"} component={PreviewVAQ_UB_4_3_1} />

              <Route exact path={"/VAQ_UB_4_3_2"} component={VAQ_UB_4_3_2} />
              <Route exact path={"/questionnaireV25"} component={QuestionnaireV25} />

              <Route exact path={"/NDY_UB_4_3"} component={NDY_UB_4_3} />
              <Route exact path={"/questionnaireV26"} component={QuestionnaireV26} />

              <Route exact path={"/NDY_UB_4_4"} component={NDY_UB_4_4} />
              <Route exact path={"/questionnaireV27"} component={QuestionnaireV27} />
              <Route exact path={"/VAQ_UB_5"} component={VAQ_UB_5} />
              <Route exact path={"/VAQ_UB_5_ID"} component={VAQ_ID_UPLOAD_V5} />
              <Route exact path={"/questionaireVAQV5"} component={QuestionaireVAQV5} />
              <Route exact path={"/questionnaireV29"} component={QuestionnaireV29} />

              <Route exact path={"/VAQ_UB_4_3_1_B"} component={VAQ_UB_4_3_1_B} />
              <Route exact path={"/VAQ_UB_4_3_1_C"} component={VAQ_UB_4_3_1_C} />
              <Route exact path={"/questionnaireV30"} component={QuestionnaireV30} />

              <Route exact path={"/PreviewVAQ_UB_4_3_C"} component={PreviewVAQ_UB_4_3_C}/>

              <Route exact path={"/PreviewOneVaq_V5"} component={PreviewOneVaq_V5} />
              <Route exact path={"/PreviewQuestVaq_V5"} component={PreviewQuestVaq_V5} />

              <Route exact path={"/MeritPage_Vaq_V5"} component={MeritPage_Vaq_V5} />
              
              <Route exact path={"/followup-vaq-v2"} component={FlpVaqV2} />
              <Route exact path={"/VAQ_UB_4_3_1_D"} component={VAQ_UB_4_3_1_D} />

              <Route exact path={"/VAQ_UB_4_3_1_C2"} component={VAQ_UB_4_3_1_C2} />
              <Route exact path={"/questionnaireV31"} component={QuestionnaireV31} />
                        
              <Route exact path={"/MB_UFGN_4_2"} component={MB_UFGN_4_2} />
              <Route exact path={"/questionnaireMBGN_4_2"} component={QuestionaireMBGN_4_2} />
              <Route exact path={"/questionnaireV28"} component={QuestionnaireV28} />
              <Route exact path={"/claim_outcome"} component={ClaimOutcome} />
              <Route exact path={"/PersonDataKyc_MB4_2"} component={PersonKycMB_GN_4_2} />
              <Route exact path={"/OD_UFL_4"} component={OD_UFL_4} />


              <Route exact path={"/questionnaireV24"} component={QuestionnaireV24} />


              <Route exact path={"/PreviewODUFL4"} component={PreviewOD_UFL_4} />
              <Route exact path={"/ThankyouODUFL4"} component={ThankyouODUFL4} />

           </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
