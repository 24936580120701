import { useState } from "react";
// import { useFormContext } from "react-hook-form";
import Select from "react-select";
import { TopSelectedLenders } from "../../../../Constants/Constants";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import BankAccNo from "./BankAccNo";
import SortCode from "./SortCode";
import ShareInfo from "./ShareInfo";
import Autocomplete from "../../../UI/AutoComplete";

const Banks = ({
    show,
    backClick,
    lenderList,
    SelectBank,
    setSelectBank,
    saveInputAns,
    OldCardbck,
    Latepymnentbck,
    setLenderName,
    SkippedLeads,
    setClickedSlide,
    setAccNum,
    lenderName,
    setSortCode,
    onSelectAnswer,
    shareInfoSave,
    validation,
    getValues,
    trigger,
    validationMsg,
    setError
}) => {
    const [BankValidationMsg, setBankValidationMsg] = useState("");
    const [accNumPopUp, setAccNumPopUp] = useState("hide");
    const [sortCodePopUp, setSortCodePopUp] = useState(false);
    const [shareInfoPopUp, setShareInfoPopUp] = useState("hide");
    const [showOtherOpts,setShowOtherOpts] = useState(false);
    const [hideNxtBtn,setHideNxtBtn] = useState(true);


    const customStyles = {
        control: (base) => ({
            ...base,
            minHeight: 30,
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9999,
        }),
        menuList: (base) => ({
            ...base,
            zIndex: 9999,
        }),
    };
    

    const nextSlide = async (e) => {
        let LengthSelectedBank = SelectBank.length;
        let ValueSelectedBank = SelectBank.value;

        if (e.target.name == "bankOptions") {
            const selectedLender = TopSelectedLenders.find(
                (lender) => lender.value === e.target.value
            );
            if (selectedLender) {
                localStorage.setItem("bud_lender", e.target.value);
                setLenderName(selectedLender.label);
                setShareInfoPopUp("show");
                saveInputAns(e);
            }

            // // setSelectBank(e.target.value)
            // localStorage.setItem("bud_lender", e.target.value);
            // // localStorage.setItem("lenderArray", JSON.stringify([TopSelectedLenders]));
            // setLenderName(e.target.labelName);
            // saveInputAns(e);
        } else {
            if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
                setBankValidationMsg("Please select your Bank");
            } else {
                if(ValueSelectedBank != "others"){
                localStorage.setItem("bud_lender", ValueSelectedBank);
                localStorage.setItem("lenderArray", JSON.stringify([SelectBank]));
                setLenderName(ValueSelectedBank);
                setShareInfoPopUp("show");
                saveInputAns(e);
                }else{
                    setShowOtherOpts(true);
                    setHideNxtBtn(false);
                }
            }
        }
    };

    function handleSelect(opt) {
        setSelectBank(opt);
        setBankValidationMsg("");
        if(opt.value!="others"){
            setShowOtherOpts(false);
            setHideNxtBtn(true);
        }else{
            setShowOtherOpts(true);
            setHideNxtBtn(false);
          }
    }
    let BankList = [];

    if (lenderList.length > 0) {
        BankList = BankList.concat(
            lenderList
                .filter(function (val) {
                    return (
                        !val.display_name.endsWith("Card") &&
                        !val.display_name.endsWith("Business") &&
                        !val.display_name.endsWith("card") &&
                        !val.display_name.endsWith("Cards") &&
                        !val.display_name.includes("Barclays") &&
                        !val.display_name.includes("NatWest") &&
                        !val.display_name.includes("Halifax") &&
                        !val.display_name.includes("Lloyds") &&
                        !val.display_name.includes("Santander") &&
                        !val.display_name.includes("Nationwide") &&
                        !val.display_name.includes("HSBC")
                    );
                })
                .map(function (val) {
                    return {
                        value: val.provider,
                        label: val.display_name,
                    };
                })
        );
    }

    const closeModal =(e) => {
        setShareInfoPopUp("hide");
        setAccNumPopUp("hide");
        setSortCodePopUp("hide");
      };
 const OtherLenderSlide = async (e) => {
    const input = document.getElementById('otherlenders').value;
          e.preventDefault();
          const values = getValues();
          const otherlender = await trigger("otherlenders");

     
          if (input!="" && otherlender) {
            localStorage.setItem("bud_lender", input);
            setLenderName(input);
            setShareInfoPopUp("show");
            saveInputAns(e);
          }else{
            setError("otherlenders", {
              type: "manual",
              message: "Please Enter Valid Bank Name",
            });
          }
 }
    return (
        <>
            <div
                id="bankNamediv"
                className={`question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2 ${show}`}
            >
                <div className="row mb-3">
                    <div className="mb-2">
                        <ul className="p-0 m-0 option-grid">
                            {TopSelectedLenders.map((lender, index) => (
                                <li key={lender.label}>
                                    <div className="optionbtn">

                                        <RadioButtonQuest
                                            type="button"
                                            //labelClassName={`${checkedOne}`}
                                            id={`bankOption${index + 1}`}
                                            name="bankOptions"
                                            value={lender.value}
                                            labelName={lender.label}
                                            onClick={nextSlide}
                                            dataQuestId={64}
                                        >
                                            {lender.label}
                                        </RadioButtonQuest>
                                    </div>

                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 mb-2 z-3">
                        <Select
                            placeholder="Find Your Bank"
                            options={BankList}
                            aria-label="Username"
                            name="bankName"
                            id="bankName"
                            value={SelectBank}
                            onChange={handleSelect}
                            isSearchable={true}
                            styles={customStyles}
                            menuPortalTarget={document.body}  // Render menu into the body
                        //isMulti
                        />

                        {BankValidationMsg != "" && (
                            <span id="bank_err" className="error_msg">
                                {BankValidationMsg}
                            </span>
                        )}
                    </div>
                    <div className={`col-lg-4 mb-2 ${hideNxtBtn ? "show" : "hide"}`}>
          <button
            type="button"
            className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
            id="submitLender"
            onClick={nextSlide}
            name="banks"
            data-quest-id={64}
          >
            Next &#8594;
          </button>
        </div>
        
      <div className={`col-lg-12 mb-2 ${showOtherOpts ? "show" : "hide"}`}>
      <div className="row">
        <div className="col-lg-8 mb-2 z-3 autocomplete-wrapper">
        <Autocomplete 
            validation={validation}
            />

          {  validationMsg.otherlenders && (
            <span id="bank_err" className="error_msg">
              { validationMsg.otherlenders.message}
            </span>
          )}
        </div>
        <div className="col-lg-4 mb-2">
          <button
            type="button"
            className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
            id="submitLender"
            onClick={OtherLenderSlide}
            name="banks"
            data-quest-id={64}
          >
            Next &#8594;
          </button>
        </div>
        </div>
      </div>
                   
                </div>
            </div>

            {accNumPopUp && (
        <>
          <BankAccNo
            backClick={backClick}
            saveInputAns={saveInputAns}
            SkippedLeads={SkippedLeads}
            setClickedSlide={setClickedSlide}
            setAccNum={setAccNum}
            lenderName={lenderName}
            accNumPopUp={accNumPopUp}
            setAccNumPopUp={setAccNumPopUp}
            setSortCodePopUp={setSortCodePopUp}
            closeModal={closeModal}
          />
        </>
      )}

      {sortCodePopUp && (
        <SortCode
          backClick={backClick}
          saveInputAns={saveInputAns}
          SkippedLeads={SkippedLeads}
          setClickedSlide={setClickedSlide}
          setSortCode={setSortCode}
          lenderName={lenderName}
          sortCodePopUp={sortCodePopUp}
          setSortCodePopUp={setSortCodePopUp}
          closeModal={closeModal}
        />
      )}
      {shareInfoPopUp && (
        <>
          <ShareInfo
            backClick={backClick}
            shareInfoSave={shareInfoSave}
            SkippedLeads={SkippedLeads}
            setClickedSlide={setClickedSlide}
            setAccNum={setAccNum}
            lenderName={lenderName}
            accNumPopUp={accNumPopUp}
            setAccNumPopUp={setAccNumPopUp}
            setSortCodePopUp={setSortCodePopUp}
            shareInfoPopUp ={shareInfoPopUp}
            closeModal={closeModal}
            setShareInfoPopUp ={setShareInfoPopUp}
          />
        </>
      )}
        </>
    );
};
export default Banks;
